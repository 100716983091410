import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ManageContainer } from '../_Containers/ManageContainer';
import { analytics } from '../../analytics/analytics';
import services from '../../API/services';
import { AccountType, iUser, iWheel, iWheelMember, iWheelPermissions, WheelMemberRole } from '../../API/interfaces';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { isWheelAdmin, isWheelMember, isWheelAccountabilityBuddy, getWheelMember } from '../Shared/utils';
import Header from './Header/Header';
import { TabContainer, Tab } from '../Shared/TabContainer/TabContainer';
import { Spinner } from '../Shared/Spinner/Spinner';
import SuggestedResources from './Tabs/SuggestedResources/SuggestedResources';
import TeamResults from './Tabs/TeamResults/TeamResults';
import MyResults from './Tabs/MyResults/MyResults';
import MemberResults from './Tabs/MemberResults/MemberResults';
import DuplicateWheelModal from '../Shared/DuplicateWheelModal/DuplicateWheelModal';
import WheelMembers from './Tabs/WheelMembers/WheelMembers';
import TaskReminder from './Tabs/TaskReminder/TaskReminder';
import CheckinModal from './CheckinModal/CheckinModal';
import { WHEEL_ACCESS_TYPES } from '../../constants';
import ErrorContainer from './common/ErrorContainer/ErrorContainer';
import QuestionnaireModal from './QuestionnaireModal/QuestionnaireModal';
import QuestionnaireTrigger from './QuestionnaireTrigger/QuestionnaireTrigger';
import './WheelPage.scss';
import { MembersClient } from '../../API/members.client';
import { PostCheckinPopup } from './CheckinModal/PostCheckin/PostCheckinPopUp';
import { showInfoModal } from '../Shared/InfoModal/InfoModal';
import { getJoinRoleModalDescription } from './utils';
import { AccountabilityBuddyWheelPage } from './AccountabilityBuddyWheelPage';
import { bindActionCreators } from 'redux';
import { hideCheckAssistInfoModalAction } from '../Auth/Auth.actions';

interface iWheelPageQueryParams {
  wheelId: string;
}

export type LocationStateProps = {
  isJoiningToWheel?: boolean;
  isFromNotification?: boolean;
  initiatorId?: string;
};

interface iWheelPageProps extends RouteComponentProps<iWheelPageQueryParams, any, LocationStateProps> {
  user: iUser;
  session: { count: number; showQuestionnaire: boolean };
  actions: {
    hideCheckAssistInfoModalAction: () => void;
  };
}

export type AssistMember = {
  id: string;
  name: string;
};

interface iWheelPageState {
  isLoading: boolean;
  wheel: iWheel | null;
  wheelPermissions: iWheelPermissions | null;
  showCheckinModal: boolean;
  showDuplicateModal: boolean;
  showQuestionnaireModal: boolean;
  postCheckinData: null | any;
  scoresData?: Array<{ score: number; segmentName: string }> | null;
  requestError: Error;
  members: Array<iWheelMember>;
  activeBuddy: iWheelMember;
  isCheckinAssistModal: boolean;
  showCheckinAssistInfo: boolean;
  assistMember: AssistMember;
  isHideCheckinAssistInfoChecked: boolean;
  defaultTabIndex: number;
  activeMemberResultsMemberId: string;
  segmentScores: Array<{ score: number; segmentName: string }>;
  isOpenModal: boolean;
  isAIOpen: boolean;
  isTaskReminder: boolean;
}

class WheelPage extends Component<iWheelPageProps, iWheelPageState> {
  private tabContainer: React.RefObject<any>;
  constructor(props) {
    super(props);

    const isPostCheckin = new URLSearchParams(props.location.search).get('postCheckin');

    this.state = {
      isLoading: true,
      wheel: undefined,
      wheelPermissions: null,
      showCheckinModal: false,
      showDuplicateModal: false,
      showQuestionnaireModal: false,
      postCheckinData: isPostCheckin ? [] : null,
      scoresData: null,
      requestError: null,
      members: [],
      activeBuddy: null,
      isCheckinAssistModal: false,
      showCheckinAssistInfo: false,
      assistMember: null,
      isHideCheckinAssistInfoChecked: false,
      defaultTabIndex: 0,
      activeMemberResultsMemberId: null,
      segmentScores: [],
      isOpenModal: false,
      isAIOpen: false,
      isTaskReminder: false,
    };

    this.tabContainer = React.createRef();
  }

  handleOpenAI = () => {
    this.setState({ isAIOpen: true, isLoading: false });
    window.scrollTo(0, 0);
  };

  handleCloseAI = () => {
    this.setState({ isAIOpen: false });
  };

  loadData = async () => {
    const {
      user,
      match: {
        params: { wheelId },
      },
    } = this.props;
    this.setState({ isLoading: true, requestError: null });

    try {
      const wheelResponse = await services.getWheelById(wheelId);
      const wheelPermissions = await services.fetchWheelPermissions(wheelId);
      const wheelMembersResponse = await MembersClient.getWheelMembersByWheelId(wheelId);

      if (!isWheelMember(wheelResponse.data.members, user._id)) {
        const err = Error('Contact the wheel owner to send you the invite link');
        err.name = 'NotMember';
        throw err;
      }

      const isPostCheckin = new URLSearchParams(this.props.location.search).get('postCheckin');

      let newState: Partial<iWheelPageState> = {
        isLoading: false,
        wheel: wheelResponse
          ? {
              ...wheelResponse.data,
              segments: wheelResponse.data.segments.filter((segment) => segment.name !== '_comments'),
            }
          : null,
        wheelPermissions,
        members: wheelMembersResponse.data,
      };

      if (isPostCheckin && this.state.postCheckinData.length === 0) {
        const params = { id: wheelId, userId: user._id };
        const latestScores = await services.getLatestScores(params);
        const scoresData = latestScores.data?.map(({ score, segment_name }) => ({
          score,
          segmentName: segment_name,
        }));
        newState = { ...newState, scoresData: scoresData };
      }

      this.setState(newState as iWheelPageState);
    } catch (err: any) {
      this.setState({ isLoading: false, requestError: err });
    }
  };

  showCheckinModal = () => {
    this.setState({ showCheckinModal: true });
    analytics.createCheckinAttempt(false);
  };

  showCheckinAssistModal = (assistMember: AssistMember) => {
    this.setState({
      isCheckinAssistModal: true,
      showCheckinAssistInfo: true,
      showCheckinModal: true,
      assistMember,
    });
  };

  onCompleteCheckinAssistInfoModal = () => {
    this.setState({ showCheckinAssistInfo: false });
  };

  closeCheckinModal = (isCheckinCompleted = false, data) => {
    this.setState({
      showCheckinModal: false,
      postCheckinData: data?.postCheckinData?.articles,
      scoresData: data?.scores,
      isCheckinAssistModal: false,
      assistMember: null,
      segmentScores: data?.scores ? this.calculateSegmentScores(this.state.wheel.segments, data.scores) : [],
    });

    if (isCheckinCompleted) {
      this.loadData();
    }
  };

  showShowQuestionnaireModal = () => {
    this.setState({ showQuestionnaireModal: true });
    analytics.questionnaireClickOnTrigger();
  };

  closeShowQuestionnaireModal = () => {
    this.setState({ showQuestionnaireModal: false });
  };

  showDuplicateWheelModal = () => this.setState({ showDuplicateModal: true });

  setActiveBuddy = (activeBuddy: iWheelMember) => this.setState({ activeBuddy });

  closeDuplicateWheelModal = (result: boolean, newWheelName?: string) => {
    const { wheel } = this.state;
    this.setState({ showDuplicateModal: false });

    if (result) {
      services
        .duplicateWheel(wheel.id, newWheelName)
        .then(() => toasterService.addSuccessToast(`${wheel.name} has been duplicated successfully`));

      analytics.duplicateOnWheelPage();
    }
  };

  onChangeTab = (index: number, title: string) => {
    if (title === 'Members') {
      analytics.clickMembersTab();
    }
    if (title === 'Suggested Resources' || title === 'AI Resources') {
      analytics.clickAiSuggestedTab();
    }
    if (title === 'Task Reminder') {
      analytics.clickTaskReminderTab();
    }
  };

  async componentDidMount() {
    this.loadData();

    const { scoresData, wheel } = this.state;

    const isTaskReminder = new URLSearchParams(this.props.location.search).get('taskReminder');
    if (isTaskReminder) {
      this.setState({ isTaskReminder: true });
    }

    if (scoresData) {
      const updatedSegmentScores = this.calculateSegmentScores(wheel.segments, scoresData);
      this.setState({ segmentScores: updatedSegmentScores });
    }
  }

  calculateSegmentScores(segments, scoresData) {
    return segments.map((segment) => {
      const matchingScore = scoresData.find((score) => score.segment_id === segment.id);
      return {
        score: matchingScore ? matchingScore.score : 0,
        segmentName: segment.name,
        segmentId: segment.id,
      };
    });
  }

  componentDidUpdate(prevProps: iWheelPageProps, prevState: iWheelPageState): void {
    const { members, wheel, isTaskReminder } = this.state;

    if (isTaskReminder && this.tabContainer.current) {
      this.setTabActive('Task Reminder');
    }

    const {
      location,
      user,
      match: {
        params: { wheelId },
      },
      location: { state: { isFromNotification = false, initiatorId = '' } = {} },
    } = this.props;

    if (prevProps.match.params.wheelId !== wheelId) {
      this.loadData();
    }

    if (prevState.wheel?.id !== wheel?.id) {
      const query = new URLSearchParams(location.search);
      const joinRole = query.get('role');
      if (location.state?.isJoiningToWheel && joinRole === WheelMemberRole.ACCOUNTABILITY_BUDDY) {
        const invitingMemberId = query.get('invitingMemberId') || '';
        const invitingMember = getWheelMember(members, invitingMemberId);
        showInfoModal({
          title: `Welcome ${user.first_name} ${user.last_name}`,
          description: getJoinRoleModalDescription(invitingMember.firstName, invitingMember.lastName, wheel.name),
        });
      }
    }

    if (prevState.wheel?.id !== wheel?.id) {
      const isAdmin = !!wheel && isWheelAdmin(wheel, user._id);
      const newDefaultTabIndex = isFromNotification ? 2 : +isAdmin;
      if (!isTaskReminder) {
        this.setState({ defaultTabIndex: newDefaultTabIndex }, () => {
          if (this.tabContainer.current) {
            this.tabContainer.current.setTab(newDefaultTabIndex === 2 ? 'Member Results' : 'Team Results');
          }
        });
      }
      this.setState({ defaultTabIndex: isFromNotification ? 2 : +isAdmin });
    }
  }

  toggleHideCheckinAssistInfo = () => {
    this.setState({
      isHideCheckinAssistInfoChecked: !this.state.isHideCheckinAssistInfoChecked,
    });
  };

  setDefaultTabIndex = (tabIndex: number) => {
    this.setState({ defaultTabIndex: tabIndex });
  };

  setActiveMemberResultsMemberId = (memberId: string) => {
    this.setState({ activeMemberResultsMemberId: memberId });
  };

  onCheckinAssistConfirm = (tabIndex: number, memberId: string) => {
    const { hideCheckAssistInfoModalAction } = this.props.actions;
    const { isHideCheckinAssistInfoChecked } = this.state;
    this.setDefaultTabIndex(tabIndex);
    this.setActiveMemberResultsMemberId(memberId);
    isHideCheckinAssistInfoChecked && hideCheckAssistInfoModalAction();
  };

  setTabActive = (title) => {
    this.tabContainer.current.setTab(title);
  };

  handleClosePostCheckinPopup = () => {
    const { wheel } = this.state;
    this.setState({ postCheckinData: null });
    // if (wheel.isPersonal) {
    //   this.props.history.push('/dashboard');
    // }
  };

  render() {
    const {
      user,
      session,
      location: { state: { initiatorId = '' } = {} },
    } = this.props;
    const {
      isLoading,
      wheel,
      wheelPermissions,
      members,
      showDuplicateModal,
      showCheckinModal,
      postCheckinData,
      scoresData,
      requestError,
      showQuestionnaireModal,
      activeBuddy,
      isCheckinAssistModal,
      assistMember,
      isHideCheckinAssistInfoChecked,
      showCheckinAssistInfo,
      defaultTabIndex,
      activeMemberResultsMemberId,
      segmentScores,
      isAIOpen,
    } = this.state;

    // TODO make common component
    if (isLoading || requestError) {
      return (
        <ManageContainer>
          <div className="wheel-page">
            {isLoading && (
              <div className="main-spinner-container">
                <Spinner />
              </div>
            )}
            {requestError && <ErrorContainer error={requestError} />}
          </div>
        </ManageContainer>
      );
    }

    const myWheelData = getWheelMember(members, user._id);
    const isAdmin = isWheelAdmin(wheel, user._id);
    const isAccountabilityBuddy = isWheelAccountabilityBuddy(wheel, user._id);
    const isPublicWheel = wheel.access === WHEEL_ACCESS_TYPES.PUBLIC;
    const showQuestionnaireTrigger =
      user.accountTag !== AccountType.SCHOOL && session.count > 2 && session.showQuestionnaire;
    const filteredMembers = isAccountabilityBuddy
      ? members.filter((member) => myWheelData.accountabilityBuddyFor.includes(member.userId))
      : members;

    const checkinAssistModalProps = {
      isCheckinAssistModal,
      assistMember,
      isHideCheckinAssistInfoChecked,
      toggleHideCheckinAssistInfo: this.toggleHideCheckinAssistInfo,
      onCompleteCheckinAssistInfoModal: this.onCompleteCheckinAssistInfoModal,
      showCheckinAssistInfo,
    };

    const resourcesTabName = !isPublicWheel ? 'AI Resources' : 'Suggested Resources';

    const tabs = document.querySelectorAll('.tab');
    let suggestedResourcesTabIndex = null;
    tabs.forEach((tab, index) => {
      if (tab.textContent.includes(resourcesTabName)) {
        suggestedResourcesTabIndex = index;
      }
    });

    const privateSegmentScores = scoresData?.map(({ score, segmentName }) => ({
      score,
      segmentName,
    }));

    // restrict consent screen only for research study wheels for a mvp
    const wheelCreatorId = wheel.wheelCreator.id;
    const researchSleepAccounts = ['66a6f749f3dbe3002e44f1fb', '643cb100aef80500183380cf', '65153e6cef12b8002549da54'];
    const isResearchSleepAccount = researchSleepAccounts.includes(wheelCreatorId);

    return (
      <ManageContainer>
        {showQuestionnaireTrigger && <QuestionnaireTrigger onClick={this.showShowQuestionnaireModal} />}
        <div className="wheel-page">
          <Header
            wheel={wheel}
            activeBuddy={activeBuddy}
            toggleCheckinModal={this.showCheckinModal}
            toggleDuplicateWheelModal={this.showDuplicateWheelModal}
          />
          {isAccountabilityBuddy ? (
            <AccountabilityBuddyWheelPage
              wheel={wheel}
              filteredMembers={filteredMembers}
              wheelPermissions={wheelPermissions}
              setActiveBuddy={this.setActiveBuddy}
            />
          ) : (
            <TabContainer ref={this.tabContainer} onChangeTab={this.onChangeTab} defaultTabIndex={defaultTabIndex}>
              <Tab title="My Results">
                <MyResults
                  wheel={wheel}
                  wheelPermissions={wheelPermissions}
                  members={members}
                  showCheckinModal={this.showCheckinModal}
                />
              </Tab>
              <Tab title="Team Results" show={isAdmin}>
                <TeamResults
                  wheel={wheel}
                  wheelPermissions={wheelPermissions}
                  members={members}
                  showCheckinModal={this.showCheckinModal}
                />
              </Tab>
              <Tab title="Member Results" show={isAdmin && !isPublicWheel}>
                <MemberResults
                  wheel={wheel}
                  members={filteredMembers}
                  wheelPermissions={wheelPermissions}
                  setActiveBuddy={this.setActiveBuddy}
                  initiatorId={initiatorId}
                  isAdmin={isAdmin}
                  openCheckinAssistModal={this.showCheckinAssistModal}
                  user={user}
                  activeMemberResultsMemberId={activeMemberResultsMemberId}
                />
              </Tab>
              <Tab title="Members" show={!isPublicWheel}>
                <WheelMembers
                  wheel={wheel}
                  lockFeature={isAdmin && !wheelPermissions.wheel_members}
                  openCheckinAssistModal={this.showCheckinAssistModal}
                />
              </Tab>
              <Tab title={resourcesTabName} show={!isPublicWheel}>
                <SuggestedResources
                  user={user}
                  wheel={wheel}
                  isAIOpen={isAIOpen}
                  onOpenAI={this.handleOpenAI}
                  onCloseAI={this.handleCloseAI}
                  showCheckinModal={this.showCheckinModal}
                  isAdmin={isAdmin}
                />
              </Tab>
              <Tab title="Task Reminder" show={!isPublicWheel} wheelId={wheel.id}>
                <TaskReminder user={user} wheel={wheel} members={members} isAdmin={isAdmin} />
              </Tab>
            </TabContainer>
          )}
        </div>

        {showDuplicateModal && <DuplicateWheelModal wheel={wheel} toggle={this.closeDuplicateWheelModal} />}

        {showCheckinModal && (
          <CheckinModal
            wheel={wheel}
            toggle={this.closeCheckinModal}
            onCheckinAssistConfirm={this.onCheckinAssistConfirm}
            {...(isCheckinAssistModal && checkinAssistModalProps)}
          />
        )}

        {postCheckinData && !isResearchSleepAccount && (
          <PostCheckinPopup
            segmentScores={!isPublicWheel ? privateSegmentScores : segmentScores}
            close={this.handleClosePostCheckinPopup}
            setTabActive={this.setTabActive}
            title={resourcesTabName}
            show={!isPublicWheel}
            user={user._id}
            onOpenAI={this.handleOpenAI}
            accountName={wheel?.wheelCreator?.account?.accountName}
            showSurveyResponsesButton={!!wheel.checkinSurveyId}
          />
        )}

        {showQuestionnaireModal && <QuestionnaireModal onClose={this.closeShowQuestionnaireModal} />}
      </ManageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
  session: state.session,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ hideCheckAssistInfoModalAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WheelPage));
