import React, { Component } from 'react';
import { DateTime, Interval } from 'luxon';
import * as _ from 'lodash';
import { Modal, ModalBody } from 'reactstrap';
import { iCheckinModalScore, iUser, iWheel, SurveyQuestion } from '../../../API/interfaces';
import StepCounter from './StepCounter/StepCounter';
import Step from './Step/Step';
import LastStep from './LastStep/LastStep';
import ExitMessage from './ExitMessage/ExitMessage';
import DisabledCheckinModal from './DisabledCheckinModal/DisabledCheckinModal';
import './CheckinModal.scss';
import { FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import { UserPermissionsType } from '../../Auth/Auth.reducer';
import CheckinAssistInfo from './CheckinAssistInfo';
import { AssistMember } from '../WheelPage';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';
import CheckInAssistWhiteIcon from '../../../Assets/images/checkin-assist-white.png';
import services from '../../../API/services';
import SurveySteps from './SurveySteps/SurveySteps';

interface iCheckinModalProps {
  wheel: iWheel;
  toggle: (isCheckinCompleted: boolean, checkin?) => void;
  isCheckinAssistModal?: boolean;
  permissions: UserPermissionsType;
  assistMember?: AssistMember;
  isHideCheckinAssistInfoChecked?: boolean;
  toggleHideCheckinAssistInfo?: () => void;
  onCompleteCheckinAssistInfoModal?: () => void;
  showCheckinAssistInfo?: boolean;
  onCheckinAssistConfirm?: (index: number, id: string) => void;
}

interface iCheckinModalState {
  currentStep: number;
  scores: Array<iCheckinModalScore> | null;
  checkinComment: string;
  showExitMessage: boolean;
  surveyQuestions: SurveyQuestion[] | null;
  responses: { questionId: string; response: string | number }[];
}
class CheckinModal extends Component<iCheckinModalProps, iCheckinModalState> {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      scores: props.wheel.segments.map((segment) => ({
        segmentId: segment.id,
        comment: null,
        score: 1,
        segmentName: segment.name,
      })),
      checkinComment: '',
      showExitMessage: false,
      surveyQuestions: null,
      responses: [],
    };
  }

  async componentDidMount() {
    const { wheel } = this.props;

    if (wheel.checkinSurveyId) {
      try {
        const response = await services.getSurveyQuestions(wheel.checkinSurveyId);
        const surveyQuestions: SurveyQuestion[] = response.data;
        this.setState({ surveyQuestions });
      } catch (error) {
        console.error('Error fetching survey questions:', error);
      }
    }
  }

  setResponse = (questionId: string, response: string | number) => {
    this.setState((prevState) => {
      const updatedResponses = [...prevState.responses];

      const existingIndex = updatedResponses.findIndex((r) => r.questionId === questionId);
      if (existingIndex !== -1) {
        updatedResponses[existingIndex].response = response;
      } else {
        updatedResponses.push({ questionId, response });
      }

      return { responses: updatedResponses };
    });
  };

  isClosureHours = (): boolean => {
    const { checkinClosures } = this.props.wheel.wheelCreator.account;

    if (!_.isEmpty(checkinClosures)) {
      return checkinClosures.some((closure) => {
        const timezone = closure.timeZone || 'local';
        const now = DateTime.now().setZone(timezone);

        switch (closure.type) {
          case 'dateRange':
            const from = DateTime.fromISO(closure.from, { zone: timezone });
            const to = DateTime.fromISO(closure.to, { zone: timezone });

            return Interval.fromDateTimes(from, to).contains(now);
          case 'weekDays':
            if (!closure.days.includes(now.weekdayLong)) {
              return false;
            }
            const [hourStart, minutesStart] = closure.start.split(':');
            const [hourEnd, minutesEnd] = closure.end.split(':');
            const isAfterClosureStart =
              now.hour > +hourStart || (now.hour === +hourStart && now.minute >= +minutesStart);
            const isBeforeClosureEnd = now.hour < +hourEnd || (now.hour === +hourEnd && now.minute <= +minutesEnd);

            // check if it regular range (15:00 18:00) or reversed (18:00 9:00)
            return hourStart > hourStart || (hourStart === hourStart && minutesEnd > minutesStart)
              ? isAfterClosureStart && isBeforeClosureEnd
              : isAfterClosureStart || isBeforeClosureEnd;
          default:
            return false;
        }
      });
    }
    return false;
  };

  setScore = (score: number) => {
    const { scores, currentStep } = this.state;
    // mutation
    scores[currentStep].score = score;
    this.setState({ scores: [...scores] });
  };

  // set score or checkin comment
  setComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { wheel } = this.props;
    const { scores, currentStep } = this.state;
    const comment = e.target.value;

    if (currentStep < wheel.segments.length) {
      // mutation
      scores[currentStep].comment = comment;
      this.setState({ scores: [...scores] });
    } else {
      this.setState({ checkinComment: comment });
    }
  };

  onExit = () => this.setState({ showExitMessage: true });

  cancelExit = () => this.setState({ showExitMessage: false });

  onBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  onNext = () => this.setState({ currentStep: this.state.currentStep + 1 });

  render() {
    const {
      toggle,
      wheel,
      permissions,
      isCheckinAssistModal,
      assistMember,
      isHideCheckinAssistInfoChecked,
      toggleHideCheckinAssistInfo,
      onCompleteCheckinAssistInfoModal,
      showCheckinAssistInfo,
      onCheckinAssistConfirm,
    } = this.props;
    const { currentStep, scores, checkinComment, showExitMessage, responses } = this.state;
    const totalSteps = wheel.segments.length + (this.state.surveyQuestions?.length || 0);

    if (this.isClosureHours()) {
      return <DisabledCheckinModal toggle={() => toggle(false)} />;
    }

    const isShownCheckinAssistInfo = permissions?.see_assist_info_modal && showCheckinAssistInfo;

    const assistMemberInitials = () => {
      const nameArray = !!assistMember?.name.length && assistMember.name.split(' ');
      return `${nameArray[0][0]} ${nameArray[1][0]}`;
    };

    return (
      <Modal isOpen={true} toggle={this.onExit} className="checkin-modal">
        {!showExitMessage && (
          <ModalBody className="checkin-modal-body">
            {isShownCheckinAssistInfo ? (
              <CheckinAssistInfo
                assistMember={assistMember}
                isHideCheckinAssistInfoChecked={isHideCheckinAssistInfoChecked}
                toggleHideCheckinAssistInfo={toggleHideCheckinAssistInfo}
                onCompleteCheckinAssistInfoModal={onCompleteCheckinAssistInfoModal}
                onCancel={this.onExit}
              />
            ) : (
              <>
                <FiX className="close-button" size={18} onClick={this.onExit} />
                <StepCounter currentStep={currentStep} totalSteps={totalSteps} hasLastStep />

                {isCheckinAssistModal && (
                  <AssistMemberContainer>
                    <AssistMemberNameBadge>
                      <AssistMemberInitials>{assistMemberInitials()}</AssistMemberInitials>
                      <Text size="12px">{assistMember?.name}</Text>
                    </AssistMemberNameBadge>
                    <AssistBadge>
                      <StyledImage src={CheckInAssistWhiteIcon} />
                      <Text size="12px" color="#fff" weight="200">
                        Check in assist
                      </Text>
                    </AssistBadge>
                  </AssistMemberContainer>
                )}

                {currentStep < wheel.segments.length && (
                  <Step
                    wheel={wheel}
                    scores={scores}
                    currentStep={currentStep}
                    setScore={this.setScore}
                    setComment={this.setComment}
                    onExit={this.onExit}
                    onBack={this.onBack}
                    onNext={this.onNext}
                  />
                )}

                {currentStep >= wheel.segments.length && currentStep < totalSteps && (
                  <SurveySteps
                    surveyId={wheel.checkinSurveyId}
                    wheelId={wheel.id}
                    surveyQuestions={this.state.surveyQuestions}
                    currentStep={currentStep}
                    responses={this.state.responses}
                    setResponse={this.setResponse}
                    onExit={this.onExit}
                    onBack={this.onBack}
                    onNext={this.onNext}
                    totalSegments={wheel.segments.length}
                  />
                )}

                {currentStep === totalSteps && (
                  <LastStep
                    wheel={wheel}
                    scores={scores}
                    comment={checkinComment}
                    setComment={this.setComment}
                    toggle={toggle}
                    onBack={this.onBack}
                    onCheckinAssistConfirm={onCheckinAssistConfirm}
                    {...(isCheckinAssistModal && assistMember && { assistMember, isHideCheckinAssistInfoChecked })}
                    surveyResponses={responses}
                  />
                )}
              </>
            )}
          </ModalBody>
        )}

        {showExitMessage && (
          <ModalBody className="exit-modal-body">
            <ExitMessage toggle={toggle} cancelExit={this.cancelExit} />
          </ModalBody>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.userRD.accountPermissions,
});

export default connect(mapStateToProps)(CheckinModal);

const AssistMemberContainer = styled.div`
  display: flex;
  margin: 16px 0 32px 0;
`;

const AssistMemberNameBadge = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: solid 1px rgba(64, 64, 66, 0.24);
  background-color: #fff;
  padding: 2px 8px 2px 4px;
  margin-right: 4px;
`;

const AssistMemberInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: #2a3e50;
  border-radius: 50px;
  margin-right: 4px;
  color: #fff;
  font-size: 6px;
`;

const AssistBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: #2a3e50;
`;

const StyledImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;
