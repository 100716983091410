export type ReportAccessType = 'identified' | 'anonymous';

export const reportTypeTooltipText = {
  identified:
    'This will generate an identified report, showcasing a summary of relevant wheel data and listing wheel members by name.',
  anonymous: 'This will generate an anonymous report, removing wheel members names.',
};

export const reportTypeValues = {
  identified: 'identified',
  anonymous: 'anonymous',
};

export const sleepDescription = `Your Sleep Report is ready for download. This report includes your sleep journal data along with check-in responses recorded. Use this report to track trends and insights related to your sleep and well-being.`;
