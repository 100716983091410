import { HttpClient } from './HttpClient';

export enum TaskRecurrence {
  NONE = 'doNotRepeat',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum DeliveryMethod {
  EMAIL = 'email',
  PUSH_NOTIFICATION = 'push',
}

export class TasksClient {
  static createTask(
    wheelId: string,
    payload: {
      title: string;
      description: string;
      recurrence: TaskRecurrence;
      deliveryMethods: DeliveryMethod[];
      dueDateTime: Date;
      assignees: string[];
      notifyAdmins?: boolean;
    }
  ) {
    return HttpClient.post(`/wheels/${wheelId}/tasks`, payload).catch(handleError);
  }

  static updateTask(
    taskId: string,
    payload: {
      title?: string;
      description?: string;
      recurrence?: TaskRecurrence;
      dueDateTime?: Date;
      deliveryMethods?: DeliveryMethod[];
      assignees?: string[];
      notifyAdmins?: boolean;
    }
  ) {
    return HttpClient.put(`/wheels/tasks/${taskId}`, payload).catch(handleError);
  }

  static deleteTask(taskId: string) {
    return HttpClient.delete(`/wheels/tasks/${taskId}`).catch(handleError);
  }

  static completeTask(taskId: string) {
    return HttpClient.post(`/wheels/tasks/${taskId}/completion`).catch(handleError);
  }

  static uncompleteTask(taskId: string) {
    return HttpClient.delete(`/wheels/tasks/${taskId}/completion`).catch(handleError);
  }

  static getTask(taskId: string) {
    return HttpClient.get(`/wheels/tasks/${taskId}`).catch(handleError);
  }

  static getTasksByMember(wheelId: string, memberId?: string) {
    const queryParams = buildQueryParams({ memberId });
    return HttpClient.get(`/wheels/${wheelId}/tasks/member?${queryParams}`)
      .then((response) => response.data || [])
      .catch((error) => {
        console.error('Error fetching member tasks:', error);
        return [];
      });
  }

  static getTasksByTeam(wheelId: string) {
    return HttpClient.get(`/wheels/${wheelId}/tasks/team`)
      .then((response) => response.data || [])
      .catch(handleError);
  }

  static getTaskHistoryByMember(wheelId: string, memberId?: string) {
    const queryParams = buildQueryParams({ memberId });
    return HttpClient.get(`/wheels/${wheelId}/tasks/history/member?${queryParams}`)
      .then((response) => response.data || [])
      .catch(handleError);
  }

  static getTaskHistoryByTeam(wheelId: string) {
    return HttpClient.get(`/wheels/${wheelId}/tasks/history/team`)
      .then((response) => response.data || [])
      .catch(handleError);
  }

  static getTasksOverview() {
    const timezoneOffset = -new Date().getTimezoneOffset();
    return HttpClient.get('/wheels/tasks/overview', {
      params: { timezoneOffset },
    }).catch(handleError);
  }
}

const handleError = (error: any) => error.response;

const buildQueryParams = (params: Record<string, string | undefined>): string => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.append(key, value);
  });
  return queryParams.toString();
};
