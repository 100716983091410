import { DateTime } from 'luxon';

export type WheelAccess = 'private' | 'public' | 'wearable';

export enum AccountPlan {
  FREE = 'Free',
  BUSINESS = 'Business',
  PERFORMANCE = 'Performance',
  SCHOOL = 'School',
}

export enum AccountRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  TEACHER = 'teacher',
  MEMBER = 'member',
  ACCOUNTABILITY_BUDDY = 'accountability_buddy',
}

export enum AccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business', // deprecated but possible in DB
  PERSONAL_BUSINESS = 'personal&business', // deprecated but possible in DB
  SCHOOL = 'school',
  WORK = 'work',
  SPORT = 'sport',
  WELLBEING = 'wellbeing',
  MENTAL_HEALTH = 'mental_health',
  GT_CONNECT = 'gt_connect',
  NO_STRESS = 'no_stress_mate',
  OTHER_PROGRAM = 'other_program',

  //company
  ATHLETIC_WEST = 'athletic_west',
  SKILLHIRE = 'skillhire',
  WUNAN = 'wunan',
  METATTUDE = 'metattude',
}
export type WheelStep = 'number' | 'integer';

export enum WheelMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  ACCOUNTABILITY_BUDDY = 'accountability_buddy',
}

export type WheelMemberRoleType = 'admin' | 'member' | 'accountability_buddy';

export enum WheelRoleNaming {
  admin = 'Admin',
  member = 'Member',
  accountability_buddy = 'Accountability Buddy',
  anonymous = 'Anonymous',
}

export interface iUser {
  _id: string;
  token: string;
  email: string;
  first_name: string;
  last_name: string;
  createdAt: string;
  updatedAt: string;
  enterprise: boolean;
  accountTag: AccountType;
  account: iAccount;
  accountRole: AccountRole | null;
  personalData: PersonalData;
  isConsentGiven?: boolean;
}

export interface iScore {
  score: number;
  segmentId: string;
  segmentName: string; // added by FE
  description: string | null; // added by FE
}

export interface iCheckin {
  averageScore: number; // added by FE
  comment: string;
  createDate: string;
  createWeek: string;
  id: string;
  scores: Array<iScore>;
  userId: string;
}

export interface iComment {
  userId: string;
  date: string;
  comment: string;
  score?: string;
  averageScore?: string;
  commentId?: string;
  replies?: Reply[];
}

interface Reply {
  id: string;
  userId: string;
  comment: string;
  createDate: string;
}

export interface iSegment {
  _id?: string; // default segments
  defaultSegmentId: string;
  description: string | null;
  id: string;
  name: string;
}

export interface iLookerConfig {
  embedUrl: string;
  externalGroupId: string;
  externalUserId: string;
}

export interface iAccount {
  accountName: string;
  admin: string;
  logo: string;
  plan: AccountPlan;
  checkinClosures?: Array<iCheckinClosure>;
  teamAlert?: boolean;
  teamAlertThreshold?: number;
  forceScoreComment?: boolean;
  skipMobileJoinFlow?: boolean;
  followUpRequest?: boolean;
  isPostCheckinDataEnabled?: boolean;
  lookerConfigs?: Array<iLookerConfig>;
}

export interface iWheelCreator {
  account: iAccount;
  accountRole: string | null;
  accountTag: AccountType;
  firstName: string;
  id: string;
  lastName: string;
}

export interface iCheckinClosure {
  type: 'weekDays' | 'dateRange';
  days?: Array<string>;
  from: string; // ISO (dateRange)
  to: string; // ISO (dateRange)
  start: string; // hours (weekDays)
  end: string; // hours (weekDays)
  timeZone: string;
}

export interface iWheel {
  access: WheelAccess;
  createDate: string;
  id: string;
  isScoreComments: boolean;
  isGeneralComments: boolean;
  isPersonal: boolean;
  isTemplate: boolean;
  maxScale: number;
  name: string;
  description?: string;
  folder?: string;
  segments: Array<iSegment>;
  step: WheelStep;
  wheelCreator: iWheelCreator;
  members: Array<iMember>;
  reminder: iReminder;
  checkinSurveyId?: string;
  checkinSurveyType?: string;
  isConsentGiven?: boolean;
}

export interface iWheelExt extends iWheel {
  checkinsCount: number;
  latestScores: any[];
  members: Array<iMemberExt>;
  folder: string;
}

export interface iWheelExtMapped extends iWheelExt {
  latestResultUpdateAt: Date;
  isMember: boolean;
  isAdmin: boolean;
  isAccountabilityBuddy: boolean;
  averageScore: number;
}

export interface iWheelPermissions {
  wheel_members: boolean;
  wheel_pdfReport: boolean;
}

export type AggregationUnit = 'none' | 'day' | 'week' | 'month' | 'year';

// comments grouped by date range
export interface iCommentsAggregation {
  [dateRange: string]: Array<iComment>;
}

// represents aggregated scores as one score
export interface iScoresAggregation extends iScore {
  scoreComments: iCommentsAggregation | null;
}

// score with single comment for POST checkin request in checkin modal
export interface iCheckinModalScore extends iScore {
  comment: string | null;
}

// represents aggregated checkins as one checkin
export interface iCheckinsAggregation {
  from: DateTime;
  to: DateTime;
  comments: iCommentsAggregation;
  scores: Array<iScoresAggregation>;
  checkinIds: Array<string>;
  numberOfCheckins: number;
  averageScore: number; // added by FE
  dateRange: string; // added by FE
}

export interface iCheckinRequest {
  wheelId: string;
  userId?: string;
  from?: string; // ISO Date 26-02-2021
  to?: string; // ISO Date 26-02-2021
  unit: AggregationUnit;
  limit?: number;
  timeZone?: string;
}

export enum ReminderFrequency {
  ONCE = 'once',
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly',
}

export interface iReminder {
  startDate: string;
  lastDate: string;
  nextDate: string;
  frequency: ReminderFrequency;
  isEnabled: boolean;
}

export interface iMember {
  isPasswordReset?: boolean;
  isManuallyAddedUser?: boolean;
  isManuallyCreatedUser?: boolean;
  firstName?: string;
  lastName?: string;
  isReminderEnabled: boolean;
  userId: string;
  memberRole: WheelMemberRole;
  accountabilityBuddyFor?: string[];
  email?: string;
}

export interface iMemberExt extends iMember {
  firstName: string;
  lastName: string;
}

export interface iWheelMember {
  avgScore: string | null;
  firstName: string;
  lastName: string;
  userId: string;
  memberRole: WheelMemberRole;
  lastCheckinDate: string;
  email?: string;
  accountabilityBuddyFor?: string[];
  isManuallyAddedUser: boolean;
  isPasswordReset: boolean;
  isManuallyCreatedUser: boolean;
}

export interface iWheelDefaultTemplate {
  id: string;
  name: string;
  description?: string;
  accountTag: AccountType;
  access: WheelAccess;
  maxScale: number;
  step: WheelStep;
  image?: string;
  segments: Array<iSegment>;
}

export interface iWheelTemplate {
  id: string;
  name: string;
  access: WheelAccess;
  maxScale: number;
  step: WheelStep;
  isScoreComments: boolean;
  creator: iUser;
  segments: Array<iSegment>;
  members: Array<iMember>;
}

export class Folder implements iFolder {
  id: string;
  name: string;
  creator: string;
  createdAt: Date;
  parent: string;
  wheels: string[];

  static of(folder: iFolder) {
    return new Folder(folder);
  }

  constructor(folder: iFolder) {
    this.id = folder.id;
    this.name = folder.name;
    this.creator = folder.creator;
    this.createdAt = folder.createdAt;
    this.parent = folder.parent;
    this.wheels = folder.wheels || [];
  }

  public addWheel(wheelId: string) {
    const wheels = this.wheels.concat(wheelId);
    this.wheels = wheels;
    return this;
  }

  public deleteWheel(wheelId: string) {
    if (!this.wheels.length) return this;

    const wheels = this.wheels.filter((id) => id !== wheelId);
    this.wheels = wheels;
    return this;
  }
}

export interface iFolder {
  id: string;
  name: string;
  creator: string;
  createdAt: Date;
  parent: string;
  wheels?: string[];
}

export class FolderWithoutParent implements Partial<iFolder> {
  constructor(public name: string) {}
}
export class FolderWithParent implements Partial<iFolder> {
  constructor(public name: string, public parent: string) {}
}
export type tFolder = FolderWithoutParent | FolderWithParent;

export type SendSupportMessageParams = {
  message: string;
  email?: string;
  supportEmail?: string;
  phoneNumber?: string;
};

export interface PersonalData {
  age?: number | null;
  birth?: string | null;
  gender?: string | null;
  ethnicity?: string | null;
  nationality?: string | null;
  userTag: string | null;
  userRole: string[] | null;
  focus: string[] | null;
  resources: string[] | null;
  challenges: string[] | null;
  goals: string[] | null;
  reason: string[] | null;
}

export enum ManualInvitationMessages {
  USER_CREATED = 'UserCreated',
  USER_IN_SYSTEM = 'UserInSystem',
  USER_AB = 'UserAB',
  USER_MEMBER = 'UserMember',
}

export enum SurveyType {
  SLEEP_JOURNAL = 'sleep_journal',
}

export enum SurveyQuestionType {
  TIME = 'time',
  NUMBER = 'number',
  DURATION = 'duration',
}

export interface Survey {
  id: string;
  userId: string;
  type: SurveyType;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: string;
  question: string;
  type: SurveyQuestionType;
  label: string;
  min: number;
  max: number;
}

export interface SurveyExportResult {
  datetime: string;
  [question: string]: string | number;
}
