import React, { useState } from 'react';
import { iWheel } from '../../../API/interfaces';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CountableTextBox from '../CountableTextBox/CountableTextBox';
import { MAX_WHEEL_NAME_LENGTH } from '../../../constants';
import { BlackBtn, RedBtn } from '../../Auth/Onboarding/_styled';

interface iDuplicateWheelModalProps {
  wheel: iWheel;
  toggle: (boolean, string?) => void;
}

export default ({ wheel, toggle }: iDuplicateWheelModalProps) => {
  if (!wheel) {
    return null;
  }
  const defaultName = `${wheel.name} (Copy)`;
  const [wheelName, setWheelName] = useState(defaultName.length > MAX_WHEEL_NAME_LENGTH ? wheel.name : defaultName);
  const title = wheel.isTemplate ? 'Create from template' : 'Duplicate wheel';
  const message = `Please, enter a name for a new wheel. ${
    !wheel?.isTemplate ? 'Members and scores will not be copied' : ''
  }`;
  const onSubmit = () => !!wheelName && toggle(true, wheelName);
  const onCancel = () => toggle(false);

  return (
    <Modal isOpen={true} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <div>{message}</div>
        <form onSubmit={onSubmit} className="mt-2">
          <CountableTextBox
            placeholder={`Enter a ${wheel.isTemplate ? 'Create' : 'Duplicate'} name`}
            value={wheelName}
            max={MAX_WHEEL_NAME_LENGTH}
            // @ts-ignore
            onChange={(e) => setWheelName(e.target.value)}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <BlackBtn onClick={onCancel}>Cancel</BlackBtn>
        <RedBtn style={{ height: '40px', lineHeight: '40px', marginLeft: '20px' }} onClick={onSubmit}>
          {wheel.isTemplate ? 'Create' : 'Duplicate'}
        </RedBtn>
      </ModalFooter>
    </Modal>
  );
};
