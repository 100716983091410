import React from 'react';
import styled from 'styled-components';
import './Button.scss';

export const Button = styled.button<any>`
  border-radius: 28px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(117deg, #fa8868 1%, #ff554d);
  color: #fff;
  font-size: 12px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.86px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : '52px')};
  line-height: ${(props) => (props.height ? props.height : '52px')};

  &:disabled,
  &[disabled] {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(114deg, #fa8868 0%, #ff554d 100%);
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
  }
`;

export const MediumButton = styled(Button)`
  height: 40px;
  line-height: 40px;
`;

export const SmallButton = styled(Button)`
  line-height: 32px;
  height: 32px;
  padding: 0 12px;
`;

export const CancelButton = styled(Button)`
  background-image: none;
  border: 2px solid #2a3e50;
  color: #2a3e50;
  box-shadow: none;
  background-color: #fff;
`;

export const RedBorderButton = styled(Button)`
  border: solid 1px #ff483c;
  background-image: none;
  background-color: transparent;
  color: #ff483c;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  white-space: nowrap;
  min-width: unset;
`;

export const BlackBorderButton = styled(RedBorderButton)`
  border-color: #000000;
  color: #000000;
`;

export const SsoButton = styled(Button)`
  width: 100%;
  background-color: #fff;
  background-image: none;
  color: #2b2e31;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TransparentButton = styled(Button)`
  border: none;
  background-image: none;
  background-color: transparent;
  color: #2a3e50;
  box-shadow: none;
  font-weight: 700;
  display: flex;
  align-items: center;
`;
