import { BlackBtn, BlackButtonBox, BtnGroup, ContentBox, LeftPanel, RedBtn, Root } from './_styled';
import { Text } from '../../Shared/Typography/Typography';
import { ArrowLeft } from 'react-feather';
import { RightScreen } from './RightScreen';
import { useHistory } from 'react-router-dom';
import { analytics } from '../../../analytics/analytics';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers';

export const StaticContentScreen = ({
  onBack,
  onNext,
  title = 'Well done!',
  subtitle = 'Halfway there 💪',
  description = "Let's continue to further customise your experience. Just a few more questions to get started!",
  text,
  showTitle = true,
  showSkipButton = true,
  step,
  totalSteps,
  isOnboarding = false,
}) => {
  const history = useHistory();
  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));

  const onSkip = () => {
    history.push(`/share${shareLinkParams}`);
  };

  const handleContinueClick = () => {
    analytics.clickOnbLetsContinue();
    onNext();
  };
  console.log(isOnboarding);

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={onBack}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
        </BlackButtonBox>
        <ContentBox style={{ justifyContent: 'center' }}>
          {isOnboarding && <Text size="14px">{`Step ${step + 1}/${totalSteps}`}</Text>}
          {showTitle && <Text size="14px">{title}</Text>}
          <Text className="mb-2" weight={600} size="36px" color="#2b2e31">
            {subtitle}
          </Text>
          <Text weight={500} size="18px" dangerouslySetInnerHTML={{ __html: description }} />
          {text && <Text weight={500} size="18px" className="mb-3" dangerouslySetInnerHTML={{ __html: text }} />}
          <BtnGroup>
            <RedBtn onClick={handleContinueClick}>Continue Profile Setup</RedBtn>
            {showSkipButton && <BlackBtn onClick={onSkip}>Skip & Get Started 🚀</BlackBtn>}
          </BtnGroup>
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};
