import React from 'react';
import dayjs from 'dayjs';
import { TaskView } from '../TaskReminder';
import TaskItem from '../components/TaskItem';
import { iWheelMember } from '../../../../../API/interfaces';
import AssigneesFilter from '../components/AssigneesFilter';
import { Text } from '../../../../Shared/Typography/Typography';
import backArrow from '../../../../../Assets/images/back-arrow.svg';
import { Spinner, SpinnerBackground } from '../../../../Shared/Spinner/Spinner';
import NoTask from '../components/NoTasks';
import { v4 as uuidv4 } from 'uuid';

interface TaskHistoryProps {
  history: any[];
  isLoading: boolean;
  goToTodo: () => void;
  isAdmin: boolean;
  members: iWheelMember[];
  userId: string;
  setCurrentFilter: (filter: string) => void;
  currentFilter: string;
  view: TaskView;
}

const TaskHistory: React.FC<TaskHistoryProps> = ({
  history,
  isLoading,
  goToTodo,
  isAdmin,
  members,
  userId,
  setCurrentFilter,
  currentFilter,
  view,
}) => {
  const groupedTasks = history.reduce((groups, task) => {
    const groupKey = dayjs(task.completedAt ?? task.dueDateTime).format('DD MMM YYYY');
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(task);
    return groups;
  }, {} as Record<string, any[]>);

  const sortedGroupKeys = Object.keys(groupedTasks).sort((a, b) => dayjs(b).diff(dayjs(a)));

  return (
    <div className="todo-list">
      <div className="header">
        <div className="back-to-tasks" onClick={goToTodo}>
          <img src={backArrow} alt="arrow-left" width={13} height={10} />
          <Text size="16px" mSize="14px" color="#0c2337">
            Back to Tasks
          </Text>
        </div>

        {isAdmin && (
          <AssigneesFilter
            userId={userId}
            members={members}
            fetchTasks={(selectedFilter) => setCurrentFilter(selectedFilter)}
            currentFilter={currentFilter}
          />
        )}
      </div>

      {isLoading ? (
        <div className="task-loader">
          <SpinnerBackground height="60px">
            <Spinner />
          </SpinnerBackground>
        </div>
      ) : history.length === 0 ? (
        <NoTask
          message="No tasks have been completed yet. Once tasks are completed, they will appear here."
          showImage
        />
      ) : (
        <div className="task-list">
          {sortedGroupKeys.map((date) => (
            <div key={date} className="task-group">
              <Text size="14px" weight="300" color="#2a3e50" style={{ display: 'block', margin: '24px 0 16px' }}>
                {date}
              </Text>
              {groupedTasks[date].map((task, index) => (
                <TaskItem
                  key={`${task.taskId}-${task.dueDateTime}-${uuidv4()}`}
                  task={task}
                  members={members}
                  isAdmin={isAdmin}
                  userId={userId}
                  view={view}
                  currentFilter={currentFilter}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskHistory;
