import React, { useState, useEffect } from 'react';
import { iUser, iWheel, iWheelMember } from '../../../../API/interfaces';
import TodoList from './TodoList/TodoList';
import TaskHistory from './TaskHistory/TaskHistory';
import TaskCreateEdit from './TaskCreateEdit/TaskCreateEdit';
import { TasksClient } from '../../../../API/tasks.client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './TaskReminder.scss';
import { isWheelAccountabilityBuddy } from '../../../Shared/utils';
import { analytics } from '../../../../analytics/analytics';

interface iTaskReminderProps extends RouteComponentProps {
  user: iUser;
  wheel: iWheel;
  members: Array<iWheelMember>;
  isAdmin?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}

export enum TaskView {
  TODO = 'TODO',
  HISTORY = 'HISTORY',
  CREATE_EDIT = 'CREATE_EDIT',
}

const TaskReminder: React.FC<iTaskReminderProps> = ({ user, wheel, members, isAdmin, setIsLoading }) => {
  const [currentView, setCurrentView] = useState<TaskView>(TaskView.TODO);
  const [taskToEdit, setTaskToEdit] = useState<any | null>(null);
  const [tasks, setTasks] = useState<any[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<string>(isAdmin ? 'all' : user._id);

  const filteredMembers = members.filter((member) => !isWheelAccountabilityBuddy(wheel, member.userId));

  useEffect(() => setIsLoading(false), []);

  const fetchTasks = async () => {
    setTasksLoading(true);
    try {
      const response =
        isAdmin && currentFilter !== user._id
          ? currentFilter === 'all'
            ? await TasksClient.getTasksByTeam(wheel.id)
            : await TasksClient.getTasksByMember(wheel.id, currentFilter)
          : await TasksClient.getTasksByMember(wheel.id);
      const fetchedTasks = response?.data || response || [];
      setTasks(fetchedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setTasks([]);
    } finally {
      setTasksLoading(false);
    }
  };

  const fetchHistory = async () => {
    setTasksLoading(true);
    try {
      const response =
        isAdmin && currentFilter !== user._id
          ? currentFilter === 'all'
            ? await TasksClient.getTaskHistoryByTeam(wheel.id)
            : await TasksClient.getTaskHistoryByMember(wheel.id, currentFilter)
          : await TasksClient.getTaskHistoryByMember(wheel.id);
      const fetchedHistory = response?.data || response || [];
      setHistory(fetchedHistory);
    } catch (error) {
      console.error('Error fetching history:', error);
      setHistory([]);
    } finally {
      setTasksLoading(false);
    }
  };

  useEffect(() => {
    if (currentView === TaskView.TODO) {
      fetchTasks();
    } else if (currentView === TaskView.HISTORY) {
      fetchHistory();
    }
  }, [currentFilter, currentView]);

  const deleteTask = async (taskId: string) => {
    try {
      await TasksClient.deleteTask(taskId);
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const saveTask = async (taskData: any) => {
    try {
      if (taskToEdit) {
        await TasksClient.updateTask(taskToEdit.taskId, taskData);
      } else {
        await TasksClient.createTask(wheel.id, taskData);
      }
      setTaskToEdit(null);
      setCurrentView(TaskView.TODO);
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const handleEditTask = (taskId: string) => {
    const task = tasks.find((t) => t.taskId === taskId);
    if (task) {
      setTaskToEdit(task);
      setCurrentView(TaskView.CREATE_EDIT);
    } else {
      console.error('Task not found for editing:', taskId);
    }
  };

  const handleCreateTask = () => {
    setTaskToEdit(null);
    setCurrentView(TaskView.CREATE_EDIT);
    analytics.clickTaskReminderCreate();
  };

  return (
    <div className="task-reminder">
      {currentView === TaskView.TODO && (
        <TodoList
          userId={user._id}
          members={filteredMembers}
          isAdmin={!!isAdmin}
          fetchTasks={(filter) => setCurrentFilter(filter)}
          editTask={handleEditTask}
          deleteTask={deleteTask}
          openHistory={() => setCurrentView(TaskView.HISTORY)}
          createTask={handleCreateTask}
          tasks={tasks}
          isLoading={tasksLoading}
          view={currentView}
          currentFilter={currentFilter}
        />
      )}

      {currentView === TaskView.HISTORY && (
        <TaskHistory
          history={history}
          isLoading={tasksLoading}
          goToTodo={() => setCurrentView(TaskView.TODO)}
          isAdmin={!!isAdmin}
          members={filteredMembers}
          userId={user._id}
          setCurrentFilter={setCurrentFilter}
          currentFilter={currentFilter}
          view={currentView}
        />
      )}

      {currentView === TaskView.CREATE_EDIT && (
        <TaskCreateEdit
          task={taskToEdit}
          members={filteredMembers}
          isAdmin={isAdmin}
          userId={user._id}
          saveTask={saveTask}
          goToTodo={() => setCurrentView(TaskView.TODO)}
          currentFilter={currentFilter}
          view={currentView}
        />
      )}
    </div>
  );
};

export default withRouter(TaskReminder);
