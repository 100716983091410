import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { InsightsReducerStateType, InsightsPeriodType } from './insights.model';
import LockIcon from '../../Assets/images/icon-lock.png';
import { CALENDAR_DATE_FORMAT } from '../../constants';
import services from '../../API/services';
import { ManageContainer } from '../_Containers/ManageContainer';
import PeriodButton from './PeriodButton';
import { Text } from '../Shared/Typography/Typography';
import SummaryBadge from './SummaryBadge';
import InsightsComments from './InsightsComments';
import { loadInsightsSuccess, startLoad } from './insights.actions';
import { selectInsights } from './insights.selectors';
import NoInsightsComments from './InsightsComments/NoInsightsComments';
import { Spinner, SpinnerBackground } from '../Shared/Spinner/Spinner';
import { LookerClient } from '../../API/looker.client';
import { TabContainer, Tab } from '../Shared/TabContainer/TabContainer';
import './index.scss';
import { iUser } from '../../API/interfaces';
import EmbeddedIframe from './EmbeddedIframe/EmbeddedIframe';

enum InsightsTabs {
  sleep = 'Sleep Insights',
  summary = 'Account Summary',
  insights = 'Segment Insights',
  redFlag = 'Red Flag',
}

interface InsightsProps {
  user: iUser;
}

const Insights = ({ user }: InsightsProps) => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState<InsightsPeriodType>('week');
  const insights: InsightsReducerStateType = useSelector(selectInsights);
  const [lookerUrls, setLookerUrls] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<InsightsTabs | null>(InsightsTabs.redFlag);
  const [loadingIframes, setLoadingIframes] = useState<boolean[]>([]);

  const startDate = insights?.periodStart && DateTime.fromISO(insights?.periodStart).toFormat(CALENDAR_DATE_FORMAT);
  const endDate = insights?.periodEnd && DateTime.fromISO(insights?.periodEnd).toFormat(CALENDAR_DATE_FORMAT);
  const dateRange = startDate && endDate && `${startDate} - ${endDate}`;
  const isLockedInsights = !insights?.teamAlertThreshold;
  const hasLookerEmbed = Array.isArray(user?.account?.lookerConfigs) && user.account.lookerConfigs.length > 0;

  useEffect(() => {
    dispatch(startLoad());
    services.getInsights(period).then((res) => dispatch(loadInsightsSuccess(res)));
  }, [dispatch, period]);

  useEffect(() => {
    if (activeTab === InsightsTabs.insights || activeTab === InsightsTabs.summary || activeTab === InsightsTabs.sleep) {
      LookerClient.getEmbedUrls().then(({ urls }) => {
        setLookerUrls(urls);
        setLoadingIframes(Array(urls.length).fill(true));
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (hasLookerEmbed) {
      if (user.account.lookerConfigs.length >= 3) {
        setActiveTab(InsightsTabs.sleep);
      } else {
        setActiveTab(InsightsTabs.summary);
      }
    } else {
      setActiveTab(InsightsTabs.redFlag);
    }
  }, [hasLookerEmbed, user]);

  const onPeriodChange = (selectedPeriod: InsightsPeriodType) => setPeriod(selectedPeriod);

  const onChangeTab = (index: number, selectedTabName: InsightsTabs) => setActiveTab(selectedTabName);

  const handleIframeLoad = useCallback((index: number) => {
    setLoadingIframes((prev) => {
      const newLoadingStates = [...prev];
      newLoadingStates[index] = false;
      return newLoadingStates;
    });
  }, []);

  const renderTab = (title, urlIndex) => (
    <Tab title={title}>
      <>
        {loadingIframes[urlIndex] && (
          <SpinnerBackground height="559px">
            <Spinner />
          </SpinnerBackground>
        )}
      </>
      <EmbeddedIframe
        url={lookerUrls[urlIndex]}
        isLoading={loadingIframes[urlIndex]}
        onLoad={() => handleIframeLoad(urlIndex)}
      />
    </Tab>
  );

  return (
    <ManageContainer className="gray">
      <div className="insights">
        <div className="insights-container">
          <TabContainer onChangeTab={onChangeTab} defaultTabIndex={0}>
            {hasLookerEmbed && user.account.lookerConfigs.length >= 3 && renderTab(InsightsTabs.sleep, 2)}
            {hasLookerEmbed && renderTab(InsightsTabs.summary, 0)}
            {hasLookerEmbed && renderTab(InsightsTabs.insights, 1)}
            <Tab title={InsightsTabs.redFlag}>
              <ContentContainer>
                <HeaderContainer>
                  <HeaderLeftSide>
                    <Text
                      className="mr-2 d-flex justify-content-start"
                      size="18px"
                      weight="500"
                      color={isLockedInsights ? '#6a777f' : undefined}
                    >
                      Insights
                      {isLockedInsights && <LockImage src={LockIcon} />}
                    </Text>
                    {!isLockedInsights && <Text size="12px">{dateRange}</Text>}
                  </HeaderLeftSide>
                  {!isLockedInsights && (
                    <PeriodButton firstLabel="Weekly" secondLabel="Monthly" onChange={onPeriodChange} period={period} />
                  )}
                </HeaderContainer>

                {insights.isLoading ? (
                  <SpinnerBackground height="50vh">
                    <Spinner />
                  </SpinnerBackground>
                ) : (
                  <>
                    <SummaryContainer>
                      {!isLockedInsights && (
                        <>
                          <SummaryBadge
                            text="members"
                            count={insights?.membersAmount}
                            changePercentage={insights?.membersChange}
                          />
                          <SummaryBadge
                            text="low scores"
                            count={insights?.lowScoresAmount}
                            changePercentage={insights?.lowScoresChange}
                          />
                        </>
                      )}
                    </SummaryContainer>

                    {!isLockedInsights || insights?.comments?.length ? (
                      <InsightsComments
                        members={insights?.users}
                        comments={insights?.comments}
                        wheels={insights?.wheels}
                        segments={insights?.segments}
                      />
                    ) : (
                      <NoInsightsComments isLockedInsights={isLockedInsights} period={period} />
                    )}
                  </>
                )}
              </ContentContainer>
            </Tab>
          </TabContainer>
        </div>
      </div>
    </ManageContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(Insights);

const ContentContainer = styled.div`
  width: 100%;
  min-height: 700px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  padding: 32px 40px 40px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    align-items: center;
    padding: 26px 16px 16px 16px;
  }
`;

const HeaderLeftSide = styled.div`
  display: flex;
  align-items: baseline;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SummaryContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const LockImage = styled.img`
  width: 12px;
  height: 13px;
  margin-left: 4px;
`;
