import React, { useState, useEffect, useRef } from 'react';
import { iWheelMember } from '../../../../../API/interfaces';
import { Users } from 'react-feather';

type ExtendedWheelMember = Partial<iWheelMember> & {
  userId: string;
  firstName: string;
  lastName: string;
};

interface iAssigneesFilterProps {
  userId: string;
  members: Array<iWheelMember>;
  fetchTasks: (filter: string) => void;
  currentFilter?: string;
}

const AssigneesFilter: React.FC<iAssigneesFilterProps & { currentFilter: string }> = ({
  userId,
  members,
  fetchTasks,
  currentFilter,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState<ExtendedWheelMember[]>([]);
  const [activeMember, setActiveMember] = useState<ExtendedWheelMember | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleClickOutside = (event: MouseEvent) => {
    if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  const onClick = (member: ExtendedWheelMember) => {
    setActiveMember(member);
    setShowDropdown(false);
    fetchTasks(member.userId);
  };

  useEffect(() => {
    const updatedMembers: ExtendedWheelMember[] = [
      { userId: 'all', firstName: 'All', lastName: 'Team' },
      { userId, firstName: 'You', lastName: '' },
      ...members.filter((member) => member.userId !== userId),
    ];
    setFilteredMembers(updatedMembers);

    const matchingMember = updatedMembers.find((member) => member.userId === currentFilter);
    setActiveMember(matchingMember || updatedMembers[0]);
  }, [userId, members, currentFilter]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDropdown]);

  const assigneeIcon = (assignee) =>
    assignee?.userId === 'all' ? (
      <Users color="white" strokeWidth="2" size={12} />
    ) : (
      `${assignee?.firstName[0]}${assignee?.lastName[0] || ''}`
    );

  return (
    <div className="member-dropdown" ref={dropdownRef}>
      <div className={`dropdown-top${showDropdown ? ' opened' : ''}`} onClick={toggleDropdown}>
        <div className="active-member">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start flex-nowrap align-items-center">
              <div className="initials">{assigneeIcon(activeMember)}</div>
              <div className="name">
                {activeMember?.userId === 'all'
                  ? 'All Team'
                  : activeMember?.userId === userId
                  ? 'You'
                  : `${activeMember?.firstName} ${activeMember?.lastName}`}
              </div>
            </div>
            <div className={`arrow${showDropdown ? ' opened' : ''}`} />
          </div>
        </div>
      </div>

      {showDropdown && (
        <div className="dropdown-body">
          {filteredMembers.map((member) => (
            <div key={member.userId} className="member" onClick={() => onClick(member)}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start flex-nowrap align-items-center">
                  <div className="initials">{assigneeIcon(member)}</div>
                  <div className="name">
                    {member.userId === 'all'
                      ? 'All Team'
                      : member.userId === userId
                      ? 'You'
                      : `${member.firstName} ${member.lastName}`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssigneesFilter;
