import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  iCommentsAggregation,
  iUser,
  iWheel,
  iCheckinsAggregation,
  AggregationUnit,
  iWheelPermissions,
  iWheelMember,
  AccountPlan,
} from '../../../../API/interfaces';
import { iTabChildProps } from '../../../Shared/TabContainer/TabContainer';
import { analytics } from '../../../../analytics/analytics';
import { educationService } from '../../../Education/Education.service';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import Wheel from '../../common/Wheel/Wheel';
import HistoryChart from '../../../Shared/HistoryChart/HistoryChart';
import CommentsSection from '../../CommentsSection/CommentsSection';
import SaveShare from '../../common/SaveShare/SaveShare';
import NoResults from '../../common/NoResults/NoResults';
import DateRangeDropdown, {
  DateRangeLabel,
  iDateRangeOptionData,
} from '../../common/DateRangeDropdown/DateRangeDropdown';
import AggregationService from '../../common/AggregationService';
import GenerateReport from '../../common/GenerateReportModal/GenerateReport';
import './MyResults.scss';
import { Padding } from '../../../Shared/Padding/Padding';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import services from '../../../../API/services';

interface iYourResultsProps extends iTabChildProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  members: Array<iWheelMember>;
  user: iUser;
  showCheckinModal: () => void;
}

interface iYourResultsState {
  activeSegmentId: string | null;
  activeSegmentIndex: number | null;
  activeCheckinDate: string | null;
  activeCheckin: iCheckinsAggregation | null;
  checkins: Array<iCheckinsAggregation> | null;
  comments: iCommentsAggregation | null;
  dateRange: iDateRangeOptionData | null;
  showOnlyCommentsWithText: boolean;
}

class MyResults extends Component<iYourResultsProps, iYourResultsState> {
  private readonly aggregationService: AggregationService;

  constructor(props) {
    super(props);

    this.state = {
      activeSegmentId: null,
      activeSegmentIndex: null,
      activeCheckin: null,
      activeCheckinDate: null,
      checkins: null,
      comments: null,
      dateRange: {
        label: 'Latest check ins' as DateRangeLabel,
        from: null,
        to: null,
        unit: 'none' as AggregationUnit,
      },
      showOnlyCommentsWithText: false,
    };

    this.aggregationService = new AggregationService(props.wheel);
  }

  // change active segment
  setActiveSegmentId = (segmentId, segmentIndex) => {
    this.setState({ activeSegmentId: segmentId, activeSegmentIndex: segmentIndex });
  };

  // change active date (timeline entry)
  setActiveCheckinDate = (checkinCreateDate) => {
    const { activeCheckinDate } = this.state;
    this.setState({ activeCheckinDate: activeCheckinDate === checkinCreateDate ? null : checkinCreateDate });
  };

  // change date range
  setDateRange = (dateRange: iDateRangeOptionData) => {
    this.setState({ dateRange });
  };

  // change filter for comments
  setShowOnlyCommentsWithText = (showOnlyCommentsWithText: boolean) => {
    this.setState({ showOnlyCommentsWithText });
  };

  dateRangeDropdownCb = (dateRange: iDateRangeOptionData) => {
    this.setDateRange(dateRange);
    analytics.dateRangeDropdown('myResults', dateRange.label);
  };

  // load data for a particular date range
  loadData = async () => {
    const { dateRange, activeSegmentId, showOnlyCommentsWithText } = this.state;
    const { wheel, user, setIsLoading } = this.props;

    setIsLoading(true);
    const checkinsResponse = await this.aggregationService.loadData({
      wheelId: wheel.id,
      userId: user._id,
      from: dateRange.from?.toISODate(),
      to: dateRange.to?.toISODate(),
      unit: dateRange.unit,
    });

    const checkins = this.aggregationService.mapCheckinsResponse(checkinsResponse);
    const lastCheckin = checkins[0];

    this.setState(
      {
        checkins,
        activeCheckin: lastCheckin || null,
        activeCheckinDate: lastCheckin?.dateRange || null,
        comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
      },
      () => {
        setIsLoading(false);
        educationService.processCheckinEducation();
      }
    );
  };

  async componentDidMount() {
    await this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<iYourResultsProps>, prevState: Readonly<iYourResultsState>) {
    const { wheel } = this.props;
    const { activeCheckinDate, checkins, activeSegmentId, dateRange, showOnlyCommentsWithText } = this.state;

    // change checkin when date is changed
    if (prevState.activeCheckinDate !== activeCheckinDate) {
      this.setState({
        activeCheckin: this.aggregationService.getActiveCheckin(checkins, activeCheckinDate),
      });
    }

    // set comment when segment or comments filter is changed
    if (
      (wheel.isScoreComments && prevState.activeSegmentId !== activeSegmentId) ||
      prevState.showOnlyCommentsWithText !== showOnlyCommentsWithText
    ) {
      this.setState({
        comments: this.aggregationService.getActiveComments(checkins, showOnlyCommentsWithText, activeSegmentId),
      });
    }

    // load data if dateRange is changed
    if (dateRange !== prevState.dateRange) {
      this.loadData();
    }
  }

  render() {
    const {
      activeCheckin,
      checkins,
      activeSegmentId,
      activeSegmentIndex,
      activeCheckinDate,
      comments,
      dateRange,
      showOnlyCommentsWithText,
    } = this.state;
    const { wheel, wheelPermissions, members, setIsLoading, user, showCheckinModal } = this.props;

    const refresh = (): Promise<void> => {
      return this.loadData();
    };

    if (!checkins) {
      return null;
    }

    if (!checkins.length) {
      return (
        <NoResults
          wheel={wheel}
          showCheckinModal={showCheckinModal}
          dateRange={dateRange}
          setDateRange={this.setDateRange}
          shouldShowDateDropdown={dateRange.label !== 'Latest check ins'}
        />
      );
    }

    const activeSegmentName = activeSegmentIndex !== null ? activeCheckin.scores[activeSegmentIndex].segmentName : null;

    const isSleepWheel = !!wheel.checkinSurveyType && wheel.access === WHEEL_ACCESS_TYPES.WEARABLE;

    const downloadSleepCSV = async () => {
      if (!wheel?.checkinSurveyId) return;

      try {
        const response = await services.exportSurveyResults(wheel.checkinSurveyId, wheel.id, user._id);
        const myName = `${user?.first_name}_${user?.last_name}`;

        const blob = new Blob([response.data], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `sleep_journal_${myName}.csv`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading sleep journal CSV:', error);
      }
    };

    return (
      <>
        <div className="my-results">
          <SegmentTags
            averageScore={activeCheckin.averageScore}
            scores={activeCheckin.scores}
            activeSegmentId={activeSegmentId}
            onClick={this.setActiveSegmentId}
            downloadSleepCSV={downloadSleepCSV}
            showSurveyResponsesButton={!!wheel.checkinSurveyId}
          />

          <div className="buttons-container">
            <SaveShare className="education_save-share" wheel={wheel} setIsLoading={setIsLoading} memberId={user._id} />

            <div className="timeline-controls">
              <DateRangeDropdown activeOption={dateRange} onChange={this.dateRangeDropdownCb} />

              <GenerateReport
                lockFeature={!wheelPermissions?.wheel_pdfReport && !isSleepWheel}
                wheelPermissions={wheelPermissions}
                wheel={wheel}
                dateRange={dateRange}
                numberOfMembers={1}
                setIsLoading={setIsLoading}
                activeSegmentId={activeSegmentId}
                activeSegmentName={activeSegmentName}
                activeCheckinDate={activeCheckinDate}
                showOnlyCommentsWithText={showOnlyCommentsWithText}
                isMyResults
                downloadSleepCSV={downloadSleepCSV}
              />
            </div>
          </div>

          <div className="charts-container">
            <Wheel
              className="education_results"
              maxScale={wheel?.maxScale}
              averageScore={activeCheckin.averageScore}
              scores={activeCheckin.scores}
              activeSegmentId={activeSegmentId}
              onClick={this.setActiveSegmentId}
              width={300}
              numberOfCheckins={activeCheckin.numberOfCheckins}
            />
            <HistoryChart
              checkins={checkins}
              activeSegmentId={activeSegmentId}
              activeSegmentIndex={activeSegmentIndex}
              wheelScale={wheel.maxScale}
              onClick={this.setActiveCheckinDate}
              activeCheckinDate={activeCheckinDate}
            />
          </div>
        </div>
        <Padding mPadding={'0px'}>
          {(activeSegmentIndex === null || wheel.isScoreComments) && (
            <CommentsSection
              activeSegmentName={activeSegmentName}
              activeCheckinDate={activeCheckinDate}
              dateRange={dateRange}
              comments={comments}
              refresh={refresh}
              members={members}
              numberOfCheckins={checkins.length}
              showOnlyCommentsWithText={showOnlyCommentsWithText}
              setShowOnlyCommentsWithText={this.setShowOnlyCommentsWithText}
              teamAlertThreshold={wheel.wheelCreator.account?.teamAlertThreshold}
              isReplyEnabled={
                wheel.wheelCreator.account.plan === AccountPlan.BUSINESS ||
                wheel.wheelCreator.account.plan === AccountPlan.PERFORMANCE
              }
            />
          )}
        </Padding>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(MyResults);
