import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { Check } from 'react-feather';
import { iWheel } from '../../../../API/interfaces';
import { tStep } from '../../Sidebar/Sidebar';
import { WHEEL_ACCESS_TYPES, WHEEL_STEP_TYPES } from '../../../../constants';
import WheelAndSegments from './WheelAndSegments';

interface iCompleteStep {
  isTemplate: boolean;
  wheel: Partial<iWheel>;
  setStep: (step: tStep) => void;
  setStepToEdit: (step: tStep) => void;
}

export default ({ wheel, setStep, setStepToEdit, isTemplate }: iCompleteStep) => {
  const classes = useStyles();

  const onHover = (step: tStep) => setStepToEdit(step);
  const onBlur = () => setStepToEdit(null);

  return (
    <div>
      <Typography className={classes.title}>Check if all you choose are correct </Typography>
      <Typography
        className={classes.text}
        onMouseOver={() => onHover('Wheel Name')}
        onMouseOut={onBlur}
        onClick={() => setStep('Wheel Name')}
      >
        {wheel.name}
      </Typography>
      <Typography
        className={classes.smallText}
        onMouseOver={() => onHover('Wheel Name')}
        onMouseOut={onBlur}
        onClick={() => setStep('Wheel Name')}
      >
        {wheel.description}
      </Typography>

      <div
        className={classes.segmentsSection}
        onMouseOver={() => onHover('Segments')}
        onMouseOut={onBlur}
        onClick={() => setStep('Segments')}
      >
        <WheelAndSegments maxScale={wheel.maxScale} segments={wheel.segments} />
      </div>

      <List className={classes.list}>
        <ListItem
          classes={{ root: classes.listItem }}
          onMouseOver={() => onHover('Settings')}
          onMouseOut={onBlur}
          onClick={() => setStep('Settings')}
        >
          <ListItemIcon className={classes.listIcon}>
            <Check size={16} />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                <span className={classes.capitalize}>
                  {wheel.access === WHEEL_ACCESS_TYPES.WEARABLE ? 'WearIT' : wheel.access}
                </span>{' '}
                version of {isTemplate ? 'template' : 'wheel'}
              </span>
            }
          />
        </ListItem>

        <ListItem
          classes={{ root: classes.listItem }}
          onMouseOver={() => onHover('Wheel Type')}
          onMouseOut={onBlur}
          onClick={() => setStep('Wheel Type')}
        >
          <ListItemIcon className={classes.listIcon}>
            <Check size={16} />
          </ListItemIcon>
          <ListItemText
            primary={`Scale from 1 - ${wheel.maxScale} (${
              wheel.step === WHEEL_STEP_TYPES.INTEGER ? 'whole' : 'decimal'
            } number)`}
          />
        </ListItem>

        {wheel.isScoreComments && (
          <ListItem
            classes={{ root: classes.listItem }}
            onMouseOver={() => onHover('Settings')}
            onMouseOut={onBlur}
            onClick={() => setStep('Settings')}
          >
            <ListItemIcon className={classes.listIcon}>
              <Check size={16} />
            </ListItemIcon>
            <ListItemText primary="Segment comments include" />
          </ListItem>
        )}

        {!!wheel.checkinSurveyType && wheel.access === WHEEL_ACCESS_TYPES.WEARABLE && (
          <ListItem
            classes={{ root: classes.listItem }}
            onMouseOver={() => onHover('Settings')}
            onMouseOut={onBlur}
            onClick={() => setStep('Settings')}
          >
            <ListItemIcon className={classes.listIcon}>
              <Check size={16} />
            </ListItemIcon>
            <ListItemText primary="Sleep Journal Survey activate" />
          </ListItem>
        )}
      </List>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: '18px',
    lineHeight: 1.33,
    fontWeight: 500,
    color: '#0c2337',
  },
  text: {
    marginTop: '32px',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#2b2e31',
    cursor: 'pointer',
  },
  smallText: {
    marginTop: '4px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#2b2e31',
    cursor: 'pointer',
  },
  segmentsSection: {
    marginTop: '48px',
  },
  list: {
    marginTop: '44px',
  },
  listItem: {
    marginTop: '24px',
    minHeight: 'initial !important',
    padding: '0 !important',
    cursor: 'pointer',
  },
  listIcon: {
    color: '#0c2337',
    minWidth: '24px',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
});
