import { APP_PLATFORMS } from '../constants';
import { DateRangeLabel } from '../Components/WheelPage/common/DateRangeDropdown/DateRangeDropdown';
import { eSortOptions } from '../Components/Dashboard/_types';

class GoogleAnalytics {
  // @ts-ignore
  private gtag = window.gtag;

  private _sendEvent(event: string, payload?: any) {
    return this.gtag('event', event, payload);
  }

  signUp() {
    this._sendEvent('signup_submit_web');
  }

  signUpSSO() {
    this._sendEvent('signup_submit_web_sso');
  }

  signUpLink() {
    this._sendEvent('click_signup_web');
  }

  publicWheelSignUp() {
    this._sendEvent('signup_public_wheel_web');
  }

  login() {
    this._sendEvent('login_web');
  }

  loginSSO() {
    this._sendEvent('login_web_sso');
  }

  loginLink() {
    this._sendEvent('click_login_web');
  }

  publicWheelLogin() {
    this._sendEvent('login_public_wheel_web');
  }

  createWheelAttempt() {
    this._sendEvent('click_create_wheel_web');
  }

  createWheelSubmit(segmentsCount: number, access) {
    this._sendEvent('create_wheel_submit_web', {
      segments_count: segmentsCount,
      access,
    });
  }

  createCheckinAttempt(isPublic: boolean) {
    this._sendEvent(isPublic ? 'public_checkin_web' : 'checkin_web');
  }

  createCheckinSubmit(isPublic: boolean) {
    this._sendEvent(isPublic ? 'public_checkin_submit_web' : 'checkin_submit_web');
  }

  shareWheel() {
    this._sendEvent('invite_member_web');
  }

  forgetPassword() {
    this._sendEvent('click_forgot_password_web');
  }

  forgotPasswordLink() {
    this._sendEvent('click_forgot_password_web');
  }

  saveNewPassword() {
    this._sendEvent('save_new_password_web');
  }

  wheelSettings() {
    this._sendEvent('wheel_settings_web');
  }

  downloadApp(platform: APP_PLATFORMS) {
    this._sendEvent(`download_${platform}_web`);
  }

  deleteWheel() {
    this._sendEvent(`delete_wheel_web`);
  }

  tutorial() {
    this._sendEvent('tutorial_web');
  }

  onboarding_tag_personal() {
    this._sendEvent('personal_web');
  }

  onboarding_tag_business() {
    this._sendEvent('business_web');
  }

  onboarding_tag_school() {
    this._sendEvent('school_web');
  }

  onb_signup_personal() {
    this._sendEvent('onb_signup_personal_web');
  }

  onb_signup_business() {
    this._sendEvent('onb_signup_business_web');
  }

  onb_signup_school() {
    this._sendEvent('onb_signup_school_web');
  }

  saveImage(share = false) {
    this._sendEvent(`save_${share ? 'share_' : ''}image_web`);
  }

  duplicate_wheel() {
    this._sendEvent(`duplicate_wheel_web`);
  }

  joinWheel() {
    this._sendEvent('join_wheel_web');
  }

  joinBuddyRoleWheel() {
    this._sendEvent('buddy_joined');
  }

  onboardingCustomEvent() {
    // @ts-ignore
    dataLayer.push({ event: 'optimize.activate' });
  }

  duplicateOnWheelPage() {
    this._sendEvent('duplicate_wp_web');
  }

  settingsOnWheelPage() {
    this._sendEvent('wheel_settings_wp_web');
  }

  downloadXLS() {
    this._sendEvent('download_xls_web');
  }

  checkinReminderTab() {
    this._sendEvent('checkin_reminder_tab');
  }

  checkinReminderMemberOff() {
    this._sendEvent('checkin_reminder_member_off');
  }

  checkinReminderAdminOff() {
    this._sendEvent('checkin_reminder_admin_off');
  }

  checkinReminderAdminOn() {
    this._sendEvent('checkin_reminder_admin_on');
  }

  leaveWheel() {
    this._sendEvent('leave_wheel_web');
  }

  clickInviteLink(type) {
    this._sendEvent('click_invite_link', {
      type,
    });
  }

  clickInviteBuddy() {
    this._sendEvent('click_invite_buddy');
  }

  dateRangeDropdown(tab, dropDownOption: DateRangeLabel) {
    const eventName = {
      myResults: 'my_results_filter_open',
      teamResults: 'team_results_filter_open',
      memberResults: 'member_results_filter_open',
    }[tab];
    const type = {
      'Latest check ins': 'last_checkins',
      'Latest weekly results': 'latest_weekly_results',
      'Last week': 'last_week',
      'Last 28 days': 'last_month',
      'Last quarter': 'last_quarter',
      'Last year': 'last_year',
      'Custom range': 'custom',
    }[dropDownOption];

    this._sendEvent(eventName, type);
  }

  clickMembersTab() {
    this._sendEvent('click_members_tab');
  }

  clickGenerateReport() {
    this._sendEvent('click_generate_report');
  }

  generatePdfReport(tab, isAdmin) {
    const eventName = {
      myResults: 'generate_pdf_my_results',
      teamResults: 'generate_pdf_team_results',
      memberResults: 'generate_pdf_member_results',
    }[tab];
    const payload = tab === 'myResults' ? { role: isAdmin ? 'admin' : 'member' } : {};

    this._sendEvent(eventName, payload);
  }

  clickHelp(location) {
    const payload = { location: { beforeSignIn: 'before', afterSignIn: 'after', publicCheckin: 'public' }[location] };

    this._sendEvent('click_help', payload);
  }

  submitHelp() {
    this._sendEvent('help_submit');
  }

  clickExpandComments(expand: boolean) {
    this._sendEvent(expand ? 'expand_comment' : 'collapse_button');
  }

  clickCommentsFilter(option) {
    const eventName = {
      allCheckins: 'comments_all_checkins',
      onlyWithText: 'comments_just_comments',
    }[option];

    this._sendEvent(eventName);
  }

  clickUpgradeImHeader() {
    this._sendEvent('upgrade_header');
  }

  clickFeaturesInMenu() {
    this._sendEvent('our_features_menu');
  }

  clickOnLockedFeature(feature) {
    const eventName = {
      'generate-report': 'upgrade_generate_report',
      'pdf-report': 'upgrade_pdf_report',
      members: 'upgrade_members',
    }[feature];

    eventName && this._sendEvent(eventName);
  }

  questionnaireClickOnTrigger() {
    this._sendEvent('questionnaire');
  }

  questionnaireSend() {
    this._sendEvent('questionnaire_send');
  }

  requestDemo(location) {
    const payload = {
      location: {
        'page-header': 'upgrade_header',
        'user-menu': 'our_features',
        'generate-report': 'generate_report',
        members: 'upgrade_members',
        'pdf-report': 'upgrade_pdf_report',
        questionnaire: 'questionnaire',
      }[location],
    };

    this._sendEvent('request_demo', payload);
  }

  templateTab() {
    this._sendEvent('template_tab');
  }

  insightsTab() {
    this._sendEvent('insight_tab');
  }

  useDefaultTemplate(accountTag: string, templateName: string) {
    const name = `${accountTag} ${templateName}`.replace(' ', '_');
    this._sendEvent('use_template', { name });
  }

  useOwnTemplate() {
    this._sendEvent('use_own_template');
  }

  createTemplate() {
    this._sendEvent('create_template');
  }

  createTemplateSubmit() {
    this._sendEvent('create_template_submit');
  }

  teachersTab() {
    this._sendEvent('teachers_tab');
  }

  teamMembersTab() {
    this._sendEvent('team_members_tab');
  }

  inviteTeamMembers(type: 'Teachers' | 'Team Members') {
    this._sendEvent('invite_teacher', { type });
  }

  inviteTeamMembersSubmit(type: 'Teachers' | 'Team Members') {
    this._sendEvent('invite_team_members_submit', { type });
  }

  deleteTeamMember(type: 'Teachers' | 'Team Members') {
    this._sendEvent('delete_team_member', { type });
  }

  deleteTeamMemberConfirm(type: 'Teachers' | 'Team Members') {
    this._sendEvent('delete_team_member_confirm', { type });
  }

  resendInvite(type: 'Teachers' | 'Team Members') {
    this._sendEvent('resend_invite', { type });
  }

  resendInviteSubmit(type: 'Teachers' | 'Team Members') {
    this._sendEvent('resend_invite_submit', { type });
  }

  dashboardSearch() {
    this._sendEvent('dashboard_search');
  }

  dashboardSorting(type: eSortOptions) {
    this._sendEvent('dashboard_sorting', { type });
  }

  moveToFolder() {
    this._sendEvent('move_to_folder');
  }

  createFolder() {
    this._sendEvent('create_folder');
  }

  createSubfolder() {
    this._sendEvent('create_subfolder');
  }

  openResourceFromMenu() {
    this._sendEvent('resources_menu_web');
  }
  chooseResourceTab() {
    this._sendEvent('resources_tab_web');
  }
  openCrisisFromMenu() {
    this._sendEvent('crisis_support_menu_web');
  }
  chooseCrisisSupportTab() {
    this._sendEvent('crisis_support_tab_web');
  }
  openTutorialsFromMenu() {
    this._sendEvent('tutorial_menu_web');
  }
  chooseTutorialsTab() {
    this._sendEvent('tutorial_tab_web');
  }

  crisisSupportCall(name) {
    this._sendEvent('crisis_support_call_web', { name });
  }

  crisisSupportRedirect(name) {
    this._sendEvent('crisis_support_call', { name });
  }

  clickInsightsFree() {
    this._sendEvent('insights_free');
  }

  clickInsightsPaid() {
    this._sendEvent('insights_paid');
  }

  unlockRedFlags() {
    this._sendEvent('unlock_red_flags');
  }

  supportNowClick() {
    this._sendEvent('support_now_click');
  }

  supportNowSent() {
    this._sendEvent('support_now_sent');
  }

  markAllNotificationsAsRead() {
    this._sendEvent('notifications_readall_web');
  }

  openNotifications() {
    this._sendEvent('notifications_web');
  }

  openMemberResultsCheckinAssist() {
    this._sendEvent('checkin_assist_member_results_web');
  }

  openMembersCheckinAssist() {
    this._sendEvent('checkin_assist_members_web');
  }

  submitCheckinAssist() {
    this._sendEvent('checkin_assist_submit_web');
  }

  openSuggestedResources() {
    this._sendEvent('suggested_resources_popup_web');
  }

  clickSuggestedResourcesTab() {
    this._sendEvent('suggested_resources_tab_web');
  }

  clickAiSuggestedTab() {
    this._sendEvent('ai_suggested_tab');
  }

  clickAiSuggestedPopup() {
    this._sendEvent('ai_suggested_popup');
  }

  clickAiTemplateSupport() {
    this._sendEvent('ai_template_support');
  }

  clickAiTemplatePlan() {
    this._sendEvent('ai_template_plan');
  }

  clickAiTemplateResources() {
    this._sendEvent('ai_template_resources');
  }

  clickOnbPersonalDataAdded() {
    this._sendEvent('onb_personal_data_added');
  }

  clickOnbUserTagAdded() {
    this._sendEvent('onb_user_tag_added');
  }

  clickOnbLetsContinue() {
    this._sendEvent('onb_lets_continue');
  }
  clickOnbQuestionsStarted() {
    this._sendEvent('onb_questions_started');
  }

  clickOnbQuestionsFinished() {
    this._sendEvent('onb_questions_finished');
  }

  clickOnbSkipAll() {
    this._sendEvent('onb_skip_all');
  }

  clickOnbTestCheckinSkip() {
    this._sendEvent('onb_test_checkin_skip');
  }

  clickOnbTestCheckinComplete() {
    this._sendEvent('onb_test_checkin_complete');
  }

  clickProfileUpdateNotif() {
    this._sendEvent('profile_update_notif');
  }

  clickProfileUpdateStart() {
    this._sendEvent('profile_update_start');
  }

  clickProfileUpdateComplete() {
    this._sendEvent('profile_update_complete');
  }

  clickTaskReminderTab() {
    this._sendEvent('task_reminder_tab');
  }

  clickTaskReminderCreate() {
    this._sendEvent('task_reminder_create');
  }

  clickTaskReminderCreated() {
    this._sendEvent('task_reminder_created');
  }
}

export const googleAnalytics = new GoogleAnalytics();
