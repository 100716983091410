import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications } from './Notifications.selectors';
import NotificationItem from './NotificationItem';
import { Text } from '../../Typography/Typography';
import notificationsEmptyStateImage from '../../../../Assets/images/notifications-empty-state.png';
import services from '../../../../API/services';
import {
  deleteNotificationsSuccess,
  getNotificationsSuccess,
  markAllNotificationsAsReadSuccess,
  resetNotifications,
} from './Notifications.actions';
import { analytics } from '../../../../analytics/analytics';
import { IoMdClose } from 'react-icons/io';
import { Spinner, SpinnerBackground } from '../../Spinner/Spinner';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NotificationsDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { notificationsList } = useSelector(selectNotifications);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingNotificationId, setDeletingNotificationId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const isFetchingRef = useRef(false);

  const PAGE_SIZE = 20;

  useEffect(() => {
    if (!isOpen) return;

    const fetchInitialNotifications = async () => {
      if (notificationsList.length > 0) {
        setPage(Math.ceil(notificationsList.length / PAGE_SIZE) + 1);
        setHasMore(true);
        return;
      }

      setIsInitialLoading(true);
      try {
        const { data } = await services.getNotifications(1, PAGE_SIZE);
        dispatch(getNotificationsSuccess(data.notifications));
        setPage(2);
        setHasMore(data.notifications.length === PAGE_SIZE);
      } catch (err) {
        console.error(err);
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchInitialNotifications();
  }, [isOpen, notificationsList.length]);

  const loadMoreNotifications = async () => {
    if (isLoading || isFetchingRef.current || !hasMore) return;

    isFetchingRef.current = true;

    setIsLoading(true);
    try {
      const { data } = await services.getNotifications(page, PAGE_SIZE);
      dispatch(getNotificationsSuccess(data.notifications));
      if (data.notifications.length < PAGE_SIZE) {
        setHasMore(false);
      }
      setPage((prev) => prev + 1);
    } catch (err) {
      console.error(err);
    } finally {
      isFetchingRef.current = false;
      setIsLoading(false);
    }
  };

  const closeDrawer = () => {
    isOpen && onClose();
  };

  const onDeleteNotifications = async (id?: string) => {
    if (isDeleting || deletingNotificationId) return;

    try {
      if (id) {
        setDeletingNotificationId(id);
      } else {
        setIsDeleting(true);
      }

      await services.deleteNotifications({ id });
      dispatch(deleteNotificationsSuccess(id));
    } catch (error) {
      console.error('Error deleting notification:', error);
    } finally {
      setDeletingNotificationId(null);
      setIsDeleting(false);
    }
  };

  const onDeleteNotificationsHandler = async (id: string) => {
    await onDeleteNotifications(id);
  };

  const NotificationsList = useMemo(() => {
    return (
      <>
        {notificationsList.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            closeDrawer={closeDrawer}
            onDeleteNotificationsHandler={onDeleteNotificationsHandler}
            isDeleting={deletingNotificationId === notification.id}
          />
        ))}
        {isLoading && (
          <div className="d-flex justify-content-center p-1" style={{ height: '80px' }}>
            <Spinner />
          </div>
        )}
      </>
    );
  }, [notificationsList, deletingNotificationId, isLoading]);

  const onMarkAllAsRead = async () => {
    await services
      .markAllNotificationsAsRead()
      .then(() => dispatch(markAllNotificationsAsReadSuccess()))
      .catch((error) => error);
    analytics.markAllNotificationsAsRead();
  };

  const handleScroll = () => {
    const el = containerRef.current;
    if (!el) return;

    const nearBottom = el.scrollHeight - el.scrollTop <= el.clientHeight + 150;

    if (nearBottom) {
      loadMoreNotifications();
    }
  };

  useEffect(() => {
    const el = containerRef.current;
    if (!isOpen || !el) return;

    el.addEventListener('scroll', handleScroll);
    return () => el.removeEventListener('scroll', handleScroll);
  }, [isOpen, handleScroll]);

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <NotificationsContainer isOpen={isOpen} ref={containerRef}>
        <ContentWrapper>
          {isInitialLoading ? (
            <SpinnerBackground height="100vh">
              <Spinner />
            </SpinnerBackground>
          ) : (
            <>
              {notificationsList.length ? (
                <>
                  <Header>
                    <MarkAllAsReadButton onClick={onMarkAllAsRead}>Mark all as read</MarkAllAsReadButton>
                    <ButtonGroup>
                      {/* <DeleteAllButton onClick={() => onDeleteNotifications()}>
                    {isDeleting ? 'Deleting...' : 'Delete all'}
                    </DeleteAllButton> */}
                      <StyledCloseIcon onClick={closeDrawer} />
                    </ButtonGroup>
                  </Header>
                  {NotificationsList}
                </>
              ) : (
                <EmptyNotificationsContainer>
                  <Text margin="20px 0" size="14px" color="#7f8b96">
                    There are no notifications yet
                  </Text>
                  <StyledImg src={notificationsEmptyStateImage} />
                </EmptyNotificationsContainer>
              )}
            </>
          )}
        </ContentWrapper>
      </NotificationsContainer>
    </ClickAwayListener>
  );
};

export default NotificationsDrawer;

const NotificationsContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 70px;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 14;
  width: 360px;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  display: contents;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 24px 16px 8px 16px;
`;

const MarkAllAsReadButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  color: #005ebd;
  font-size: 12px;
  cursor: pointer;
`;

const DeleteAllButton = styled(MarkAllAsReadButton)`
  color: #fb463b;
`;

const EmptyNotificationsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`;

const StyledImg = styled.img`
  height: auto;
  width: 75%;
`;

const StyledCloseIcon = styled(IoMdClose)`
  width: 20px;
  height: 20px;
  color: #b1b1b1;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
