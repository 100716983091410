import React from 'react';
import styled from 'styled-components';
import {
  iWheel,
  iWheelMember,
  WheelMemberRole,
  WheelMemberRoleType,
  WheelRoleNaming,
} from '../../../../API/interfaces';
import { WheelMember } from './WheelMember';
import { Text } from '../../../Shared/Typography/Typography';
import { WheelMembersInviteButton } from './WheelMembersInviteButton';
import { makeStyles, Tooltip } from '@material-ui/core';
import { BiInfoCircle } from 'react-icons/bi';
import { roleTooltipDescription } from './utils';
import { AssistMember } from '../../WheelPage';

interface iWheelMembersListProps {
  wheel: iWheel;
  roleWheelMembers: iWheelMember[];
  allWheelMembers?: iWheelMember[];
  role: WheelMemberRoleType;
  changeRole: (userId: string | number, newRole: WheelMemberRole) => void;
  deleteMember: (memberId: string | number) => void;
  userId?: string;
  wheelRole?: WheelMemberRoleType;
  openCheckinAssistModal?: (member: AssistMember) => void;
}

const WheelMembersListContainer = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 16px;
`;

const WheelMembersList: React.FC<iWheelMembersListProps> = ({
  wheel,
  roleWheelMembers,
  allWheelMembers,
  role,
  changeRole,
  deleteMember,
  userId = '',
  wheelRole,
  openCheckinAssistModal,
}) => {
  const classes = useStyles();
  const isAdmin = role === WheelMemberRole.ADMIN;
  const isAccountabilityBuddy = role === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const listRoleTitle = `${WheelRoleNaming[role]}${!isAccountabilityBuddy ? 's' : ''} ${
    roleWheelMembers.length > 1 ? `( ${roleWheelMembers.length} )` : ''
  }`;

  const enrichedRoleWheelMembers = roleWheelMembers.map((roleMember) => {
    const correspondingMember = wheel.members.find((member) => member.userId === roleMember.userId);

    return {
      ...roleMember,
      isManuallyAddedUser: correspondingMember?.isManuallyAddedUser ?? false,
      isPasswordReset: correspondingMember?.isPasswordReset ?? false,
      isManuallyCreatedUser: correspondingMember?.isManuallyCreatedUser ?? false,
    };
  });

  return (
    <div className="mb-4">
      <WheelMembersListContainer className="justify-content-lg-start">
        <HeaderWrapper>
          <Text className="mr-1" size="14px">
            {listRoleTitle}
          </Text>
          <Tooltip
            arrow
            title={roleTooltipDescription[role]}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <TooltipContentWrapper>
              <BiInfoCircle size={16} color="#0c2337" />
            </TooltipContentWrapper>
          </Tooltip>
        </HeaderWrapper>
        {!isAdmin && <WheelMembersInviteButton wheel={wheel} role={role} userId={userId} />}
      </WheelMembersListContainer>
      {enrichedRoleWheelMembers &&
        enrichedRoleWheelMembers.map((wheelMember) => {
          return (
            <WheelMember
              wheelId={wheel.id}
              member={wheelMember}
              key={wheelMember.userId}
              wheelName={wheel.name}
              onChangeRole={changeRole}
              onDeleteMember={deleteMember}
              allWheelMembers={allWheelMembers}
              wheelRole={wheelRole}
              isAdminList={isAdmin}
              openCheckinAssistModal={openCheckinAssistModal}
              {...(isAdmin ? { adminsNumber: roleWheelMembers.length } : {})}
            />
          );
        })}
    </div>
  );
};

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  arrow: {
    color: '#fff',
  },
});

export default WheelMembersList;
