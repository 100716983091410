import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utils from '../../../../Shared/utils';

interface CustomDateTimePickerProps {
  taskData: any;
  setTaskData: (updatedData: any) => void;
  open: boolean;
  onClose: () => void;
  setIsPopupVisible: (value: boolean) => void;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
  taskData,
  setTaskData,
  open,
  onClose,
  setIsPopupVisible,
}) => {
  const initialDate = dayjs(taskData.dueDateTime || dayjs().toISOString());
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialDate);
  const currentDate = dayjs();
  const isMobile = utils.checkIsPhone();

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
      setTaskData((prevTaskData) => ({
        ...prevTaskData,
        dueDateTime: newDate.toISOString(),
      }));
    }
  };

  const handleClose = () => {
    if (isMobile) {
      setTimeout(() => {
        setIsPopupVisible(false);
        onClose();
      }, 100);
    } else {
      setIsPopupVisible(false);
      onClose();
    }
  };

  const shouldDisableTime = (value: Dayjs, view: 'hours' | 'minutes' | 'seconds') => {
    if (!selectedDate) return false;

    const isToday = selectedDate.isSame(currentDate, 'day');
    const selectedHour = selectedDate.hour();

    if (isToday) {
      if (view === 'hours') {
        return value.hour() < currentDate.hour();
      }
      if (view === 'minutes' && selectedHour === currentDate.hour()) {
        return value.minute() < currentDate.minute();
      }
    }

    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={selectedDate}
        defaultValue={currentDate}
        onChange={handleDateChange}
        minDate={currentDate}
        open={open}
        onClose={handleClose}
        ampm={false}
        shouldDisableTime={shouldDisableTime}
        timeSteps={{ minutes: 1 }}
        slotProps={{
          actionBar: {
            actions: isMobile ? ['accept'] : [],
          },
          textField: { sx: { visibility: 'hidden', width: 0, height: 0, padding: 0, margin: 0 } },
          popper: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport',
                  altAxis: true,
                  tether: false,
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [-2, -438],
                },
              },
            ],
            sx: { ...pickerStyles },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;

const pickerStyles = {
  zIndex: 50,
  '& .MuiPickersPopper-paper': {
    boxShadow: 'none !important',
    paddingBottom: '10px',
    borderRadius: '8px 8px 0 0 !important',
  },
  '& .MuiPickersLayout-contentWrapper': {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
  },
  '& .Mui-selected': {
    backgroundColor: '#FF5722',
    color: '#fff !important',
    borderRadius: '8px',
    background: 'linear-gradient(117deg, rgb(250, 136, 104) 1%, rgb(255, 85, 77)) !important',
  },
  '& .MuiMultiSectionDigitalClockSection-item, & .MuiPickersYear-yearButton, & .MuiPickersCalendarHeader-label': {
    fontSize: '0.75rem !important',
  },
  '& .MuiPickersDay-root': {
    borderRadius: '8px !important',
  },
  '& .MuiButton-root': {
    color: '#2b2e31 !important',
  },
  '& .MuiMultiSectionDigitalClock-root, & .MuiDateCalendar-root, & .MuiPickersSlideTransition-root': {
    maxHeight: '330px !important',
  },
  '& .MuiPickersSlideTransition-root': {
    maxHeight: '234px !important',
  },
  '& .MuiClockPointer-root, & .MuiClockPointer-thumb, & .MuiClock-pin': {
    background: 'linear-gradient(117deg, rgb(250, 136, 104) 1%, rgb(255, 85, 77)) !important',
    backgroundColor: 'unset !important',
  },
};
