import { Notification, NotificationsActionTypes } from './Notifications.model';

export const getNotificationsSuccess = (payload: Notification[]) => ({
  type: NotificationsActionTypes.GET_NOTIFICATIONS,
  payload,
});

export const resetNotifications = () => ({
  type: NotificationsActionTypes.RESET_NOTIFICATIONS,
});

export const markAllNotificationsAsReadSuccess = () => ({
  type: NotificationsActionTypes.MARK_ALL_NOTIFICATIONS_AS_READ,
});

export const markNotificationAsReadSuccess = (payload: string) => ({
  type: NotificationsActionTypes.MARK_NOTIFICATION_AS_READ,
  payload,
});

export const deleteNotificationsSuccess = (payload?: string) => ({
  type: NotificationsActionTypes.DELETE_NOTIFICATIONS,
  payload,
});
