import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import QuestionScreen from './QuestionScreen';
import InfoScreen from './InfoScreen';
import { questionnaireData, commonQuestions } from './constants';
import { StaticContentScreen } from './StaticContentScreen';
import CheckinScreen from './CheckinScreen';
import { getNextStep, updateUserData } from './helpers';
import services from '../../../API/services';
import { PersonalData, iUser, iWheel } from '../../../API/interfaces';
import { updatePersonalDataAction } from '../Auth.actions';
import { RootState } from '../../../Redux/Reducers';
import PersonalInfoScreen from './PersonalInfoScreen';

interface UserData {
  userRole?: string[];
  customOptions?: { [key: number]: string[] };
  [key: string]: any;
}

interface StepsProps {
  user: iUser;
  userType: string;
  setUserType: (userType: string | null) => void;
  updatePersonalDataAction: (personalData: PersonalData) => void;
  setStep: (step: number) => void;
}

const Steps: React.FC<StepsProps> = ({ user, userType, setUserType, updatePersonalDataAction, setStep }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [userData, setUserData] = useState<UserData>({});
  const [wheel, setWheel] = useState<iWheel>();
  const history = useHistory();

  const questions = questionnaireData[userType] || [];
  const hasStaticScreen = userType !== 'Personal';
  const personalInfoSteps = ['gender', 'nationality', 'age'];
  const infoScreenSteps = [...commonQuestions];

  const shouldSkipQuestions =
    userType === 'Education' &&
    (userData.userRole?.includes('Secondary School Student') || userData.userRole?.includes('Primary School Student'));

  const stepsOrder =
    userType === 'Personal'
      ? [
          ...personalInfoSteps.map(() => 'personalInfo'),
          ...questions.map(() => 'questions'),
          ...infoScreenSteps.map(() => 'infoScreen'),
          'checkinScreen',
        ]
      : [
          ...(shouldSkipQuestions ? ['questions'] : []),
          ...(!shouldSkipQuestions ? questions.map(() => 'questions') : []),
          ...(hasStaticScreen ? ['staticContent'] : []),
          ...personalInfoSteps.map(() => 'personalInfo'),
          ...infoScreenSteps.map(() => 'infoScreen'),
          'checkinScreen',
        ];

  const questionStartIndex = stepsOrder.indexOf('questions');

  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      userTag: userType,
      userRole: prev.userRole || user.personalData.userRole || [],
      focus: prev.focus || user.personalData.focus || [],
      challenges: prev.challenges || user.personalData.challenges || [],
      goals: prev.goals || user.personalData.goals || [],
      reason: prev.reason || user.personalData.reason || [],
      age: prev.age || user.personalData.age || null,
      gender: prev.gender || user.personalData.gender || null,
      nationality: prev.nationality || user.personalData.nationality || null,
    }));
  }, [userType]);

  useEffect(() => {
    if (stepsOrder[currentStep] === 'infoScreen') {
      const fetchWheel = async () => {
        try {
          const fetchedWheel = await services.getPersonalWheel();
          console.log(fetchedWheel);
          setWheel(fetchedWheel);
        } catch (error) {
          console.error('Failed to load wheel:', error);
        }
      };
      fetchWheel();
    }
  }, [currentStep, history]);

  const handleNext = (data: Partial<UserData> = {}, nextStep?: number) => {
    setUserData((prev) => ({ ...prev, ...data }));

    setTimeout(async () => {
      let next = nextStep ?? getNextStep(currentStep, userData, userType, stepsOrder.length);

      if (shouldSkipQuestions && stepsOrder[next] === 'personalInfo') {
        next = stepsOrder.indexOf('personalInfo');
      }

      if (next < stepsOrder.length) {
        setCurrentStep(next);
      } else if (stepsOrder[currentStep] !== 'checkinScreen') {
        await services.updatePersonalData({ ...user.personalData, ...userData, ...data });
        updatePersonalDataAction({ ...user.personalData, ...userData, ...data });
        history.push('/dashboard');
      }
    }, 0);
  };

  const handleBack = async () => {
    const isFirstStepForPersonal = userType === 'Personal' && currentStep === 0;
    const isReturningToUserRole =
      userType === 'Education' && shouldSkipQuestions && currentStep === stepsOrder.indexOf('personalInfo');

    if (isReturningToUserRole) {
      const updatedData: PersonalData = {
        ...user.personalData,
        userRole: [],
      };

      await services.updatePersonalData(updatedData);
      updatePersonalDataAction(updatedData);
      setUserData(updatedData);

      requestAnimationFrame(() => {
        setCurrentStep(questionStartIndex);
      });
      return;
    }

    if (currentStep === 0 || isFirstStepForPersonal) {
      const updatedData: PersonalData = {
        userTag: null,
        userRole: [],
        focus: [],
        resources: [],
        challenges: [],
        goals: [],
        reason: [],
        age: userData.age || null,
        gender: userData.gender || null,
        nationality: userData.nationality ?? null,
      };

      await services.updatePersonalData(updatedData);
      updatePersonalDataAction(updatedData);
      setUserData(updatedData);

      requestAnimationFrame(() => {
        setUserType(null);
        setStep(0);
        setCurrentStep(0);
      });
      return;
    }

    setCurrentStep((prev) => prev - 1);
  };

  const renderStep = () => {
    const stepType = stepsOrder[currentStep];

    if (stepType === 'questions') {
      const questionIndex = currentStep - questionStartIndex;
      const questionData = questions[questionIndex];

      if (!questionData) {
        setCurrentStep((prev) => prev + 1);
        return null;
      }

      return (
        <QuestionScreen
          data={questions[currentStep - questionStartIndex]}
          onNext={handleNext}
          onBack={handleBack}
          userData={userData}
          setUserData={setUserData}
          step={currentStep}
          totalSteps={stepsOrder.length - 1}
          userType={userType}
          personalData={user.personalData}
          updatePersonalDataAction={updatePersonalDataAction}
          questionsLength={questions.length}
          questionStartIndex={questionStartIndex}
        />
      );
    }

    if (stepType === 'personalInfo') {
      const stepIndex = currentStep - stepsOrder.indexOf('personalInfo');
      return (
        <PersonalInfoScreen
          onNext={handleNext}
          onBack={handleBack}
          step={currentStep}
          setStep={setCurrentStep}
          personalInfoSteps={personalInfoSteps}
          stepIndex={stepIndex}
          totalSteps={stepsOrder.length - 1}
        />
      );
    }

    if (stepType === 'staticContent') {
      return (
        <StaticContentScreen
          onNext={handleNext}
          onBack={handleBack}
          step={currentStep}
          text="Or you can skip the questions to start checking in with iyarn and come back to the profile setup later via the settings page."
          totalSteps={stepsOrder.length - 1}
          isOnboarding
        />
      );
    }

    if (stepType === 'infoScreen') {
      const commonStepIndex = currentStep - stepsOrder.indexOf('infoScreen');
      return (
        <InfoScreen
          data={infoScreenSteps[commonStepIndex]}
          onNext={handleNext}
          onBack={handleBack}
          step={currentStep}
          totalSteps={stepsOrder.length - 1}
        />
      );
    }

    if (stepType === 'checkinScreen') {
      return <CheckinScreen wheel={wheel} onBack={handleBack} />;
    }

    return null;
  };

  return <div>{renderStep()}</div>;
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

export default connect(mapStateToProps, { updatePersonalDataAction })(Steps);
