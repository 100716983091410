import React from 'react';

import { tIconProps } from '../../_types';

const WearItIcon = ({ className }: tIconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M11.578 2.5a.517.517 0 0 1 .27.078c.03.019.059.042.086.068l-.055-.046c.015.01.03.023.043.036l.012.01a.51.51 0 0 1 .136.249.495.495 0 0 1 .011.105l-.005-.072a.5.5 0 0 1 .005.052V6c0 .276-.225.5-.503.5a.502.502 0 0 1-.504-.5V4.206L7.152 8.104a.506.506 0 0 1-.643.057l-.07-.057-2.16-2.147-3.42 3.397a.506.506 0 0 1-.712 0 .498.498 0 0 1 0-.708l3.776-3.75a.506.506 0 0 1 .712 0l2.16 2.147L10.362 3.5H8.557a.503.503 0 0 1-.495-.41L8.054 3c0-.276.225-.5.503-.5z"
        id="6tyhgm6eca"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="mvdpsmh66b" fill="#fff">
        <use xlinkHref="#6tyhgm6eca" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#6tyhgm6eca" />
      <g mask="url(#mvdpsmh66b)" fill="#FFF">
        <path d="M0 0h12.081v12H0z" />
      </g>
    </g>
  </svg>
);

export default WearItIcon;
