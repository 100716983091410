import React from 'react';

interface iStepCounterProps {
  currentStep: number;
  totalSteps: number;
  hasLastStep?: boolean;
}

export default ({ currentStep, totalSteps, hasLastStep }: iStepCounterProps) => (
  <div className="step-counter">
    <span className="current-step">{`${currentStep === totalSteps ? 'Your result' : `Step ${currentStep + 1}`}`}</span>
    {currentStep < totalSteps && <span>{`/${totalSteps + (hasLastStep ? 1 : 0)}`}</span>}
  </div>
);
