import styled, { css } from 'styled-components';
import { FiCopy, FiEdit, FiSettings, FiTrash2, FiUserPlus, FiUsers } from 'react-icons/fi';

interface isPersonalProps {
  isPersonal: boolean;
}

export const Wrapper = styled.div<isPersonalProps>`
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.isPersonal ? '#fefaf2' : '#fff')};
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;
  padding-left: 16px;
  margin-bottom: 16px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 10px 6px 0 rgb(35 55 72 / 20%);
  }
`;

export const WrapperMobile = styled(Wrapper)`
  flex-direction: column;
  position: relative;
  padding: 16px 24px;
`;

export const ChartWrapper = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
`;
export const MainInfo = styled.div`
  flex: 10;
  padding: 16px 0;
`;
export const AdditionalInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
  margin-right: 26px;
`;
export const AdditionalInfoMobile = styled(AdditionalInfo)`
  align-items: flex-start;
`;
export const Labels = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 8px;
`;
export const Options = styled.div<isPersonalProps>`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${(props) => (props.isPersonal ? 'transparent' : '#f8f8f8')};
  padding: 0 9px;
`;
export const OptionsMobile = styled(Options)`
  position: absolute;
  right: 8px;
  top: 16px;
  background: none;
`;
export const Option = styled.div<{ color?: string }>`
  font-family: Rubik;
  font-size: 14px;
  line-height: 1.43;
  color: ${({ color }) => color || '#0e263a'};
`;
export const Text = styled.div`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #0c2337;
`;
export const TextMobile = styled(Text)`
  font-size: 16px;
`;
export const Text1 = styled.div`
  font-family: Rubik;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #0e263a;
`;
export const Text1Mobile = styled.div`
  font-size: 12px;
`;
export const Text2 = styled.div`
  font-size: 12px;
  line-height: 1.67;
  text-align: right;
  color: #5d666e;
`;

export const iconMargin = css`
  margin-right: 8px;
  font-size: 18px;
`;

export const StyledGearIcon = styled(FiSettings)`
  ${iconMargin};
`;

export const StyledCopyIcon = styled(FiCopy)`
  ${iconMargin};
`;

export const StyledInviteIcon = styled(FiUsers)`
  ${iconMargin};
`;

export const StyledInvitePlusIcon = styled(FiUserPlus)`
  ${iconMargin}
`;

export const StyledEditIcon = styled(FiEdit)`
  ${iconMargin}
`;

export const StyledTrashIcon = styled(FiTrash2)`
  ${iconMargin}
  color: #fb463b;
`;
