import styled from 'styled-components';
import userSleep from '../../../../Assets/images/user-sleep.svg';
import downloadSleep from '../../../../Assets/images/download-sleep.svg';

interface SleepButtonProps {
  downloadJournal?: boolean;
  isCheckinStep?: boolean;
  onClick?: () => void;
}

const SleepButton = ({ downloadJournal, isCheckinStep, onClick }: SleepButtonProps) => (
  <SleepJournalButton downloadJournal={downloadJournal} onClick={onClick}>
    <ButtonWrapper downloadJournal={downloadJournal} isCheckinStep={isCheckinStep}>
      <img src={downloadJournal ? downloadSleep : userSleep} alt="user-sleep" />
      <ButtonName isCheckinStep={isCheckinStep}>6 Sleep Journal Responses</ButtonName>
    </ButtonWrapper>
  </SleepJournalButton>
);

export default SleepButton;

export const SleepJournalButton = styled.div<{ downloadJournal?: boolean }>`
  display: inline-block;
  position: relative;
  margin-right: 12px;
  border: 2px solid rgba(64, 64, 66, 0.24);
  white-space: nowrap;
  border-radius: 25px;
  color: #0c2337;
  user-select: none;
  cursor: ${({ downloadJournal = false }) => (downloadJournal ? 'pointer' : 'default')};
  max-height: 38px;
`;

export const ButtonWrapper = styled.div<{ isCheckinStep?: boolean; downloadJournal?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ isCheckinStep = false, downloadJournal = false }) =>
    isCheckinStep ? '1px 12px' : downloadJournal ? '5px 12px' : '3px 12px'};
  border-radius: 24px;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
`;

export const ButtonName = styled.span<{ isCheckinStep?: boolean }>`
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${({ isCheckinStep = false }) => (isCheckinStep ? '6px' : '8px')};
  font-size: ${({ isCheckinStep = false }) => (isCheckinStep ? '12px' : '14px')};
`;
