import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Folder as FolderIcon } from 'react-feather';

import { AccountPlan, iFolder, iUser, WheelAccess, WheelStep } from '../../../../API/interfaces';
import Section from '../common/Section';
import of from '../../../../_utils/of';
import { buildTreeByParent } from '../../../Dashboard/_utils';
import { Dictionary } from 'ramda';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';

interface iFolderStep {
  user: iUser;
  folder?: string;
  onChange: (filedName: string, value: any) => void;
  folders?: iFolder[];
  isTemplate: boolean;
  step: WheelStep;
  access: WheelAccess;
}

const groupById = (acc, item) => Object.assign(acc, { [item.id]: item });

type tFolderProps = Partial<iFolder> & { value: string; id: string; parent: string; ref?: any; child?: tFolderProps[] };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      boxShadow: 'none',
      border: 'solid 1px #cececf',
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
  disableAutoFocus: true,
};

const FolderStep = ({ onChange, folders, folder, access, user }: iFolderStep) => {
  const classes = useStyles();
  const [folderId, setFolderId] = React.useState('');
  const [subFolderId, setSubfolderId] = React.useState('');

  React.useEffect(() => {
    onChange('folder', subFolderId || folderId);
  }, [folderId, subFolderId]);

  const folderProps = React.useMemo<Array<tFolderProps>>(
    () =>
      folders
        ? folders.map((folder) => ({
            parent: folder.parent,
            value: folder.id,
            name: folder.name,
            id: folder.id,
          }))
        : [],
    [folders]
  );

  const folderPropsDict = React.useMemo<Dictionary<tFolderProps>>(() => folderProps.reduce(groupById, {}), [
    folderProps,
  ]);

  // set folder, subfolder on steps switch
  React.useEffect(() => {
    if (!folderId && folder) {
      const selectedFolder = folderPropsDict[folder];
      if (selectedFolder.parent) {
        setFolderId(selectedFolder.parent);
        setSubfolderId(folder);
      } else {
        setFolderId(folder);
      }
    }
  }, [folder, folderPropsDict, subFolderId, folderId]);

  const folderPropsTree = React.useMemo(() => buildTreeByParent<tFolderProps>(folderProps || []), [folderProps]);

  const showSubfolder = React.useMemo(
    () => Boolean(folderPropsDict[folderId] && folderPropsDict[folderId].child.length),
    [folderPropsDict, folderId]
  );

  const isSleepWheel =
    user.account?.plan && user.account.plan !== AccountPlan.FREE && WHEEL_ACCESS_TYPES.WEARABLE === access;
  return (
    <>
      <Section number={isSleepWheel ? 8 : 7} title={`Choose the folder for your wheel, if you want...`}>
        <div className="position-relative">
          <FormControl fullWidth variant="outlined">
            <Select
              displayEmpty
              labelId="select-folder-label"
              value={folderId}
              onChange={(e) => setFolderId(e.target.value as string)}
              renderValue={(selectedFolderId) => {
                if (!selectedFolderId) {
                  return <div style={{ color: '#6a777f' }}>Choose Folder</div>;
                }

                return <div>{folderPropsDict[folderId]?.name}</div>;
              }}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value="" className={classes.firstMenuItem}>
                Choose Folder
              </MenuItem>
              {folderPropsTree.map(of(FolderMenuItem))}
            </Select>
          </FormControl>
        </div>

        {showSubfolder && (
          <div className="pt-4">
            <FormControl fullWidth variant="outlined">
              <Select
                displayEmpty
                labelId="select-folder-label"
                value={subFolderId}
                onChange={(e) => setSubfolderId(e.target.value as string)}
                renderValue={(selectedSubFolderId) => {
                  if (!selectedSubFolderId) {
                    return <div style={{ color: '#6a777f' }}>Choose Subfolder</div>;
                  }

                  return <div>{folderPropsDict[subFolderId]?.name}</div>;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="" className={classes.firstMenuItem}>
                  Choose Subfolder
                </MenuItem>
                {folderPropsDict[folderId].child.map(of(FolderMenuItem))}
              </Select>
            </FormControl>
          </div>
        )}
      </Section>
    </>
  );
};

const FolderMenuItem = React.forwardRef<any, tFolderProps>((props, ref) => {
  return (
    <MenuItem ref={ref as any} key={props.id} {...props}>
      <FolderIcon size="16px" style={{ marginRight: '8px' }} />
      <Option>{props.name}</Option>
    </MenuItem>
  );
});

const mapStateToProps = (state) => ({ user: state.userRD.user });

export default connect(mapStateToProps)(FolderStep);

const Option = styled.div<{ color?: string }>`
  font-family: Rubik;
  font-size: 14px;
  line-height: 1.43;
  color: ${({ color }) => color || '#0e263a'};
`;

const useStyles = makeStyles({
  firstMenuItem: {
    borderBottom: '1px solid #cececf',
    backgroundColor: '#f8f8f8 !important',
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    fontSize: '16px !important',
    color: '#2b2e31',
    opacity: '1 !important',
  },
  '& .MuiMenu-paper': {
    boxShadow: 'none',
    borderRadius: '4px',
    border: 'solid 1px #cececf',
  },
});
