import React from 'react';
import { connect } from 'react-redux';
import { iUser, iWheel, iWheelMember } from '../../../API/interfaces';
import Logo from './Logo/Logo';
import { AccessLabel, MembershipLabel, SleepLabel } from './WheelLabel/WheelLabel';
import CheckinButton from '../common/CheckinButton/CheckinButton';
import Controls from './Controls/Controls';
import { WHEEL_ACCESS_TYPES } from '../../../constants';
import './Header.scss';
import { isWheelAccountabilityBuddy, isWheelAdmin } from '../../Shared/utils';
import { BackButton } from '../../Shared/Buttons/BackButton/BackButton';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';

interface iHeaderProps {
  user: iUser;
  wheel: iWheel;
  toggleCheckinModal: () => void;
  toggleDuplicateWheelModal?: () => void;
  activeBuddy?: iWheelMember;
}

const MemberEmailContainer = styled.div`
  color: #fff;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const Header = ({ wheel, user, toggleCheckinModal, toggleDuplicateWheelModal, activeBuddy }: iHeaderProps) => {
  const isAdmin = isWheelAdmin(wheel, user?._id);
  const isAccountabilityBuddy = isWheelAccountabilityBuddy(wheel, user?._id);
  const isPublicWheel = wheel.access === WHEEL_ACCESS_TYPES.PUBLIC;

  const isPersonal = wheel?.isPersonal;
  return (
    <div className="wheel-page-header">
      <div className="header-row">
        <div>{user && <BackButton text="Back" defaultLink="/dashboard" />}</div>
        <Logo url={wheel.wheelCreator?.account.logo} />
      </div>

      <div className="wheel-labels">
        {wheel.checkinSurveyId && <SleepLabel />}
        <AccessLabel access={wheel.access} isPersonal={isPersonal} />
        <MembershipLabel wheel={wheel} user={user} />
      </div>

      <div className="header-row bottom-row">
        <div>
          <span className="wheel-name">{wheel.name}</span>
        </div>
        <div className="buttons-container">
          {isAccountabilityBuddy ? (
            activeBuddy && (
              <MemberEmailContainer>
                <Text
                  size="12px"
                  color="#fff"
                  margin="0 0 8px 0"
                >{`${activeBuddy?.firstName} ${activeBuddy?.lastName} email`}</Text>
                <Text size="16px" color="#fff">
                  {activeBuddy?.email}
                </Text>
              </MemberEmailContainer>
            )
          ) : (
            <>
              {(isAdmin || !isPublicWheel) && (
                <Controls wheel={wheel} user={user} toggleDuplicateWheelModal={toggleDuplicateWheelModal} />
              )}
              <CheckinButton className="education_checkin_regularly" onClick={toggleCheckinModal} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(Header);
