import React, { CSSProperties, forwardRef, LegacyRef } from 'react';
import { GRADIENTS } from '../../colors';
import './SegmentTag.scss';

interface iSegmentTag {
  title: string;
  score?: number;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isActive: boolean;
  index: number | null;
  hideScore?: boolean;
}

export default forwardRef(
  (
    { title, score, onClick, index, isActive, onMouseOver, onMouseOut, hideScore }: iSegmentTag,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const style: CSSProperties = {};
    // yellow and orange colors should have black font
    style.color = isActive ? (index === 0 || index === 4 ? '#000000' : '#ffffff') : '#0c2337';
    if (index !== null) {
      const gradientIndex = index % GRADIENTS.length;
      style.backgroundImage = `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
      style.cursor = !!onClick || !!onMouseOver ? 'pointer' : 'normal';
    }

    return (
      <div
        ref={ref}
        className="segment-tag"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={style}
      >
        <div className={`segment-tag-wrapper${isActive ? ' active' : ''}`}>
          <span className="segment-name">{title}</span>
          {typeof score === 'number' && !hideScore && <span className="score">{score}</span>}
        </div>
      </div>
    );
  }
);
