import { makeStyles } from '@material-ui/core';
import { ModalProps } from 'reactstrap/es/Modal';
import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  height: 100vh;
  background: #fefaf2;
  overflow: hidden;
`;

export const LeftPanel = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  position: relative;
  padding: 80px 0 100px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const LeftCheckinPanel = styled(LeftPanel)`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 100px 0;
  background: #0f2136;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const RightPanel = styled.div`
  position: relative;
  width: 50%;
  background: #1d263b;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    display: none;
  }
`;

interface AccountButtonProps {
  selected: boolean;
}

export const AccountButton = styled.div<AccountButtonProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#1d263b' : 'white')};
  padding: 13px 39px;
  border-radius: 40px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  white-space: normal;
  width: 100%;
  transition: background 0.3s, color 0.3s;

  span {
    color: ${(props) => (props.selected ? 'white' : '#1d263b')};
  }

  &:hover {
    background: ${(props) => (props.selected ? '#1d263b' : '#f0f0f0')};
  }
`;

export const OptionButton = styled(AccountButton)<AccountButtonProps>`
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 12px 24px 12px 34px;
  color: ${(props) => (props.selected ? 'white' : '#1d263b')};
  white-space: break-spaces;
`;

export const ContentBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  align-self: center;
  width: 78%;
  max-width: 600px;
  gap: 16px;
  height: fit-content;
  max-height: 100%;
  padding-top: 124px;

  @media (max-width: 767px) {
    align-items: center;
  }
`;

export const ContentModalBox = styled(ContentBox)`
  padding-top: 0;
`;

export const AuthContentBox = styled.div`
  padding-top: 124px;
  max-width: 600px;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

export const StyledModalOB = styled<React.ComponentType<ModalProps>>(Modal)`
  & .modal-content {
    border: none;
    padding: 40px 50px;
    background-color: #fefaf2;
    width: auto;
    min-width: 644px;
    margin: 50px 0;
  }
`;

export const LogoBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 45px;
  img {
    width: 90px;
  }
`;

export const RedButtonBox = styled.div`
  position: absolute;
  bottom: -76px;
`;

export const RedBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 12px 22px;
  border-radius: 25px;
  color: #ffffff;
  background: linear-gradient(117deg, #fa8868 1%, #ff554d);
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.86px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const BlackButtonBox = styled(ContentBox)`
  position: absolute;
  top: 45px;
  height: unset;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: unset;
`;

export const SkipAllBtnBox = styled(BlackButtonBox)``;

export const BlackBtn = styled.button`
  padding: 0;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0c2337;
  cursor: pointer;
  gap: 5px;
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
`;

export const VioletBox = styled.div`
  background: #7e3af2;
  color: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 14px;
  left: 0;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
  gap: 14px;
  padding: 4px 15px 5px 5px;
`;

export const GoalsOptionsContainer = styled(OptionsContainer)`
  grid-template-columns: repeat(1, 100%);
`;

export const CustomOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  padding-right: 15px;
  width: 100%;
`;

export const CheckContainer = styled.div`
  position: absolute;
  left: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  img {
    width: 24px;
    height: 24px;
    margin-top: 4px;
  }
  span {
    // color: white;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const BtnsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
`;

export const EmptyButton = styled.div`
  width: 80px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: auto;
`;

export const toolTipStyles = makeStyles({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  arrow: {
    color: '#fff',
  },
});
