import React, { useState, useEffect, useCallback } from 'react';
import { Text } from '../Shared/Typography/Typography';
import './Settings.scss';
import { toolTipStyles } from '../Auth/Onboarding/_styled';
import alertIcon from '../../Assets/images/onboarding/alert-circle.svg';
import { CircularProgress, Switch } from '@mui/material';
import { Tooltip, Typography } from '@material-ui/core';
import services from '../../API/services';

const DataAccess = () => {
  const [consentValue, setConsentValue] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleConsentChange = async () => {
    setConsentValue((prev) => {
      const newConsentValue = !prev;
      services.updateConsent(newConsentValue);
      return newConsentValue;
    });
  };

  const fetchConsentStatus = useCallback(async () => {
    try {
      const response = await services.getSleepConsent(true);
      setConsentValue(response?.data?.isConsentGiven ?? false);
    } catch (error) {
      console.error('Error fetching consent status:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConsentStatus();
  }, [fetchConsentStatus]);

  const classes = toolTipStyles();
  const toolTipText = (
    <Typography style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
      ☝️ The Centre for Sleep Science at The University of Western Australia is conducting research on sleep patterns.
      <b>Your sleep diary data will be used exclusively for research purposes.</b>
      <div style={{ marginTop: '5px' }}>iyarn will not use your data without UWA’s permission.</div>
    </Typography>
  );

  return (
    <div className="settings-tab" style={{ gap: '16px' }}>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <Text size="18px" weight={500}>
            Research Consent for Sleep Journal Data
          </Text>
          <Tooltip arrow title={toolTipText} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
            <img src={alertIcon} style={{ marginLeft: '5px', width: '14px', height: '14px' }} alt="" />
          </Tooltip>
        </div>
      </div>

      <div className="info-card">
        <div className="info-item">
          <div className="d-flex flex-column">
            <Text size="14px">Access</Text>
            <Text size="16px" weight={700}>
              {!consentValue
                ? 'Not allow to share my sleep diary data for research conducted by UWA'
                : 'Allow to share my sleep diary data for research conducted by UWA'}
            </Text>
          </div>
          <div className="d-flex align-items-center" style={{ gap: '10px' }}>
            {loading ? (
              <CircularProgress style={{ marginRight: '15px' }} size={20} />
            ) : (
              <Switch checked={consentValue} onChange={handleConsentChange} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataAccess;
