import React from 'react';
import { FormControlLabel, makeStyles, RadioGroup, Typography, withStyles } from '@material-ui/core';
import MuiRadio from '@material-ui/core/Radio';
import { WheelAccess } from '../../../../API/interfaces';
import Section from '../common/Section';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';

interface iWheelTypeStep {
  access: WheelAccess;
  isTemplate: boolean;
  onChange: (filedName: string, value: any) => void;
}

const WheelTypeStep = ({ access, onChange, isTemplate }: iWheelTypeStep) => {
  const classes = useStyles();

  return (
    <>
      <Section number={4} title={`What kind of ${isTemplate ? 'template' : 'wheel'} do you want to create?`}>
        <RadioGroup
          classes={{ root: classes.radioGroup }}
          name="access"
          value={access}
          onChange={(e) => onChange('access', e.target.value)}
        >
          <FormControlLabel value={WHEEL_ACCESS_TYPES.PRIVATE} control={<Radio />} label="Private" />
          <Typography className={classes.accessText} style={{ marginBottom: 10 }}>
            Private allows you to invite peers to join, you will be able to see the results of everyone who joins.
          </Typography>
          <FormControlLabel value={WHEEL_ACCESS_TYPES.PUBLIC} control={<Radio />} label="Public" />
          <Typography className={classes.accessText} style={{ marginBottom: 10 }}>
            Public is for presentations or workshops, this is an anonymous pulse check.
          </Typography>
          <FormControlLabel value={WHEEL_ACCESS_TYPES.WEARABLE} control={<Radio />} label="WearIT" />
          <Typography className={classes.accessText}>
            WearIT wheels integrates your members’ wearable data with your Private check-ins. When you invite members to
            join this wheel, their health metrics (like heart rate, activity levels, etc.) will be shared with the wheel
            Admin, providing deeper insights.
            <br />
            <b>☝️ Please note that this view is only available through our mobile application.</b>
          </Typography>
        </RadioGroup>
      </Section>
    </>
  );
};

export default WheelTypeStep;

const Radio = withStyles({
  root: {
    color: '#0c2337',
    '&checked': {
      color: '#0c2337',
    },
  },
})((props) => <MuiRadio color="default" {...props} />);

const useStyles = makeStyles({
  accessText: {
    marginLeft: '42px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
  },
  radioGroup: {
    marginTop: '24px',
    marginBottom: '32px',
  },
});
