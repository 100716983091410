import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Container } from 'reactstrap';
import { TagMobile, Text1Mobile, TextMobile } from '../_styled';
import { SearchMobile } from '../Search/SearchMobile';
import { SortMobile } from '../Sort';
import { CardMobile } from '../Card';
import { WHEEL_FILTER_TAGS } from '../_consts';
import { eWheelFilterTags } from '../_types';
import { formatTag, useInfiniteScroll } from '../_utils';
import NoWheelsCreated from '../NoWheelsCreated';
import NothingFound from '../NothingFound';
import { iProps } from './_types';
import { List, Cards } from './_styled';
import nUser from '../../../_utils/user';
import { Button } from '../../Shared/Buttons/Button';
import { Spinner, SpinnerBackground } from '../../Shared/Spinner/Spinner';
import { useDebouncedCallback } from 'use-debounce';
import TasksDueToday from '../TasksDueToday/TasksDueToday';

export const DashboardMobile = (props: iProps) => {
  const {
    query,
    setQuery,
    setSort,
    filter,
    onFilterTagClick,
    wheels,
    searchResults,
    isSearching,
    user,
    showWheelDuplicateModal,
    showNoWheelsHaveBeenCreated,
    history,
    onCreateWheel,
    folders,
    onMoveWheelToFolder,
    setWheelToDelete,
    setWheelToInvite,
    setWheelToLeave,
    onLoadMore,
    loading,
    totalWheels,
  } = props;

  const cardsRef = useInfiniteScroll(onLoadMore);
  const [typing, setTyping] = useState(false);
  const [currentWheels, setCurrentWheels] = useState(wheels);
  const [spinnerActive, setSpinnerActive] = useState(false);

  const handleSearchChange = (value: string) => {
    setQuery(value);
    setTyping(true);
    setSpinnerActive(true);
    debounceStopTyping();
  };

  const debounceStopTyping = useDebouncedCallback(() => {
    setTyping(false);
  }, 1000);

  useEffect(() => {
    if (!typing && !isSearching) {
      setCurrentWheels(query ? searchResults : wheels);
      setSpinnerActive(false);
    }
  }, [searchResults, wheels, typing, isSearching, query]);

  const displayedWheels = query ? currentWheels : wheels;

  const renderLoader = () => (
    <LoaderWrapper>
      <SpinnerBackground height="60px">
        <Spinner />
      </SpinnerBackground>
    </LoaderWrapper>
  );

  return (
    <ContainerMobile withSidebar={nUser.hasAccessToFolders(user)}>
      <TasksDueToday />
      <div className="col-12 mb-2 d-flex justify-content-between align-items-center" style={{ marginTop: '24px' }}>
        <TextMobile className="col-3 p-0">Dashboard</TextMobile>
        <Button fontSize="14px" className="col-7" onClick={onCreateWheel}>
          CREATE A NEW WHEEL
        </Button>
      </div>

      <div className="col-12 d-flex justify-content-start position-relative mb-2 flex-nowrap">
        <SearchMobile value={query} onChange={handleSearchChange} />

        <SortMobile setSort={setSort} />
      </div>

      <List className="col-12">
        {WHEEL_FILTER_TAGS.map((tag: eWheelFilterTags) => (
          <TagMobile
            selected={filter.access?.includes(tag) || filter.roles?.includes(tag)}
            key={tag}
            onClick={() => onFilterTagClick(tag)}
          >
            {formatTag(tag)}
          </TagMobile>
        ))}
      </List>

      {spinnerActive || isSearching ? (
        renderLoader()
      ) : !_.isEmpty(displayedWheels) ? (
        <>
          <Text1Mobile className="col-12">{`All Wheels (${displayedWheels.length}/${totalWheels})`}</Text1Mobile>
          <Cards ref={cardsRef} className="col-12">
            {wheels.map((wheel, index) => (
              <CardMobile
                key={wheel.id}
                id={wheel.id}
                wheel={wheel}
                user={user}
                history={history}
                removeWheel={() => setWheelToDelete(wheel)}
                duplicateWheel={() => showWheelDuplicateModal(wheel)}
                inviteToWheel={() => setWheelToInvite(wheel)}
                leaveWheel={() => setWheelToLeave(wheel)}
                folders={folders}
                onMoveWheelToFolder={(folder) => onMoveWheelToFolder(folder, wheel)}
              />
            ))}
          </Cards>
          {loading && renderLoader()}
        </>
      ) : null}

      {showNoWheelsHaveBeenCreated && <NoWheelsCreated />}
      {!showNoWheelsHaveBeenCreated && !displayedWheels.length && !isSearching && !typing && !spinnerActive && (
        <NothingFound />
      )}
    </ContainerMobile>
  );
};

const ContainerMobile = styled<any>(Container)`
  padding-top: ${({ withSidebar }) => (withSidebar ? 78 : 30)}px;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  min-height: calc(100vh - 57px - 74px);

  @media (max-width: 768px) {
    padding-top: ${({ withSidebar }) => (withSidebar ? 48 : 30)}px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;
