import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { iUser } from '../../API/interfaces';
import { ManageContainer } from '../_Containers/ManageContainer';
import './Settings.scss';
import { TabContainer, Tab } from '../Shared/TabContainer/TabContainer';
import Profile from './Profile';
import { RootState } from '../../Redux/Reducers';
import Account from './Account';
import Goal from './Goal';
import DataAccess from './DataAcess';
import services from '../../API/services';

interface iSettingsProps {
  user: iUser;
}

enum SettingsTabs {
  profile = 'Profile',
  account = 'Account Settings',
  goal = 'Goal Settings',
  dataAccess = 'Data Access Control',
}

const Settings = ({ user }: iSettingsProps) => {
  const [activeTab, setActiveTab]: [SettingsTabs, (value) => void] = useState(SettingsTabs.profile);
  const [sleepWheelsCount, setSleepWheelsCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchSleepWheels = async () => {
      try {
        const { data } = await services.getSleepWheelsCount();
        setSleepWheelsCount(data ?? 0);
      } catch (error) {
        console.error('Failed to fetch sleep wheels count:', error);
        setSleepWheelsCount(0);
      }
    };

    fetchSleepWheels();
  }, []);

  const onChangeTab = (index: number, selectedTabName: SettingsTabs) => setActiveTab(selectedTabName);

  return (
    <ManageContainer>
      <div className="settings">
        <div className="settings-container">
          <TabContainer onChangeTab={onChangeTab} defaultTabIndex={0}>
            <Tab title={SettingsTabs.profile}>
              <Profile />
            </Tab>
            <Tab title={SettingsTabs.account}>
              <Account />
            </Tab>
            <Tab title={SettingsTabs.goal}>
              <Goal />
            </Tab>
            {sleepWheelsCount > 0 && (
              <Tab title={SettingsTabs.dataAccess}>
                <DataAccess />
              </Tab>
            )}
          </TabContainer>
        </div>
      </div>
    </ManageContainer>
  );
};

export default connect((state: RootState) => ({ user: state.userRD.user }))(Settings);
