import React, { useState, useEffect, useMemo } from 'react';
import { TasksClient } from '../../../../../API/tasks.client';
import { Text } from '../../../../Shared/Typography/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import './TaskCreateEdit.scss';
import TaskPills from '../components/TaskPills';
import { TaskView } from '../TaskReminder';
import { iWheelMember } from '../../../../../API/interfaces';
import { Button } from '../../../../Shared/Buttons/Button';
import { BlackBtn } from '../../../../Auth/Onboarding/_styled';
import { Spinner, SpinnerBackground } from '../../../../Shared/Spinner/Spinner';
import { toasterService } from '../../../../Shared/Toaster/Toaster.service';
import useTaskForm from '../hooks/useTaskForm';
import { Checkbox } from '../../../../Shared/Inputs/Checkbox';
import { analytics } from '../../../../../analytics/analytics';

interface TaskCreateEditProps {
  task?: any;
  members: iWheelMember[];
  isAdmin: boolean;
  userId: string;
  saveTask: (taskData: any) => void;
  goToTodo: () => void;
  currentFilter: string;
  view: TaskView;
}

const defaultTaskOptions = ['Complete Check in', 'Check ECG'];

const TaskCreateEdit: React.FC<TaskCreateEditProps> = ({
  task,
  members,
  isAdmin,
  userId,
  saveTask,
  goToTodo,
  currentFilter,
  view,
}) => {
  const { formData, setFormData, loadingSave, setLoadingSave, isSaveDisabled, isCreateDisabled } = useTaskForm(task);
  const [loadingTask, setLoadingTask] = useState(false);

  const isCreateMode = !task;

  useEffect(() => {
    let isMounted = true;

    const fetchTask = async () => {
      if (view === TaskView.CREATE_EDIT && currentFilter !== 'all' && task?.taskId) {
        setLoadingTask(true);
        try {
          const response = await TasksClient.getTask(task.taskId);
          if (isMounted) {
            setFormData((prev) => ({
              ...prev,
              assignees: response.data.assignees || [],
            }));
          }
        } catch (error) {
          if (isMounted) {
            console.error('Error fetching task:', error);
          }
        } finally {
          if (isMounted) {
            setLoadingTask(false);
          }
        }
      }
    };
    fetchTask();
    return () => {
      isMounted = false;
    };
  }, [view, currentFilter, task?.taskId]);

  useEffect(() => {
    if (isCreateMode && formData.assignees.length === 0 && formData.assignedToAll === undefined) {
      setFormData((prev) => ({
        ...prev,
        assignedToAll: true,
        assignees: members.map((member) => ({
          userId: member.userId,
          completed: false,
        })),
      }));
    }
  }, [isCreateMode, members, formData.assignees, formData.assignedToAll, setFormData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!formData.assignees.length) {
      toasterService.addErrorToast(
        task
          ? 'Failed to update the task. Please assign members to the task before saving.'
          : 'Failed to create the task. Please assign members to the task before proceeding.'
      );
      return;
    }

    try {
      setLoadingSave(true);
      const formattedData = {
        ...formData,
        dueDateTime: new Date(formData.dueDateTime).toISOString(),
        assignees: formData.assignees.map((assignee: { userId: string }) => assignee.userId),
        notifyAdmins: formData.notifyAdmins,
      };

      await saveTask(formattedData);
      toasterService.addSuccessToast(
        task ? 'The task has been successfully updated!' : 'The task has been successfully created!'
      );
      
      if (isCreateMode) analytics.clickTaskReminderCreated();
      goToTodo();
    } catch (error) {
      console.error('Error saving task:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <div className="todo-list">
      <div className="header">
        <div className="d-flex flex-column">
          <Text size="18px" mSize="16px" weight="500">
            {task ? 'Edit Task' : 'Create new task'}
          </Text>
          <Text size="12px">{`You can ${task ? 'edit' : 'set'} a task for yourself or for your wheel members `}</Text>
        </div>
      </div>

      {loadingTask ? (
        <div className="task-loader">
          <SpinnerBackground height="60px">
            <Spinner />
          </SpinnerBackground>
        </div>
      ) : (
        <div className="task-form">
          <Autocomplete
            options={defaultTaskOptions}
            freeSolo
            value={formData.title}
            onChange={(event, newValue) => {
              setFormData({ ...formData, title: newValue || '' });
            }}
            onInputChange={(event, newInputValue) => {
              setFormData({ ...formData, title: newInputValue });
            }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : '')}
            style={{ width: 480, maxWidth: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Task Name"
                required
                placeholder="Enter Your Task or Choose from List"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <TextField
            name="description"
            label="Task Description (optional)"
            placeholder="Enter task description"
            variant="outlined"
            fullWidth
            multiline
            value={formData.description}
            onChange={handleInputChange}
          />
          <div className="d-flex flex-column">
            <Text size="12px" color="#2b2e31" style={{ margin: '6px 0 12px' }}>
              Add or edit your task settings below 👇
            </Text>
            <div className="pills">
              {[
                ['dueDateTime', 'recurrence'],
                ['deliveryMethods', 'assignees'],
              ].map((group, index) => (
                <div className="pills-mobile" key={index}>
                  {group.map((pillType) => (
                    <TaskPills
                      key={pillType}
                      type={pillType as any}
                      taskData={formData}
                      setTaskData={setFormData}
                      members={members}
                      userId={userId}
                      isAdmin={isAdmin}
                      view={view}
                      currentFilter={currentFilter}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="bottom-group">
            <div className="bottom-group-checkbox">
              <Checkbox
                id="wearable-checkbox"
                className="signup_checkbox"
                checked={formData.notifyAdmins}
                onChange={(e) => setFormData({ ...formData, notifyAdmins: e.target.checked })}
              >
                <Text size="14px">Remind me and admins to do this task as well</Text>
              </Checkbox>
            </div>
            <div className="bottom-group-buttons">
              <BlackBtn
                type="button"
                onClick={goToTodo}
                style={{
                  marginRight: '24px',
                }}
              >
                Don't save
              </BlackBtn>
              <Button
                style={{ height: '40px', lineHeight: '40px' }}
                type="button"
                onClick={handleSave}
                disabled={task ? isSaveDisabled : isCreateDisabled}
              >
                {(loadingSave ? (task ? 'Saving...' : 'Creating...') : task ? 'Save' : 'Create Task').toUpperCase()}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCreateEdit;
