import React, { useEffect, useState } from 'react';
import Steps from './Steps';
import AccountTypeSelection from './AccountTypeSelection';
import './Onboarding.scss';

const Onboarding: React.FC = () => {
  const [userType, setUserType] = useState<string | null>(null);
  const [step, setStep] = useState<number | null>(null);

  const handleSelect = (type) => {
    setUserType(type);
    setStep(1);
  };

  return (
    <div className="onboarding-container">
      {userType === null || step === null ? (
        <AccountTypeSelection onSelect={handleSelect} />
      ) : (
        <Steps userType={userType} setUserType={setUserType} setStep={setStep} />
      )}
    </div>
  );
};

export default Onboarding;
