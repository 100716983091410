import React, { useEffect } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import TextField from '@mui/material/TextField';
import { SurveyQuestion, SurveyQuestionType } from '../../../../API/interfaces';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BlackBtn, RedBtn } from '../../../Auth/Onboarding/_styled';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DurationInput, { parseDuration } from './DurationInput';

interface SurveyStepsProps {
  surveyId: string;
  wheelId: string;
  surveyQuestions: SurveyQuestion[];
  currentStep: number;
  responses: { questionId: string; response: string | number }[];
  setResponse: (questionId: string, response: string | number) => void;
  onExit: () => void;
  onBack: () => void;
  onNext: () => void;
  totalSegments: number;
}

export default ({
  surveyQuestions,
  currentStep,
  setResponse,
  onBack,
  onNext,
  totalSegments,
  responses,
}: SurveyStepsProps) => {
  const surveyIndex = currentStep - totalSegments;

  if (!surveyQuestions || surveyIndex < 0 || surveyIndex >= surveyQuestions.length) {
    return null;
  }

  const currentQuestion = surveyQuestions[surveyIndex];

  const validationSchema = yup.object({
    response:
      currentQuestion.type === SurveyQuestionType.NUMBER
        ? yup
            .number()
            .min(
              currentQuestion.min,
              `Please enter a number between ${currentQuestion.min} and ${currentQuestion.max}.`
            )
            .max(
              currentQuestion.max,
              `Please enter a number between ${currentQuestion.min} and ${currentQuestion.max}.`
            )
            .nullable()
        : yup
            .string()
            .matches(/^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i, 'Please enter a valid time in HH:MM AM/PM format.')
            .nullable(),
  });

  const durationValidationSchema = yup.object({
    hours: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? null : Number(value)))
      .typeError('Hours must be a number')
      .min(currentQuestion.min, `Must be at least ${currentQuestion.min} hours`)
      .max(currentQuestion.max / 60 - 1, `Cannot be more than ${currentQuestion.max / 60 - 1} hours`)
      .nullable(),
    minutes: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? null : Number(value)))
      .typeError('Minutes must be a number')
      .min(currentQuestion.min, `Must be at least ${currentQuestion.min} minutes`)
      .max(59, 'Cannot be more than 59 minutes')
      .nullable(),
  });

  const existingResponse = responses.find((r) => r.questionId === currentQuestion.id)?.response || '';

  const formik = useFormik({
    initialValues: {
      response: existingResponse || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setResponse(currentQuestion.id, values.response);
      onNext();
    },
  });

  const existingDurationResponse = responses.find((r) => r.questionId === currentQuestion.id)?.response || '';
  const parsedDuration = parseDuration(existingDurationResponse as string);

  const durationFormik = useFormik({
    initialValues: {
      hours: String(parsedDuration.hours),
      minutes: String(parsedDuration.minutes),
    },
    enableReinitialize: true,
    validationSchema: durationValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const formattedDuration = `${values.hours || '0'} hours ${values.minutes || '0'} minutes`;

      const updatedResponses = [...responses.filter((r) => r.questionId !== currentQuestion.id)];
      updatedResponses.push({ questionId: currentQuestion.id, response: formattedDuration });

      setResponse(currentQuestion.id, formattedDuration);
      onNext();
    },
  });

  const handleDurationChange = (newValue: { hours: string | number; minutes: string | number }) => {
    durationFormik.setFieldValue('hours', String(newValue.hours || ''));
    durationFormik.setFieldValue('minutes', String(newValue.minutes || ''));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('response', value);
    }
  };

  const handleInputFocus = () => {
    formik.setFieldTouched('response', true, false);
  };

  const handleTimeChange = (newValue) => {
    formik.setFieldValue('response', newValue ? newValue.format('hh:mm A') : '');
  };

  useEffect(() => {
    if (formik.resetForm) {
      formik.resetForm();
    }
    if (durationFormik.resetForm) {
      durationFormik.resetForm();
    }
  }, [currentStep]);

  const isNextDisabled =
    currentQuestion.type === SurveyQuestionType.DURATION
      ? !durationFormik.isValid || durationFormik.values.hours === '' || durationFormik.values.minutes === ''
      : !formik.isValid || formik.values.response === '';

  const handleSubmitClick = async () => {
    if (currentQuestion.type === SurveyQuestionType.DURATION) {
      const isValid = await durationFormik.validateForm();
      if (Object.keys(isValid).length === 0) {
        durationFormik.handleSubmit();
      }
    } else {
      const isValid = await formik.validateForm();
      if (Object.keys(isValid).length === 0) {
        formik.handleSubmit();
      }
    }
  };

  const renderInputField = () => {
    switch (currentQuestion.type) {
      case SurveyQuestionType.NUMBER:
        return (
          <TextField
            name="response"
            label={currentQuestion.label}
            type="text"
            variant="outlined"
            placeholder="Enter your response"
            value={formik.values.response}
            InputLabelProps={{ shrink: true }}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            onFocus={handleInputFocus}
            error={formik.touched.response && Boolean(formik.errors.response)}
            helperText={formik.touched.response ? formik.errors.response : ''}
            style={{ width: '210px' }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        );

      case SurveyQuestionType.DURATION:
        return (
          <DurationInput
            value={{
              hours: durationFormik.values.hours,
              minutes: durationFormik.values.minutes,
            }}
            onChange={handleDurationChange}
            formik={durationFormik}
          />
        );

      case SurveyQuestionType.TIME:
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              fieldHoursPlaceholder: () => 'hh',
              fieldMinutesPlaceholder: () => 'mm',
              fieldMeridiemPlaceholder: () => 'AM/PM',
            }}
          >
            <TimePicker
              label={currentQuestion.label}
              value={formik.values.response ? dayjs(formik.values.response, 'hh:mm A') : null}
              format="hh:mm A"
              onChange={handleTimeChange}
              slotProps={{
                textField: {
                  placeholder: 'hh:mm AM/PM',
                  InputLabelProps: { shrink: true },
                },
                actionBar: {
                  sx: {
                    '& .MuiButton-root': {
                      color: '#233748',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    },
                  },
                },
                popper: {
                  sx: {
                    '& .Mui-selected': {
                      background: 'linear-gradient(117deg,#fa8868 1%,#ff554d) !important',
                      color: '#fff !important',
                      borderRadius: '8px',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="step-question">{currentQuestion.question}</div>
      <div className="response-container">{renderInputField()}</div>

      <div className="buttons">
        <BlackBtn onClick={onBack}>
          <ArrowLeft size={13} /> BACK
        </BlackBtn>
        <RedBtn type="submit" onClick={handleSubmitClick} disabled={isNextDisabled} style={{ height: '40px' }}>
          NEXT <ArrowRight size={13} />
        </RedBtn>
      </div>
    </>
  );
};
