import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import { X } from 'react-feather';
import { Button } from '../../../Shared/Buttons/Button';
import { analytics } from '../../../../analytics/analytics';
import services from '../../../../API/services';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';
import {
  AccountPlan,
  iUser,
  iWheel,
  iWheelMember,
  iWheelPermissions,
  WheelMemberRole,
} from '../../../../API/interfaces';
import { Tab, TabContainer } from '../../../Shared/TabContainer/TabContainer';
import { iDateRangeOptionData } from '../DateRangeDropdown/DateRangeDropdown';
import ReportDetails from './ReportDetails';
import { DATE_RANGE_FORMAT } from '../../../../constants';
import utils from '../../../Shared/utils';
// for tab styles TODO refactor tab component and remove this
import './GenerateReportModal.scss';
import styled from 'styled-components';
import { RadioButton } from '../../../Shared/RadioButton';
import { reportTypeTooltipText, reportTypeValues } from './utils';
import { ReportAccessType } from './utils';

enum ModalTabs {
  pdf = 'PDF Report',
  csv = 'CSV Report',
}

const REPORT_DESCRIPTIONS = {
  [ModalTabs.pdf]: 'This report is a summary that is useful for discussion',
  [ModalTabs.csv]: 'This is an export of the data in .csv format',
};

interface iGenerateReportModalProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  isTeamReport?: boolean;
  member?: iWheelMember | null;
  dateRange: iDateRangeOptionData;
  numberOfMembers: number;
  activeSegmentId: string;
  activeSegmentName: string;
  activeCheckinDate: string;
  user: iUser;
  toggle: () => void;
  setIsLoading: (isLoading: boolean) => void;
  classes: any;
  showOnlyCommentsWithText: boolean;
  isMemberResults?: boolean;
  isMyResults?: boolean;
  downloadSleepCSV?: () => void;
}

interface iGenerateReportModalState {
  activeTab: ModalTabs;
  reportAccessType: ReportAccessType;
}

class GenerateReportModal extends Component<iGenerateReportModalProps, iGenerateReportModalState> {
  isPdfAvailable: boolean;

  constructor(props) {
    super(props);

    this.isPdfAvailable = props.wheelPermissions?.wheel_pdfReport;

    this.state = {
      activeTab: this.isPdfAvailable ? ModalTabs.pdf : ModalTabs.csv,
      reportAccessType: 'identified',
    };
  }

  downLoadReport = async () => {
    const { activeTab, reportAccessType } = this.state;
    const {
      setIsLoading,
      toggle,
      wheel,
      member,
      dateRange,
      isTeamReport,
      user,
      activeSegmentId,
      activeCheckinDate,
      showOnlyCommentsWithText,
      isMemberResults,
      isMyResults,
    } = this.props;

    setIsLoading(true);
    toggle();

    try {
      switch (activeTab) {
        case ModalTabs.pdf:
          const pdfBlob = await services.getPdfReport({
            wheelId: wheel.id,
            userId: isTeamReport ? null : member ? member.userId : user._id,
            unit: dateRange.unit,
            from: dateRange.from?.toISODate(),
            to: dateRange.to?.toISODate(),
            segmentId: activeSegmentId || undefined,
            checkinDate: activeCheckinDate || undefined,
            onlyCommentsWithText: showOnlyCommentsWithText || undefined,
            reportAccessType,
          });

          this.downLoadFile(pdfBlob, 'pdf');

          const wheelPageTab = isTeamReport ? 'teamResults' : member ? 'memberResults' : 'myResults';
          const isAdmin =
            wheelPageTab !== 'myResults' ||
            wheel.members.find((m) => m.userId === user._id)?.memberRole === WheelMemberRole.ADMIN;
          analytics.generatePdfReport(wheelPageTab, isAdmin);
          break;
        case ModalTabs.csv:
          const csvBlob = await services.exportData(wheel.id, {
            reportAccessType,
            ...(isMemberResults ? { memberId: member?.userId } : isMyResults ? { memberId: user._id } : {}),
          });

          this.downLoadFile(csvBlob, 'csv');

          analytics.downloadXLS();
          break;
        default:
          break;
      }
    } catch (e: any) {
      toasterService.addErrorToast(e.message);
    }

    setIsLoading(false);
  };

  downLoadFile = (blob: Blob, extension: 'pdf' | 'csv') => {
    const { wheel } = this.props;

    const anchorElement = document.createElement('a');
    anchorElement.style.display = 'none';
    document.body.appendChild(anchorElement);

    const fr = new FileReader();
    if (fr) {
      fr.onload = (ev) => {
        // @ts-ignore
        anchorElement.href = ev.target.result;
        anchorElement.setAttribute('download', `${wheel.name}-data.${extension}`);
        anchorElement.click();
      };
      fr.readAsDataURL(blob);
    }
  };

  onChangeTab = (index: number, selectedTabName: string) => this.setState({ activeTab: selectedTabName as ModalTabs });

  getDateRangeString = (tab: ModalTabs) => {
    let dateRangeString: string;

    switch (tab) {
      case ModalTabs.pdf:
        const { dateRange } = this.props;
        dateRangeString = dateRange.label;

        if (dateRange.from) {
          dateRangeString += `, ${dateRange.from.toFormat(DATE_RANGE_FORMAT)} - ${dateRange.to.toFormat(
            DATE_RANGE_FORMAT
          )}`;
        }
        break;
      case ModalTabs.csv:
        dateRangeString = 'All time';
        break;
      default:
        break;
    }

    return dateRangeString;
  };

  getContent = (reportType: string) => {
    const {
      isTeamReport,
      classes,
      numberOfMembers,
      activeSegmentName,
      activeCheckinDate,
      isMemberResults,
      isMyResults,
    } = this.props;

    if (isTeamReport || isMemberResults || isMyResults) {
      return (
        <TabContainer onChangeTab={this.onChangeTab} defaultTabIndex={this.isPdfAvailable ? 0 : 1}>
          <Tab title={ModalTabs.pdf} className={classes.tab} lockFeature={!this.isPdfAvailable}>
            <ReportDetails
              description={REPORT_DESCRIPTIONS[ModalTabs.pdf]}
              reportType={reportType}
              dateRange={this.getDateRangeString(ModalTabs.pdf)}
              activeSegmentName={activeSegmentName}
              activeCheckinDate={activeCheckinDate}
            />
          </Tab>
          <Tab title={ModalTabs.csv} show={isTeamReport || isMemberResults || isMyResults} className={classes.tab}>
            <ReportDetails
              description={REPORT_DESCRIPTIONS[ModalTabs.csv]}
              reportType={reportType}
              dateRange={this.getDateRangeString(ModalTabs.csv)}
              numberOfMembers={numberOfMembers}
            />
          </Tab>
        </TabContainer>
      );
    }

    return (
      <ReportDetails
        description={REPORT_DESCRIPTIONS[ModalTabs.pdf]}
        reportType={reportType}
        dateRange={this.getDateRangeString(ModalTabs.pdf)}
        activeSegmentName={activeSegmentName}
        activeCheckinDate={activeCheckinDate}
      />
    );
  };

  render() {
    const {
      member,
      isTeamReport,
      toggle,
      classes,
      isMemberResults,
      isMyResults,
      user,
      wheel,
    } = this.props;
    const { activeTab, reportAccessType } = this.state;
    const showTabs = isTeamReport && this.isPdfAvailable;
    const reportType = isTeamReport ? 'Team results' : member ? `${member.firstName} ${member.lastName}` : 'My Results';

    return (
      <Dialog open={true} onClose={toggle} classes={{ paper: classes.dialog }} className="generate-report-modal">
        <X className={classes.closeBtn} size={18} color="#7b858e" onClick={toggle} />
        {isTeamReport || isMemberResults || isMyResults ? (
          <RadioControls>
            <RadioButton
              isBoldSelected
              textSize="16px"
              value={reportTypeValues.identified}
              selected={reportAccessType}
              text="Generate Identified Report"
              onChange={(e) => {
                this.setState({ reportAccessType: e });
              }}
              infoIconTooltipText={reportTypeTooltipText.identified}
            />
            <RadioButton
              isBoldSelected
              textSize="16px"
              value={reportTypeValues.anonymous}
              selected={reportAccessType}
              text="Generate Anonymous Report"
              onChange={(e) => {
                this.setState({ reportAccessType: e });
              }}
              infoIconTooltipText={reportTypeTooltipText.anonymous}
              disabled={!user?.account || user?.account?.plan === AccountPlan.FREE}
            />
          </RadioControls>
        ) : (
          <DialogTitle classes={{ root: classes.title }}>
            {showTabs ? 'Generate Report' : `Generate ${this.isPdfAvailable ? ModalTabs.pdf : ModalTabs.csv}`}
          </DialogTitle>
        )}
        <DialogContent classes={{ root: classes.content }}>{this.getContent(reportType)}</DialogContent>
        <DialogActions classes={{ root: classes.actions }} disableSpacing={utils.checkIsPhone()}>
          {activeTab === ModalTabs.pdf && (
            <MuiButton onClick={toggle} classes={{ root: classes.secondaryBtn }}>
              CHANGE REPORT DATA
            </MuiButton>
          )}
          <Button
            onClick={activeTab === ModalTabs.pdf ? this.downLoadReport : this.downLoadReport}
            className={classes.primaryBtn}
          >
            DOWNLOAD
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

const styles = {
  dialog: {
    padding: '32px 0px 40px',
    backgroundColor: '#fefaf2',
    width: '540px',
  },
  closeBtn: {
    position: 'absolute' as any,
    top: '30px',
    right: '30px',
    cursor: 'pointer',
  },
  title: {
    padding: '0 40px',
    fontSize: '30px',
  },
  content: {
    margin: '0',
    padding: '0',
  },
  tab: {
    backgroundColor: '#fefaf2',
    width: '50%',
    textAlign: 'center' as any,
  },
  actions: {
    padding: '0 40px',
    '@media(max-device-width: 767px)': {
      justifyContent: 'space-between',
      fontSize: '10px',
    },
  },
  primaryBtn: {
    height: '40px',
    lineHeight: '40px',
    fontSize: '10px',
    '@media(max-device-width: 767px)': {
      padding: '0 10px',
      fontSize: 'inherit',
    },
  },
  secondaryBtn: {
    fontWeight: 'bold' as any,
    '@media(max-device-width: 767px)': {
      padding: '0 10px',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
};

const RadioControls = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;

  @media (max-width: 768px) {
    padding-left: 20px;
    margin-top: 10px;
  }
`;

export default withStyles(styles)(connect(mapStateToProps)(GenerateReportModal));
