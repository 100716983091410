import React, { useState } from 'react';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import { Download } from 'react-feather';
import { analytics } from '../../../../analytics/analytics';
import { iWheel, iWheelMember, iWheelPermissions } from '../../../../API/interfaces';
import withFeatureLock from '../../../_HOCs/withFeatureLock';
import GenerateReportModal from './GenerateReportModal';
import { iDateRangeOptionData } from '../DateRangeDropdown/DateRangeDropdown';

interface iGenerateReportProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  isTeamReport?: boolean;
  member?: iWheelMember | null;
  dateRange: iDateRangeOptionData;
  numberOfMembers: number;
  activeSegmentId: string;
  activeSegmentName: string;
  activeCheckinDate: string;
  setIsLoading: (isLoading: boolean) => void;
  showOnlyCommentsWithText: boolean;
  isMemberResults?: boolean;
  isMyResults?: boolean;
  downloadSleepCSV?: () => void;
}

const GenerateReport = (props: iGenerateReportProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Button
        className="generate-report-btn"
        onClick={() => {
          analytics.clickGenerateReport();
          setShowModal(true);
        }}
        startIcon={<Download size={16} />}
      >
        Generate Report
      </Button>

      {showModal && <GenerateReportModal {...props} toggle={() => setShowModal(false)} />}
    </>
  );
};

export default withFeatureLock(GenerateReport, 'generate-report');

const Button = withStyles({
  root: {
    backgroundColor: 'rgb(15, 33, 54)',
    color: '#ffffff',
    textTransform: 'unset',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(15, 33, 54, .8)',
    },
  },
})(MuiButton);
