import React, { useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import './components.scss';
import { TasksClient } from '../../../../../API/tasks.client';
import { TaskView } from '../TaskReminder';

interface TaskCompletionToggleProps {
  taskId: string;
  dueDateTime: Date;
  completedAt?: Date;
  completed: boolean;
  isEditable: boolean;
  onStatusChange: (newStatus: boolean) => void;
  view?: TaskView;
  taskStatus?: string;
}

const TaskCompletionToggle: React.FC<TaskCompletionToggleProps> = ({
  taskId,
  dueDateTime,
  completed,
  isEditable,
  onStatusChange,
  view,
  taskStatus,
}) => {
  const [status, setStatus] = useState(taskStatus ?? (completed ? 'complete' : 'incomplete'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStatus(taskStatus ?? (completed ? 'complete' : 'incomplete'));
  }, [taskStatus, completed]);

  const isPastDue = new Date(dueDateTime) < new Date();
  const isHistoryView = view === TaskView.HISTORY;

  const handleComplete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await TasksClient.completeTask(taskId);
      setStatus('complete');
      onStatusChange(true);
    } catch (error) {
      console.error('Error completing task:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleIncomplete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await TasksClient.uncompleteTask(taskId);
      setStatus('incomplete');
      onStatusChange(false);
    } catch (error) {
      console.error('Error marking task incomplete:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderIcon = () => {
    if (status === 'complete') {
      return (
        <div
          className={`circle ${isHistoryView && 'circle-history'} ${loading ? 'default-loading' : 'complete'}`}
          onClick={isEditable && !loading ? handleIncomplete : undefined}
        >
          {!loading && <Check color="#fff" strokeWidth="2" size={16} />}
        </div>
      );
    }
    if (status === 'incomplete' && isPastDue) {
      return (
        <div className={`circle ${isHistoryView && 'circle-history'} incomplete`}>
          <X color="#fff" strokeWidth="2" size={16} />
        </div>
      );
    }
    return (
      <div
        className={`circle default ${loading ? 'default-loading' : ''}`}
        onClick={isEditable && !loading ? handleComplete : undefined}
      ></div>
    );
  };

  return <div className="task-completion-toggle">{renderIcon()}</div>;
};

export default TaskCompletionToggle;
