// helpers.ts
import { questionnaireData } from './constants';

interface UserData {
  userRole?: string[];
  customOptions?: { [key: number]: string[] };
  [key: string]: any;
}

export const skipIds = {
  'Secondary School Student': ['edu_focus', 'edu_resources', 'edu_goals'],
  'Primary School Student': ['edu_focus', 'edu_resources', 'edu_goals'],
  'University Student': ['edu_focus', 'edu_resources', 'edu_goals'],
};

export const getNextStep = (currentStep: number, userData: UserData, userType: string, totalSteps: number): number => {
  let nextStep = currentStep + 1;
  const userRole = userData.userRole ? userData.userRole[0] : null;
  const allQuestions = questionnaireData[userType];

  if (userRole && skipIds[userRole]) {
    while (nextStep < totalSteps && skipIds[userRole].includes(allQuestions[nextStep]?.id)) {
      nextStep += 1;
    }
  }

  return nextStep < totalSteps ? nextStep : totalSteps - 1;
};

export const getPreviousStep = (currentStep: number, userData: UserData, userType: string): number => {
  let previousStep = currentStep - 1;
  const userRole = userData.userRole ? userData.userRole[0] : null;
  const allQuestions = questionnaireData[userType];
  if (userRole && skipIds[userRole]) {
    while (previousStep >= 0 && skipIds[userRole].includes(allQuestions[previousStep]?.id)) {
      previousStep -= 1;
    }
  }
  return previousStep;
};

export const updateUserData = (data: Partial<UserData>, prevData: UserData) => {
  return { ...prevData, ...data };
};

export const accountTypes = [
  { title: 'Personal', subtitle: 'Use for self-awareness and growth' },
  { title: 'Workplace', subtitle: 'Use for staff engagement and development' },
  { title: 'Education', subtitle: 'Supporting student or teacher wellbeing' },
  { title: 'Healthcare Professional', subtitle: 'For clinicians, GPs, Psychologists...' },
  { title: 'Research', subtitle: 'For researchers, academics...' },
  { title: 'Other', subtitle: 'Facilitation, Measurement & Evaluation, or other...' },
];