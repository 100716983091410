import React from 'react';
import moment from 'moment';
import { FiLogOut } from 'react-icons/fi';
import { MenuItem, Menu } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { BsThreeDotsVertical } from 'react-icons/bs';
// TODO replace
import NestedMenuItem from 'material-ui-nested-menu-item';
import { Folder as FolderIcon } from 'react-feather';
import styled from 'styled-components';

import { analytics } from '../../../analytics/analytics';

import { AccessLabel, OwnershipLabel } from './LabelSection/LabelSection';
import '../Dashboard.scss';
import './Card.scss';
import { getLatestScoresCreateDateOr } from '../_utils';
import { WheelChart } from '../../../Templates/WheelChart';
import {
  ChartWrapper,
  Labels,
  Text2,
  StyledCopyIcon,
  StyledGearIcon,
  Option,
  WrapperMobile,
  OptionsMobile,
  AdditionalInfoMobile,
  Text1Mobile,
  TextMobile,
  StyledTrashIcon,
  StyledInvitePlusIcon,
} from './_styled';
import EmptyWheel from './EmptyWheel';

import { iProps } from './_types';
import { Guard } from '../../../_utils/Guard';
import { AccountPlan, WheelMemberRole } from '../../../API/interfaces';

import of from '../../../_utils/of';
import FolderMenuItem, { tProps as tFolderMenuItemProps } from './FolderMenuItem';
import ABWheelLogo from '../../../Assets/images/ab-role-dashboard-card-logo.png';
import { SleepLabel } from '../../WheelPage/Header/WheelLabel/WheelLabel';

const ABLogo = styled.img`
  width: 88px;
`;

export const CardMobile = (props: iProps) => {
  const {
    history,
    wheel,
    user,
    removeWheel,
    id,
    duplicateWheel,
    folders,
    onMoveWheelToFolder,
    leaveWheel,
    inviteToWheel,
  } = props;
  const numberOfAdmins = wheel.members.filter((m) => m.memberRole === WheelMemberRole.ADMIN).length;

  const goToDetails = React.useCallback(() => {
    history.push(`/wheel/${wheel.id}/member/${user._id}`);
  }, []);

  const goToSettings = React.useCallback(() => {
    analytics.wheelSettings();
    handleClose();
    history.push(`/wheel/${wheel.id}/settings`);
  }, []);

  const onDuplicateWheel = React.useCallback(() => {
    handleClose();
    duplicateWheel();
  }, []);

  const onLeaveWheel = React.useCallback(() => {
    handleClose();
    leaveWheel();
  }, []);

  const optionsRef = React.useRef(null);
  const onOptionsClick = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const lastCheckin = React.useMemo(() => getLatestScoresCreateDateOr(null, wheel.latestScores), [wheel]);
  // @ts-ignore
  const scores = React.useMemo(() => wheel.latestScores.filter((seg) => seg.score > 0), [wheel]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const foldersProps = React.useMemo<tFolderMenuItemProps[]>(
    () =>
      folders.map((folder) => ({
        ...folder,
        optionsOpened: Boolean(anchorEl),
        containsWheel: folder.wheels.includes(wheel.id),
        onClick: () => onMoveWheelToFolder(folder),
      })),
    [folders, wheel.id]
  );

  const onDelete = React.useCallback(() => {
    removeWheel();
    handleClose();
  }, []);

  const onInviteToWheel = React.useCallback(() => {
    inviteToWheel();
    handleClose();
  }, []);

  const wheelMember = wheel.members.find((member) => member.userId === user._id);
  const isAccountabilityBuddy = wheelMember.memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const wheelMembersLengthForRole = isAccountabilityBuddy
    ? wheelMember.accountabilityBuddyFor.length
    : wheel.members.length;
  const isPersonal = wheel?.isPersonal;

  return (
    <>
      <WrapperMobile onClick={goToDetails} isPersonal={isPersonal}>
        <TextMobile>{wheel.name}</TextMobile>
        <StyledText2 className="text-left">{wheel.description}</StyledText2>
        <Labels>
          <AccessLabel wheel={wheel} />
          {!!wheel.checkinSurveyId && <SleepLabel />}
          <OwnershipLabel wheelRole={wheelMember.memberRole} />
        </Labels>
        <div style={{ display: 'flex' }}>
          <ChartWrapper>
            {isAccountabilityBuddy ? (
              <ABLogo src={ABWheelLogo} />
            ) : (
              <>
                {wheel.latestScores.length ? (
                  <WheelChart
                    wheel={wheel}
                    width={88}
                    showScore
                    scoreOptions={{
                      radius: 14,
                      background: '#fff',
                      color: '#2a3e50',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      fontFamily: 'Rubik',
                    }}
                    scores={scores}
                  />
                ) : (
                  <EmptyWheel />
                )}
              </>
            )}
          </ChartWrapper>
          <AdditionalInfoMobile>
            <div>
              {(wheel.isAdmin || isAccountabilityBuddy) && (
                <Text1Mobile>{`${wheelMembersLengthForRole} members`}</Text1Mobile>
              )}
              <Text1Mobile>{`${wheel.checkinsCount} checkins`}</Text1Mobile>
            </div>
            {lastCheckin && <Text2>{`Last checkin ${moment(lastCheckin).fromNow()}`}</Text2>}
          </AdditionalInfoMobile>
        </div>

        <OptionsMobile ref={optionsRef} onClick={isPersonal ? null : onOptionsClick} isPersonal={isPersonal}>
          {!isPersonal && <BsThreeDotsVertical size="1.5em" />}
        </OptionsMobile>
      </WrapperMobile>

      {wheel.isAdmin && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ disablePadding: true, style: { minWidth: '200px' } }}
        >
          <MenuItem onClick={goToSettings} className="py-2" selected={false}>
            <StyledGearIcon />
            <Option>Settings</Option>
          </MenuItem>
          <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
            <NestedMenuItem
              className="py-2"
              parentMenuOpen={Boolean(anchorEl)}
              label={
                <>
                  <FolderIcon size="16px" style={{ marginRight: '8px' }} />
                  <Option>Move to folder</Option>
                </>
              }
            >
              {foldersProps.map(of(FolderMenuItem))}
            </NestedMenuItem>
          </Guard.Component>
          <MenuItem onClick={onDuplicateWheel} className="py-2">
            <StyledCopyIcon />
            <Option>Duplicate</Option>
          </MenuItem>
          {wheel.isAdmin && (
            <MenuItem onClick={onInviteToWheel} className="py-2">
              <StyledInvitePlusIcon />
              <Option>Invite</Option>
            </MenuItem>
          )}
          {numberOfAdmins === 1 ? (
            <MenuItem onClick={onDelete} className="py-2">
              <StyledTrashIcon className="mr-lg-2" />
              <Option color="#fb463b">Delete</Option>
            </MenuItem>
          ) : (
            <MenuItem onClick={onLeaveWheel} className="py-2">
              <FiLogOut color="#fb463b" className="mr-lg-2" />
              <Option color="#fb463b">Leave the wheel</Option>
            </MenuItem>
          )}
        </Menu>
      )}

      {wheel.isMember && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ disablePadding: true }}
        >
          <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
            <NestedMenuItem
              className="py-2"
              parentMenuOpen={Boolean(anchorEl)}
              label={
                <>
                  <FolderIcon size="16px" style={{ marginRight: '8px' }} />
                  <Option>Move to folder</Option>
                </>
              }
            >
              {foldersProps.map(of(FolderMenuItem))}
            </NestedMenuItem>
          </Guard.Component>
          <MenuItem onClick={onDuplicateWheel} className="py-2" selected={false}>
            <StyledCopyIcon />
            <Option>Duplicate</Option>
          </MenuItem>
          <MenuItem onClick={onLeaveWheel} className="py-2">
            <FiLogOut color="#fb463b" className="mr-lg-2" />
            <Option color="#fb463b">Leave the wheel</Option>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

const StyledText2 = styled(Text2)`
  max-width: 274px;
`;
