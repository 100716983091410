import React, { Component } from 'react';
import * as _ from 'lodash';
import services from '../../../API/services';
import { iUser } from '../../../API/interfaces';
import { authService } from '../../Auth/Auth.service';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import utils from '../../Shared/utils';
import Alert from '../Alert/Alert';
import { TextField } from '@material-ui/core';
import { BlackBtn, RedBtn } from '../../Auth/Onboarding/_styled';
import 'dayjs/locale/en-gb';

interface iSettingsFormProps {
  user: iUser;
  fields: string[];
}

interface iSettingsFormState {
  schema: any;
  userData: { firstName: string; lastName: string; email: string; personalData: any };
  initialUserData: { firstName: string; lastName: string; email: string; personalData: any };
  errors: { firstName?: string; lastName?: string; email?: string; age?: number };
  alertMsg: string | null;
  isUpdating: boolean;
}

class SettingsFormComponent extends Component<iSettingsFormProps, iSettingsFormState> {
  constructor(props: iSettingsFormProps) {
    super(props);
    const { user } = props;

    const initialUserData = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      personalData: {
        age: user.personalData?.age || null,
        ...user.personalData,
      },
    };

    this.state = {
      schema: utils.getFormValidationSchema('settings'),
      userData: initialUserData,
      initialUserData: initialUserData,
      errors: {},
      alertMsg: null,
      isUpdating: false,
    };
  }

  handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setState({ isUpdating: true });

    const { userData, initialUserData } = this.state;
    const { user } = this.props;

    const ageChanged = userData.personalData.age !== initialUserData.personalData.age;
    const userFieldsChanged =
      userData.firstName !== initialUserData.firstName ||
      userData.lastName !== initialUserData.lastName ||
      userData.email !== initialUserData.email;

    try {
      let updatedPersonalData = { ...user.personalData };

      if (ageChanged) {
        updatedPersonalData = {
          ...user.personalData,
          age: userData.personalData.age || null,
        };

        await services.updatePersonalData(updatedPersonalData);
        await authService.updatePersonalData(updatedPersonalData);
      }

      if (userFieldsChanged) {
        const { firstName, lastName, email } = userData;
        await services.updateUser({ firstName, lastName, email });

        authService.login({
          ...user,
          first_name: firstName,
          last_name: lastName,
          email,
          personalData: updatedPersonalData, 
        });
      }

      this.setState({
        alertMsg: 'User information has been updated.',
        initialUserData: { ...userData },
        isUpdating: false,
      });
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode, message } = error.response.data;

        if (statusCode === 400 && message === 'Email is already in use') {
          toasterService.addErrorToast(message);
        } else {
          toasterService.addErrorToast('Failed to update user data.');
        }
      } else {
        toasterService.addErrorToast('An unexpected error occurred.');
      }

      this.setState({ isUpdating: false });
    }
  };

  handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
    const { userData } = this.state;
    const value = fieldName === 'age' ? Number(event.target.value) : event.target.value;

    if (['firstName', 'lastName', 'email'].includes(fieldName)) {
      this.setState({
        userData: {
          ...userData,
          [fieldName]: value,
        },
      });
    } else {
      this.setState({
        userData: {
          ...userData,
          personalData: {
            ...userData.personalData,
            [fieldName]: value,
          },
        },
      });
    }
  };

  hideMessage = () => {
    this.setState({ alertMsg: null });
  };

  handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { initialUserData } = this.state;
    this.setState({
      userData: initialUserData,
      errors: {},
    });
  };

  generateInput = ([id, fieldName, label, placeholder]: string[]) => {
    const { userData, errors } = this.state;

    if (fieldName === 'age') {
      return (
        <div className="form-group age-input mb-px-2 w-100" key={id}>
          <TextField
            id="age"
            label={label}
            type="number"
            variant="outlined"
            placeholder={placeholder}
            value={userData.personalData.age ?? ''}
            onChange={(e) => this.handleValueChange(e, fieldName)}
            error={!!errors?.[fieldName]}
            helperText={errors?.[fieldName]}
            inputProps={{ min: 6, max: 99 }}
          />
        </div>
      );
    }

    return (
      <div className="form-group mb-px-2 w-100" key={id}>
        <TextField
          id={'input_' + id}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          value={userData[fieldName]}
          onChange={(e) => this.handleValueChange(e, fieldName)}
          error={!!errors?.[fieldName]}
          helperText={errors?.[fieldName]}
        />
      </div>
    );
  };

  generateInputs = () => {
    const { fields } = this.props;

    const allFields = [
      ['fname', 'firstName', 'First Name', 'Enter your First Name'],
      ['lname', 'lastName', 'Last Name', 'Enter your Last Name'],
      ['email', 'email', 'Email', 'Enter your Email'],
      ['age', 'age', 'Age', 'Enter your age'],
    ];

    return allFields
      .filter(([, fieldName]) => fields.includes(fieldName))
      .map((options) => this.generateInput(options));
  };

  componentDidUpdate(prevProps: iSettingsFormProps, prevState: iSettingsFormState) {
    const { schema, userData, errors } = this.state;

    if (!_.isEqual(prevState.userData, userData)) {
      try {
        const newErrors = utils.validateFields(schema, userData);
        if (!_.isEqual(errors, newErrors)) {
          this.setState({ errors: newErrors });
        }
      } catch (error) {
        console.error('Error validating fields:', error);
      }
    }
  }

  isUserDataUnchanged = () => {
    const { userData, initialUserData } = this.state;
    return (
      userData.firstName === initialUserData.firstName &&
      userData.lastName === initialUserData.lastName &&
      userData.email === initialUserData.email &&
      userData.personalData.age === initialUserData.personalData.age
    );
  };

  render() {
    const { alertMsg, errors, isUpdating } = this.state;
    const { fields } = this.props;

    const isUnchanged = this.isUserDataUnchanged();
    const isEmailRender = fields.includes('email');

    return (
      <div className="settings-form">
        <div style={{ width: '100%' }}>
          {alertMsg && <Alert alertType="success" text={alertMsg} handleClose={this.hideMessage} />}
        </div>

        <form onSubmit={this.handleSave}>
          <div>{this.generateInputs()}</div>
          <div className="d-flex justify-content-end" style={{ gap: '10px' }}>
            {!isUpdating && !isUnchanged && (
              <>
                <BlackBtn type="button" onClick={this.handleReset}>
                  Don't save
                </BlackBtn>
                <RedBtn type="submit" disabled={!_.isEmpty(errors)}>
                  {isEmailRender ? 'Update Email' : 'Save Changes'}
                </RedBtn>
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default SettingsFormComponent;
