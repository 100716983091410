import { useState } from 'react';
import {
  StyledMainButton,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledSecondaryButton,
} from '../FolderModals/_styled';
import CountableTextBox from '../../Shared/CountableTextBox/CountableTextBox';
import { isWheelAdmin } from '../../Shared/utils';
import { MAX_WHEEL_NAME_LENGTH } from '../../../constants';
import { BlackBtn, RedBtn } from '../../Auth/Onboarding/_styled';

export const DuplicateWheel = ({ wheel, cancel, confirm }) => {
  const defaultName = `${wheel?.name} (Copy)`;
  const [wheelName, setWheelName] = useState(defaultName?.length > MAX_WHEEL_NAME_LENGTH ? wheel?.name : defaultName);

  return (
    <>
      <StyledModalHeader toggle={cancel}>Duplicate wheel</StyledModalHeader>
      <StyledModalBody>
        <div>{`Please, enter a name for a new wheel. Members and scores will not be copied`}</div>
        <form onSubmit={() => confirm(wheelName)} className="mt-2">
          <CountableTextBox
            placeholder={`Enter a Duplicate name`}
            value={wheelName}
            max={MAX_WHEEL_NAME_LENGTH}
            onChange={(e) => setWheelName(e.target.value as string)}
          />
        </form>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledSecondaryButton onClick={cancel}>Cancel</StyledSecondaryButton>
        <StyledMainButton onClick={() => confirm(wheelName)}>Duplicate</StyledMainButton>
      </StyledModalFooter>
    </>
  );
};

export const DeleteWheel = ({ wheel, cancel, confirm }) => (
  <>
    <StyledModalHeader toggle={cancel}>Delete wheel</StyledModalHeader>
    <StyledModalBody>{`Are you sure you want to delete the wheel "${wheel?.name}"?`}</StyledModalBody>
    <StyledModalFooter>
      <BlackBtn onClick={cancel}>Cancel</BlackBtn>
      <RedBtn style={{ height: '40px', lineHeight: '40px', marginLeft: '20px' }} onClick={confirm}>
        Confirm
      </RedBtn>
    </StyledModalFooter>
  </>
);

export const LeaveWheel = ({ wheel, cancel, confirm, userId }) => {
  const isAdmin = isWheelAdmin(wheel, userId);
  return (
    <>
      <StyledModalHeader toggle={cancel}>Leave the wheel</StyledModalHeader>
      <StyledModalBody>{`Are you sure you want to delete yourself from the wheel? You will not see "${
        wheel?.name
      }" any longer.${isAdmin ? ' The data will be available for other admins' : ''}`}</StyledModalBody>
      <StyledModalFooter>
        <BlackBtn onClick={cancel}>Cancel</BlackBtn>
        <RedBtn style={{ height: '40px', lineHeight: '40px', marginLeft: '20px' }} onClick={confirm}>
          Confirm
        </RedBtn>
      </StyledModalFooter>
    </>
  );
};
