import React from 'react';
import { iScore } from '../../../API/interfaces';
import SegmentTag from './SegmentTag/SegmentTag';
import SleepButton from '../../WheelPage/common/SleepButton/SleepButton';

interface iSegmentTagsProps {
  scores: Array<iScore>;
  activeSegmentId: string;
  onMouseOver: (segmentId: string) => void;
  onMouseOut: () => void;
  hideScores?: boolean;
  showSurveyResponsesButton?: boolean;
}

export default ({
  scores,
  activeSegmentId,
  onMouseOut,
  onMouseOver,
  hideScores,
  showSurveyResponsesButton,
}: iSegmentTagsProps) => (
  <div className="segment-tags">
    {scores.map((score, index) => (
      <SegmentTag
        key={score.segmentId}
        title={score.segmentName}
        score={score.score}
        isActive={score.segmentId === activeSegmentId}
        onMouseOver={() => onMouseOver(score.segmentId)}
        onMouseOut={onMouseOut}
        index={index}
        hideScore={hideScores}
      />
    ))}
    {showSurveyResponsesButton && <SleepButton isCheckinStep />}
  </div>
);
