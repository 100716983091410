import { ArrowLeft, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  AccountButton,
  BlackBtn,
  BlackButtonBox,
  ContentBox,
  FieldWrapper,
  GoalsOptionsContainer,
  LeftPanel,
  RedBtn,
  RedButtonBox,
  Root,
} from './_styled';
import { RightScreen } from './RightScreen';
import { Text } from '../../Shared/Typography/Typography';
import { accountTypes } from './helpers';
import { RootState } from '../../../Redux/Reducers';
import { connect, useSelector } from 'react-redux';
import { analytics } from '../../../analytics/analytics';
import services from '../../../API/services';
import { updatePersonalDataAction } from '../Auth.actions';

const AccountTypeSelection = ({ user, onSelect, updatePersonalDataAction }) => {
  const [userType, setUserType] = useState<string | null>(null);

  console.log(user);

  useEffect(() => {
    if (user.personalData.userTag) {
      setUserType(user.personalData.userTag);
    }
  }, [user]);

  const handleNext = async () => {
    if (!userType) return;

    analytics.clickOnbUserTagAdded();

    const updatedData = {
      ...user.personalData,
      userTag: userType,
    };

    try {
      await services.updatePersonalData(updatedData);
      updatePersonalDataAction(updatedData);
    } catch (error) {
      console.error('Failed to update account type:', error);
    }

    onSelect(userType);
  };

  const history = useHistory();
  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));

  const handleBack = () => {
    if (!shareLinkParams) {
      history.push('/dashboard');
    } else {
      history.push(`/share${shareLinkParams}`);
    }
  };

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={handleBack}>
            <ArrowRight size={13} />
            {!shareLinkParams ? 'DASHBOARD' : 'JOIN WHEEL'}
          </BlackBtn>
        </BlackButtonBox>
        <ContentBox style={{ justifyContent: 'center' }}>
          <Text weight={600} size="36px" color="#2b2e31">
            What is your main use?
          </Text>
          <FieldWrapper>
            <GoalsOptionsContainer>
              {accountTypes.map((account) => (
                <AccountButton
                  key={account.title}
                  selected={userType === account.title}
                  onClick={() => setUserType(account.title)}
                >
                  <Text weight={500} size="18px" mSize="16" lineHeight="normal">
                    {account.title}
                  </Text>
                  <Text size="14px">{account.subtitle}</Text>
                </AccountButton>
              ))}
            </GoalsOptionsContainer>
          </FieldWrapper>
          {userType && (
            <RedButtonBox>
              <RedBtn onClick={handleNext}>
                NEXT <ArrowRight size={13} />
              </RedBtn>
            </RedButtonBox>
          )}
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

export default connect(mapStateToProps, { updatePersonalDataAction })(AccountTypeSelection);
