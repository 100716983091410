import React, { useState } from 'react';
import { WheelMemberRoleType, WheelRoleNaming } from '../../API/interfaces';
import { Spinner } from '../Shared/Spinner/Spinner';
import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import { BlackBtn, RedBtn } from '../Auth/Onboarding/_styled';
import { WHEEL_ACCESS_TYPES } from '../../constants';
import { Checkbox } from './../Shared/Inputs/Checkbox';
import { Switch } from '@mui/material';

interface iBrowserStepProps {
  wheelName: string;
  wheelAccess: string;
  invitingMemberName: string;
  onCancel: () => void;
  onSubmit: (consent?: boolean) => void;
  inviteRole: WheelMemberRoleType;
  hasSleepSurvey: boolean;
  isConsentGiven?: boolean;
  wheelCreatorId: string;
}

export default ({
  wheelName,
  wheelAccess,
  invitingMemberName,
  onCancel,
  onSubmit,
  inviteRole,
  hasSleepSurvey = false,
  isConsentGiven,
  wheelCreatorId,
}: iBrowserStepProps) => {
  const [isWearableConsentChecked, setWearableConsentChecked] = useState(false);
  const [sleepConsent, setSleepConsent] = useState(isConsentGiven === undefined ? true : isConsentGiven);
  const [step, setStep] = useState(1);

  // restrict consent screen only for research study wheels for a mvp
  // const researchSleepAccounts = ['66a6f749f3dbe3002e44f1fb', '643cb100aef80500183380cf'];
  // const isResearchSleepAccount = researchSleepAccounts.includes(wheelCreatorId);
  const isResearchSleepAccount = true;

  const handleWearableConsentChange = () => {
    setWearableConsentChecked(!isWearableConsentChecked);
  };

  const handleSleepConsentChange = () => setSleepConsent(!sleepConsent);

  const isJoinDisabled = wheelAccess === WHEEL_ACCESS_TYPES.WEARABLE && !isWearableConsentChecked;

  return wheelName ? (
    <Container>
      {hasSleepSurvey && isResearchSleepAccount && isConsentGiven === undefined && (
        <StepIndicator>
          Step {step}
          <span>/2</span>
        </StepIndicator>
      )}

      {step === 1 ? (
        <>
          <Text size="18px" weight={500} style={{ marginBottom: '16px' }}>
            Admin {invitingMemberName} has invited you to join their Wheel “{wheelName}” as{' '}
            {WheelRoleNaming[inviteRole]}.
          </Text>

          {wheelAccess === WHEEL_ACCESS_TYPES.WEARABLE && (
            <>
              <Text size="18px">
                ☝️ <b>Please note,</b> as a Member by joining,{' '}
                <b>
                  your wearable health data - such as heart rate, activity levels, and sleep patterns - will be shared
                  with the Admins of this wheel.
                </b>
                <br />
                This data will be used to provide deeper insights into and help track your progress.
              </Text>

              <ConsentContainer>
                <Checkbox
                  id="wearable-checkbox"
                  className="signup_checkbox"
                  checked={isWearableConsentChecked}
                  onChange={handleWearableConsentChange}
                >
                  <Text size="18px">I understand and consent to share my data with the admin of this wheel.</Text>
                </Checkbox>
              </ConsentContainer>
            </>
          )}

          <ButtonContainer>
            <RedBtn
              onClick={() => {
                if (isConsentGiven === undefined && isResearchSleepAccount && hasSleepSurvey) {
                  setStep(2);
                } else {
                  onSubmit();
                }
              }}
              disabled={isJoinDisabled}
            >
              {hasSleepSurvey && isResearchSleepAccount && isConsentGiven === undefined ? 'Next' : 'Join'}
            </RedBtn>
            <BlackBtn onClick={onCancel}>Decline Invitation</BlackBtn>
          </ButtonContainer>
        </>
      ) : (
        <>
          <Text size="18px" weight={500} style={{ marginBottom: '16px' }}>
            Consent to Participate in Sleep Research
          </Text>

          <Text size="18px">
            ☝️ The Centre for Sleep Science at The University of Western Australia is conducting research on sleep
            patterns.
            <br /> If you would like to read the full UWA consent form please read{' '}
            <b style={{ textDecoration: 'underline' }}>
              <a
                href="https://iyarn.com/blog/participant-consent-form-iyarn-sleep-jounrnal/"
                target="_blank"
                style={{ color: '#2b2e31' }}
              >
                here
              </a>
            </b>
          </Text>

          <ConsentContainer>
            <div className="d-flex align-items-center">
              <Text size="12px">{sleepConsent ? 'Yes' : 'No'}</Text>
              <Switch checked={sleepConsent} onChange={handleSleepConsentChange} />
            </div>
            <Text size="18px">
              {sleepConsent ? 'Allow' : 'Not allow'} to share my sleep diary data for research conducted by UWA.
            </Text>
          </ConsentContainer>

          <ButtonContainer>
            <RedBtn onClick={() => onSubmit(sleepConsent)}>Go Ahead</RedBtn>
            <BlackBtn onClick={() => setStep(1)}>Back</BlackBtn>
          </ButtonContainer>
        </>
      )}
    </Container>
  ) : (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;

const ConsentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 20px 0 36px;
  maxheight: 38px;
  height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 24px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100px;
`;

const StepIndicator = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2b2e31;
  margin-bottom: 16px;

  span {
    color: #86919a;
  }
`;
