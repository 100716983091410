import React from 'react';
import { Check, X } from 'react-feather';
import { Text } from '../../../../Shared/Typography/Typography';
import { TaskView } from '../TaskReminder';

interface AssigneeBadgeProps {
  userId: string;
  firstName: string;
  lastName: string;
  isCompleted: boolean;
  view: TaskView;
}

const AssigneeBadge: React.FC<AssigneeBadgeProps> = ({ userId, firstName = '', lastName = '', isCompleted, view }) => {
  const initials = `${firstName?.[0]}${lastName?.[0]}`;
  const userName = !firstName && !lastName ? 'Deleted member' : `${firstName} ${lastName}`;

  return (
    <div key={userId} className="assignee-item">
      <div className={`hover-circle${isCompleted ? '' : '-initials'}`}>
        {isCompleted ? (
          <Check color="#fff" size={9} />
        ) : view === TaskView.HISTORY ? (
          <X color="#fff" size={14} />
        ) : (
          initials
        )}
      </div>
      <Text size="12px">{userName}</Text>
    </div>
  );
};

export default AssigneeBadge;
