import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Breakpoint } from 'react-socks';
import services from '../../API/services';
import { AccountPlan, Folder, iFolder, iWheelExt, iWheelExtMapped } from '../../API/interfaces';
import { analytics } from '../../analytics/analytics';
import { Guard } from '../../_utils/Guard';
import utils from '../Shared/utils';
import { ManageContainer } from '../_Containers/ManageContainer';
import { educationService } from '../Education/Education.service';
import { EducationStatus } from '../Education/Education.model';
import { toExtendedWheel } from './_utils';
import { eSortOptions, eWheelFilterTags } from './_types';
import { SidebarMobile, SidebarDesktop } from './Sidebar';
import { DashboardDesktop, DashboardMobile } from './Body';
import { StringParam, useQueryParam } from 'use-query-params';
import { AxiosError, AxiosResponse } from 'axios';
import { FolderClient } from '../../API/folder.client';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { StyledModal } from './Sidebar/SidebarDesktop';
import { MembersClient } from '../../API/members.client';
import { loadWheelsSuccess } from './Dashboard.actions';
import InviteToWheelModal from '../WheelPage/WheelInviteModal/InviteToWheelModal';
import { DeleteWheel, DuplicateWheel, LeaveWheel } from './WheelActions/WheelActions';
import { useDebouncedCallback } from 'use-debounce';

interface iDashboardProps extends RouteComponentProps<any> {
  user: any;
  shareLinkId: string | null;
  isMobile: string;
  isSmallSC: string;
  firstVisitOfDashboard: EducationStatus;
  actions: {
    setEducationFlow: (config) => void;
    setFirstVisitOfDashboard: (string: EducationStatus) => void;
  };
}

export enum Domains {
  WHEELS = 'WHEELS',
  FOLDERS = 'FOLDERS',
}

const PAGE_SIZE = 10;

const Dashboard = (props: iDashboardProps) => {
  const [selectedFolderId, setSelectedFolderId] = useQueryParam('folder_id', StringParam);
  const user = useSelector((state: any) => (state.userRD.user ? state.userRD.user : null));
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const folderQuery = useQuery<AxiosResponse<iFolder[]>, AxiosError, Folder[]>(Domains.FOLDERS, FolderClient.get, {
    select: ({ data }) => (data ? data.map(Folder.of) : []),
  });

  const tryToRedirectToTemplates = React.useCallback(() => {
    const KEY = 'template_redirect';
    const shouldRedirect = !sessionStorage.getItem(KEY);

    if (shouldRedirect) {
      sessionStorage.setItem(KEY, '1');
      history.push('/templates');
    }
  }, []);

  const wheelQuery = useQuery<AxiosResponse<{ data: iWheelExt[]; meta: any }>, AxiosError, iWheelExtMapped[]>(
    Domains.WHEELS,
    async () => {
      utils.startLoading();
      const response = await services.getWheelsExtendedByUserId(
        1,
        PAGE_SIZE,
        isSearchMode ? query : undefined,
        filter,
        sort,
        selectedFolderId
      );
      setTotalItems(response.data.meta.totalItems);
      setTotalPages(response.data.meta.totalPages);
      return response;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: ({ data }) =>
        Array.isArray(data.data) ? data.data.map((wheel) => toExtendedWheel(user._id, wheel)) : [],
      onSuccess: (data) => {
        if (data?.length) {
          setItems(data);
          dispatch(loadWheelsSuccess({ wheels: data }));
          educationService.processDashboardEducation();
        } else {
          tryToRedirectToTemplates();
        }
        setHasInitialized(true);
        setLoading(false);
      },
      onSettled: () => utils.endLoading(),
    }
  );

  const wheels = React.useMemo(() => wheelQuery.data?.map((wheel) => toExtendedWheel(user._id, wheel)) || [], [
    wheelQuery.data,
    user._id,
  ]);

  const [hasInitialized, setHasInitialized] = useState(false);
  const [wheelToDuplicate, setWheelToDuplicate] = React.useState<iWheelExt | null>(null);
  const [wheelToLeave, setWheelToLeave] = React.useState<iWheelExt | null>(null);
  const [wheelToInvite, setWheelToInvite] = React.useState<iWheelExt | null>(null);
  const [wheelToDelete, setWheelToDelete] = React.useState<iWheelExt | null>(null);
  const [filter, setFilter] = React.useState({ access: [], roles: [] });
  const [query, setQuery] = React.useState('');
  const [sort, setSort] = React.useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [searchTotalItems, setSearchTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [searchTotalPages, setSearchTotalPages] = useState(null);
  const [items, setItems] = useState<iWheelExtMapped[]>([]);
  const [searchResults, setSearchResults] = useState<iWheelExtMapped[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);

  const folderAddWheel = useMutation(FolderClient.addWheel);
  const isSearchMode = !!query.trim();

  const showNoWheelsHaveBeenCreated = React.useMemo(() => {
    return _.isEmpty(wheels);
  }, [wheels]);

  const onFilterTagClick = React.useCallback(
    (tag: eWheelFilterTags) => {
      const accessTags = [eWheelFilterTags.PRIVATE, eWheelFilterTags.PUBLIC, eWheelFilterTags.WEARABLE];
      const roleTags = [
        eWheelFilterTags.I_AM_ADMIN,
        eWheelFilterTags.I_AM_MEMBER,
        eWheelFilterTags.I_AM_ACCOUNTABILITY_BUDDY,
      ];

      const updatedFilter = { ...filter };

      if (accessTags.includes(tag)) {
        if (updatedFilter.access.includes(tag)) {
          updatedFilter.access = updatedFilter.access.filter((t) => t !== tag);
        } else {
          updatedFilter.access.push(tag);
        }
      }

      if (roleTags.includes(tag)) {
        if (updatedFilter.roles.includes(tag)) {
          updatedFilter.roles = updatedFilter.roles.filter((t) => t !== tag);
        } else {
          updatedFilter.roles.push(tag);
        }
      }

      setFilter(updatedFilter);
    },
    [filter]
  );

  const updateWheelsList = async (userId: string) => {
    const currentFilter = _.cloneDeep(filter);

    try {
      const updatedData = await queryClient.fetchQuery({
        queryKey: [Domains.WHEELS, { filter: currentFilter, sort, page: 1, limit: PAGE_SIZE, selectedFolderId }],
        queryFn: () => services.getWheelsExtendedByUserId(1, PAGE_SIZE, undefined, currentFilter, sort, selectedFolderId),
      });

      setItems(updatedData.data.data.map((wheel) => toExtendedWheel(userId, wheel)));
      setTotalItems(updatedData.data.meta.totalItems);
      setTotalPages(updatedData.data.meta.totalPages);
    } catch (error) {
      toasterService.addErrorToast('Failed to update wheel list.');
    }
  };

  const onWheelRemoved = React.useCallback(
    async (deletedWheel: iWheelExt) => {
      const updatedWheels = wheels.filter((wheel) => wheel.id !== deletedWheel.id);
      setItems(updatedWheels);

      await updateWheelsList(user._id);
    },
    [wheels]
  );

  const onWheelDuplicate = React.useCallback(
    async (newName) => {
      if (wheelToDuplicate) {
        try {
          await services.duplicateWheel(wheelToDuplicate.id, newName);
          analytics.duplicate_wheel();

          await updateWheelsList(user._id);
        } finally {
          setWheelToDuplicate(null);
        }
      }
    },
    [wheelToDuplicate]
  );

  const onDeleteWheel = React.useCallback(async () => {
    if (wheelToDelete) {
      try {
        await services.deleteWheel(wheelToDelete.id);
        analytics.deleteWheel();

        await updateWheelsList(user._id);
      } finally {
        setWheelToDelete(null);
      }
    }
  }, [wheelToDelete]);

  const onLeaveWheel = React.useCallback(async () => {
    if (wheelToLeave) {
      try {
        await MembersClient.deleteWheelMember({
          wheelId: wheelToLeave.id,
          userId: user._id,
        });
        analytics.leaveWheel();

        await updateWheelsList(user._id);
      } finally {
        setWheelToLeave(null);
      }
    }
  }, [wheelToLeave]);

  const onCreateWheel = React.useCallback(() => {
    analytics.createWheelAttempt();

    history.push('/create-wheel');
  }, []);

  const moveWheelToFolder = React.useCallback((folder: Folder, wheel: iWheelExt) => {
    folderAddWheel.mutate(
      { folderId: folder.id, wheelId: wheel.id },
      {
        onSuccess: () => {
          toasterService.addSuccessToast(`Wheel ${wheel.name} has been successfully moved to ${folder.name}`);
          setSelectedFolderId(folder.id);
          queryClient.invalidateQueries(Domains.FOLDERS);
          analytics.moveToFolder();
        },
        onError: (error: any) => {
          const message = error?.response?.data?.message || 'Something went wrong';
          toasterService.addErrorToast(message);
        },
      }
    );
  }, []);

  const onSortChanged = useCallback((sortValue) => {
    if (sortValue && Object.keys(sortValue).length)
      analytics.dashboardSorting(Object.keys(sortValue)[0] as eSortOptions);
    setSort(sortValue);
  }, []);

  const fetchWheels = useCallback(
    async (page: number, isSearchMode: boolean, queryParam?: string, folderId?: string) => {
      if (!hasInitialized) return;
      try {
        setLoading(true);

        const response = await services.getWheelsExtendedByUserId(
          page,
          PAGE_SIZE,
          isSearchMode ? queryParam : undefined,
          filter,
          sort,
          folderId || selectedFolderId
        );

        const wheels = response.data.data.map((wheel) => toExtendedWheel(user._id, wheel));

        if (isSearchMode) {
          if (page === 1) {
            setSearchResults(wheels);
            setSearchPage(1);
            setSearchTotalPages(response.data.meta.totalPages);
            setSearchTotalItems(response.data.meta.totalItems);
          } else {
            setSearchResults((prevResults) => _.uniqBy([...prevResults, ...wheels], 'id'));
            setSearchPage(page);
          }
          setItems(wheels);
        } else {
          if (page === 1) {
            setItems(wheels);
            setTotalItems(response.data.meta.totalItems);
            setTotalPages(response.data.meta.totalPages);
          } else {
            setItems((prevItems) => _.uniqBy([...prevItems, ...wheels], 'id'));
            setPage(page);
          }
        }
      } catch (error) {
        console.error('Error fetching wheels:', error);
      } finally {
        setLoading(false);
        setIsSearching(false);
      }
    },
    [filter, sort, user._id, hasInitialized, selectedFolderId]
  );

  const applyFiltersAndQuery = useDebouncedCallback(() => {
    if (!hasInitialized) return;
    setIsSearching(true);
    setPage(1);
    if (isSearchMode) {
      fetchWheels(1, true, query.trim(), selectedFolderId);
    } else {
      fetchWheels(1, false, undefined, selectedFolderId);
    }
  }, 1000);

  const loadMoreItems = useCallback(
    useDebouncedCallback(() => {
      const currentPage = isSearchMode ? searchPage : page;
      const totalAvailablePages = isSearchMode ? searchTotalPages : totalPages;
      if (loading || currentPage >= totalAvailablePages) return;
      fetchWheels(currentPage + 1, isSearchMode, query.trim());
    }, 200),
    [page, searchPage, query, loading, fetchWheels, searchTotalPages, totalPages]
  );

  useEffect(() => {
    if (!hasInitialized && items.length === 0 && !query.trim()) {
      fetchWheels(1, false);
    }
  }, [fetchWheels, items.length, query, hasInitialized]);

  useEffect(() => {
    applyFiltersAndQuery();
  }, [filter, sort, query, applyFiltersAndQuery, selectedFolderId]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 150) {
      loadMoreItems();
    }
  }, [loadMoreItems]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const dashboardProps = {
    filter,
    onFilterTagClick,
    onWheelRemoved,
    setSort: onSortChanged,
    setQuery,
    sort,
    showWheelDuplicateModal: setWheelToDuplicate,
    showNoWheelsHaveBeenCreated,
    setWheelToDelete,
    setWheelToInvite,
    setWheelToLeave,
    user,
    query,
    wheels: items,
    searchResults,
    isSearching,
    history,
    onCreateWheel,
    folders: folderQuery.data,
    onMoveWheelToFolder: moveWheelToFolder,
    onLoadMore: loadMoreItems,
    loading,
    totalWheels: query.trim() ? searchTotalItems : totalItems,
  };

  return (
    <ManageContainer className="gray without-footer" hasFooter={false}>
      <Breakpoint small up style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
        <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
          <SidebarDesktop />
        </Guard.Component>

        <DashboardDesktop {...dashboardProps} />
      </Breakpoint>

      <Breakpoint small down>
        <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
          <SidebarMobile />
        </Guard.Component>

        <DashboardMobile {...dashboardProps} />
      </Breakpoint>

      <StyledModal isOpen={Boolean(wheelToDuplicate)} centered>
        <DuplicateWheel wheel={wheelToDuplicate} confirm={onWheelDuplicate} cancel={() => setWheelToDuplicate(null)} />
      </StyledModal>

      <StyledModal isOpen={Boolean(wheelToDelete)} centered>
        <DeleteWheel wheel={wheelToDelete} confirm={onDeleteWheel} cancel={() => setWheelToDelete(null)} />
      </StyledModal>

      <StyledModal isOpen={Boolean(wheelToLeave)} centered>
        <LeaveWheel
          wheel={wheelToLeave}
          confirm={onLeaveWheel}
          cancel={() => setWheelToLeave(null)}
          userId={user._id}
        />
      </StyledModal>

      <InviteToWheelModal wheel={wheelToInvite} onCancel={() => setWheelToInvite(null)} />
    </ManageContainer>
  );
};

export default Dashboard;
