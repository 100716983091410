import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DeliveryMethod, TaskRecurrence } from '../../../../../API/tasks.client';
import './components.scss';
import { Bell, Calendar, Repeat, User, Users } from 'react-feather';
import { TaskView } from '../TaskReminder';
import AssigneeBadge from './AssigneeBadge';
import { FormControlLabel, Switch } from '@mui/material';
import { Text } from '../../../../Shared/Typography/Typography';
import CustomDateTimePicker from './CustomDateTimePicker';
import utils from '../../../../Shared/utils';
import { createPortal } from 'react-dom';

interface TaskPillsProps {
  type: 'dueDateTime' | 'recurrence' | 'deliveryMethods' | 'assignees';
  taskData: any;
  setTaskData?: (updatedData: any) => void;
  members: Array<{ userId: string; firstName: string; lastName: string }>;
  userId: string;
  isAdmin: boolean;
  view: TaskView;
  currentFilter?: string;
}

const TaskPills: React.FC<TaskPillsProps> = ({
  type,
  taskData,
  setTaskData,
  members,
  userId,
  isAdmin,
  view,
  currentFilter,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isMemberPopupVisible, setIsMemberPopupVisible] = useState(false);
  const pillRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const isMobile = utils.checkIsPhone();
  const isTodoView = view === TaskView.TODO;
  const isHistoryView = view === TaskView.HISTORY;
  const isCreateEditView = view === TaskView.CREATE_EDIT;
  const showTodoPills = isTodoView && currentFilter === 'all';
  const readOnly = isTodoView || isHistoryView;

  const iconSize = isCreateEditView ? '16' : '12';

  const formatDueDateTime = (date: string) => {
    const today = dayjs().startOf('day');
    const targetDate = dayjs(date).startOf('day');
    const isToday = today.isSame(targetDate);

    return isToday
      ? `Today at ${dayjs(date).format('HH:mm')}`
      : `${dayjs(date).format('DD MMM')} at ${dayjs(date).format('HH:mm')}`;
  };

  const updateTaskData = (key: string, value: any) => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      [key]: value,
    }));
  };

  const handleMouseEnter = () => {
    if (isAdmin && !readOnly) {
      setTimeout(() => {
        if (!isPopupVisible) {
          setIsPopupVisible(true);
        }
      }, 100);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    if (!e.relatedTarget || !(e.relatedTarget instanceof Node) || !e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        if (isPopupVisible) {
          setIsPopupVisible(false);
        }
      }, 200);
    }
  };

  const handlePillClick = () => {
    if (!isPopupVisible) {
      setIsPopupVisible(true);
    }
  };

  const handleAssigneeMouseEnter = () => {
    if (isTodoView && taskData.assignees.length > 1) {
      setIsMemberPopupVisible(true);
    }
  };

  const handleAssigneeMouseLeave = () => {
    setIsMemberPopupVisible(false);
  };

  const getBackgroundColor = () => {
    if (isCreateEditView && isPopupVisible) return '#fff';
    if (isCreateEditView) return '#0c2337';
    return '#fff';
  };

  const renderMembersPopup = () => {
    const assignees = taskData.assignees || [];

    return (
      <div className="assignee-popup">
        {assignees.map((assignee: any) => {
          const member = members.find((m) => m.userId === assignee.userId);
          if (!member) return null;
          return (
            <AssigneeBadge
              key={assignee.userId}
              userId={assignee.userId}
              firstName={member.firstName}
              lastName={member.lastName}
              isCompleted={assignee.completed}
              view={view}
            />
          );
        })}
      </div>
    );
  };

  const renderPopup = (content: React.ReactNode, className = '') => {
    return createPortal(
      isPopupVisible ? (
        <div
          className={`popup ${className}`}
          style={{
            top: popupPosition.top,
            left: popupPosition.left,
          }}
        >
          <div className="popup-body">{content}</div>
        </div>
      ) : null,
      document.body
    );
  };

  useEffect(() => {
    if (isPopupVisible && pillRef.current) {
      const rect = pillRef.current.getBoundingClientRect();
      setPopupPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isPopupVisible]);

  useEffect(() => {
    if (isMobile && isPopupVisible) {
      setTimeout(() => {
        setIsPopupVisible(true);
      }, 100);
    }
  }, [isPopupVisible]);

  const renderDueDateTime = () => {
    return (
      <div className="pill-group" ref={pillRef} onClick={handlePillClick}>
        <Calendar color={getBackgroundColor()} strokeWidth="2" size={iconSize} />
        {formatDueDateTime(taskData.dueDateTime)}

        {renderPopup(
          <CustomDateTimePicker
            taskData={taskData}
            setTaskData={setTaskData}
            open={true}
            onClose={() => setIsPopupVisible(false)}
            setIsPopupVisible={setIsPopupVisible}
          />,
          'hide-popup-border'
        )}
      </div>
    );
  };

  const renderRecurrence = () => {
    const getLabel = (value: string): string => {
      const labels = {
        doNotRepeat: 'Do Not Repeat',
        hourly: 'Hourly',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
      };
      return labels[value] || 'Unknown';
    };

    return (
      <div className="pill-group" ref={pillRef}>
        <Repeat color={getBackgroundColor()} strokeWidth="2" size={iconSize} />
        {getLabel(taskData.recurrence as TaskRecurrence)}
        {renderPopup(
          Object.values(TaskRecurrence).map((option) => (
            <div
              key={option}
              className={`option ${taskData.recurrence === option ? 'selected' : ''}`}
              onClick={() => updateTaskData('recurrence', option)}
            >
              {getLabel(option)}
            </div>
          ))
        )}
      </div>
    );
  };

  const renderDeliveryMethods = () => {
    const getDMLabel = (methods: string[], allMethods: Record<string, string>): string => {
      const orderedMethods = [DeliveryMethod.PUSH_NOTIFICATION, DeliveryMethod.EMAIL];
      const sortedMethods = orderedMethods.filter((method) => methods.includes(method));
      if (sortedMethods.length === 0) return 'No delivery methods';
      return sortedMethods.map((method) => allMethods[method] || 'Unknown').join(' + ');
    };

    const enabledMethods = Array.isArray(taskData.deliveryMethods) ? taskData.deliveryMethods : [];
    const allMethods = {
      [DeliveryMethod.EMAIL]: 'Email',
      [DeliveryMethod.PUSH_NOTIFICATION]: 'Push notification',
    };

    const updateDeliveryMethod = (method: string, isEnabled: boolean) => {
      const updatedMethods = isEnabled ? [...enabledMethods, method] : enabledMethods.filter((m) => m !== method);

      updateTaskData('deliveryMethods', updatedMethods);
    };

    const label = getDMLabel(enabledMethods, allMethods);

    return (
      <div className="pill-group" ref={pillRef}>
        <Bell color={getBackgroundColor()} strokeWidth="2" size={iconSize} />
        {label}
        {renderPopup(
          <div style={{ paddingLeft: '12px' }}>
            {Object.entries(allMethods).map(([method, methodLabel]) => {
              const isEnabled = enabledMethods.includes(method);
              return (
                <div key={method} className="delivery-method">
                  <FormControlLabel
                    control={
                      <Switch checked={isEnabled} onChange={(e) => updateDeliveryMethod(method, e.target.checked)} />
                    }
                    label={
                      <div className="switch-item">
                        <Text size="14px" style={{ whiteSpace: 'nowrap' }}>
                          {methodLabel}
                        </Text>
                        <Text size="12px" color="rgb(43, 46, 49)" style={{ marginLeft: '10px' }}>
                          {isEnabled ? 'On' : 'Off'}
                        </Text>
                      </div>
                    }
                    labelPlacement="start"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderAssignees = () => {
    const assignees = taskData.assignees || [];

    const toggleAllAssignees = (assignAll: boolean) => {
      const updatedAssignees = assignAll
        ? members.map((member) => ({
            userId: member.userId,
            completed: assignees.find((a) => a.userId === member.userId)?.completed || false,
          }))
        : [];

      updateTaskData('assignees', updatedAssignees);
      updateTaskData('assignedToAll', assignAll);
    };

    const toggleAssignee = (userId: string, isAssigned: boolean) => {
      const memberExists = members.some((m) => m.userId === userId);
      if (!memberExists) return;

      const updatedAssignees = isAssigned
        ? [...assignees, { userId, completed: false }]
        : assignees.filter((assignee) => assignee.userId !== userId);

      updateTaskData('assignees', updatedAssignees);

      if (taskData.assignedToAll && updatedAssignees.length !== members.length) {
        updateTaskData('assignedToAll', false);
      }

      if (!taskData.assignedToAll && updatedAssignees.length === members.length) {
        updateTaskData('assignedToAll', true);
      }
    };

    const findMemberById = (userId: string) => {
      return members.find((m) => m.userId === userId);
    };

    const validAssignees = assignees.filter((assignee) => members.some((member) => member.userId === assignee.userId));

    return (
      <div
        className="pill-group"
        ref={pillRef}
        onMouseEnter={handleAssigneeMouseEnter}
        onMouseLeave={handleAssigneeMouseLeave}
      >
        {taskData.assignedToAll ? (
          <>
            <Users color={getBackgroundColor()} strokeWidth="2" size={iconSize} /> All
          </>
        ) : validAssignees.length === 1 ? (
          validAssignees[0].userId === userId ? (
            <>
              <User color={getBackgroundColor()} strokeWidth="2" size={iconSize} /> You
            </>
          ) : (
            <>
              <User color={getBackgroundColor()} strokeWidth="2" size={iconSize} />
              {`${findMemberById(validAssignees[0].userId)?.firstName} ${
                findMemberById(validAssignees[0].userId)?.lastName
              }`}
            </>
          )
        ) : (
          <>
            <Users color={getBackgroundColor()} strokeWidth="2" size={iconSize} />
            {`${validAssignees.length} assigned`}
          </>
        )}
        {isMemberPopupVisible && renderMembersPopup()}
        {renderPopup(
          <div style={{ paddingLeft: '12px' }}>
            <div className="assignee">
              <FormControlLabel
                control={
                  <Switch checked={!!taskData.assignedToAll} onChange={(e) => toggleAllAssignees(e.target.checked)} />
                }
                label={
                  <div className="switch-item">
                    <Text size="14px" weight="500" style={{ whiteSpace: 'nowrap' }}>
                      {isMobile ? 'All team' : 'Assigned to all team'}
                    </Text>
                    <Text size="12px" color="rgb(43, 46, 49)" style={{ marginLeft: '10px' }}>
                      {taskData.assignedToAll ? 'On' : 'Off'}
                    </Text>
                  </div>
                }
                labelPlacement="start"
              />
            </div>
            {members.map((member) => {
              const isAssigned = validAssignees.some((assignee) => assignee.userId === member.userId);
              return (
                <div key={member.userId} className="assignee">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!isAssigned}
                        onChange={(e) => toggleAssignee(member.userId, e.target.checked)}
                      />
                    }
                    label={
                      <div className="switch-item">
                        <div className="d-flex align-items-center">
                          <div className="initials">{`${member?.firstName[0] || ''}${member?.lastName[0] || ''}`}</div>
                          <Text size="14px" style={{ whiteSpace: 'nowrap' }}>
                            {member.userId === userId ? 'You' : `${member.firstName} ${member.lastName}`}
                            {member.userId === userId && ' (admin)'}
                          </Text>
                        </div>
                        <Text size="12px" color="rgb(43, 46, 49)" style={{ marginLeft: '10px' }}>
                          {isAssigned ? 'On' : 'Off'}
                        </Text>
                      </div>
                    }
                    labelPlacement="start"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    switch (type) {
      case 'dueDateTime':
        return renderDueDateTime();
      case 'recurrence':
        return renderRecurrence();
      case 'deliveryMethods':
        return showTodoPills || isCreateEditView ? renderDeliveryMethods() : null;
      case 'assignees':
        return showTodoPills || isCreateEditView ? renderAssignees() : null;
      default:
        return null;
    }
  };

  return (
    <>
      {!isHistoryView && renderContent() && (
        <div
          ref={pillRef}
          className={isCreateEditView ? `pill-edit ${isPopupVisible ? 'pill-edit-active' : ''}` : 'pill'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {renderContent()}
        </div>
      )}
    </>
  );
};

export default TaskPills;
