import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft } from 'react-feather';

import { BlackBtn, BlackButtonBox, CheckboxContainer, ContentBox, LeftCheckinPanel, LeftPanel, Root } from './_styled';
import { Text } from '../../Shared/Typography/Typography';
import CheckinSteps from './CheckinSteps';
import services from '../../../API/services';
import { iWheel } from '../../../API/interfaces';
import { RootState } from '../../../Redux/Reducers';
import checkbox from '../../../Assets/images/onboarding/checkbox-blank.png';
import { analytics } from '../../../analytics/analytics';

const CheckinScreen = ({ onBack, wheel }) => {
  const history = useHistory();
  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));

  useEffect(() => {
    if (!wheel) {
      history.push('/dashboard');
    }
  }, [wheel, history]);

  const redirectToWheel = () => {
    history.push(`/wheel/${wheel.id}?postCheckin=true`);
  };

  const skipOnboarding = () => {
    analytics.clickOnbTestCheckinSkip();
    history.push(shareLinkParams ? `/share${shareLinkParams}` : '/dashboard');
  };

  return (
    <Root>
      <LeftCheckinPanel>
        <BlackButtonBox>
          <BlackBtn onClick={onBack} style={{ color: 'white' }}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
        </BlackButtonBox>
        <ContentBox>
          <Text className="mt-2 mb-4" weight={600} size="36px" color="white">
            Let’s complete your first <br /> check in 👉
          </Text>
          <CheckboxContainer>
            <img src={checkbox} alt="checkbox" />
            <Text color="#fff">Be consistent with check in's to help identify trends</Text>
          </CheckboxContainer>
          <CheckboxContainer>
            <img src={checkbox} alt="checkbox" />
            <Text color="#fff">Scoring low or high with how you feel you are at in regards to the segment.</Text>
          </CheckboxContainer>
          <CheckboxContainer>
            <img src={checkbox} alt="checkbox" />
            <Text color="#fff">
              Add a comment to help you remember what was going on when you completed the check in.
            </Text>
          </CheckboxContainer>
        </ContentBox>
      </LeftCheckinPanel>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={skipOnboarding}>SKIP</BlackBtn>
        </BlackButtonBox>
        <ContentBox style={{ justifyContent: 'center' }}>
          {wheel && <CheckinSteps wheel={wheel} onComplete={redirectToWheel} />}
        </ContentBox>
      </LeftPanel>
    </Root>
  );
};

export default CheckinScreen;
