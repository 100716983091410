import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, TabContainer } from '../../Shared/TabContainer/TabContainer';
import { X } from 'react-feather';
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import {
  iUser,
  iWheel,
  iWheelExt,
  ManualInvitationMessages,
  WheelMemberRole,
  WheelMemberRoleType,
} from '../../../API/interfaces';
import { Text } from '../../Shared/Typography/Typography';
import { FiLink } from 'react-icons/fi';
import { IoAdd, IoQrCodeOutline } from 'react-icons/io5';
import { LinkInput } from './LinkInput';
import styled from 'styled-components';
import { Button } from '../../Shared/Buttons/Button';
import { QRCode } from 'react-qrcode-logo';
import qrLogo from '../../../Assets/images/qr-logo.png';
import utils, { getWheelRole, isWheelAdmin } from '../../Shared/utils';
import { createShareLink } from '../common/InviteButton/utils';
import { RadioButton } from '../../Shared/RadioButton';
import { WHEEL_ACCESS_TYPES } from '../../../constants';
import { analytics } from '../../../analytics/analytics';
import { ManualUserForm } from './ManualUserForm';
import success from '../../../Assets/images/invitations/success-icon.svg';
import services from '../../../API/services';
import { connect } from 'react-redux';
import MembersInvitationSteps from './MembersInvitationSteps/MembersInvitationSteps';

interface NewUserData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}
interface WheelInvitationProps {
  wheel: iWheelExt | iWheel;
  onCancel: () => any;
  classes: any;
  role?: WheelMemberRoleType;
  user: iUser;
}

const steps = {
  DEFAULT: 0,
  UPLOAD: 1,
  INVITATION_SENT: 2,
};

export const InviteToWheelModalComponent: React.FC<WheelInvitationProps> = ({ wheel, onCancel, classes, role = WheelMemberRole.MEMBER, ...props }) => {
  const ref = useRef();
  const isPhone = utils.checkIsPhone();
  const size = 220;
  const [inviteRole, setInviteRole] = useState<WheelMemberRoleType>(role);
  const [activeTab, setActiveTab] = useState(0);
  const [newUserData, setNewUserData] = useState<NewUserData | null>(null);
  const [userWheelRole, setUserWheelRole] = useState('');
  const [uploadedData, setUploadedData] = useState([]);
  const [failedInvites, setFailedInvites] = useState<string[]>([]);
  const [step, setStep] = useState(steps.DEFAULT);

  const { user } = props;

  useEffect(() => {
    const role = () => {
      if (wheel?.access === WHEEL_ACCESS_TYPES.PUBLIC) {
        return WheelMemberRole.MEMBER;
      }

      const userRole = getWheelRole(wheel, user._id);
      if (!userRole || userRole === WheelMemberRole.MEMBER) {
        return WheelMemberRole.MEMBER;
      }
      return userRole;
    };
    setUserWheelRole(role());
  }, [wheel, user._id]);

  const isAdmin = isWheelAdmin(wheel, user._id);
  const url = useMemo(() => (wheel ? createShareLink(wheel, inviteRole, user._id) : ''), [wheel, inviteRole, user._id]);

  const onImageDownload = () => {
    // @ts-ignore
    const canvas = ref.current?.children[0];
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = 'QrCode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const onChangeInviteRole = (role: WheelMemberRoleType) => {
    setInviteRole(role);
  };

  const handleUserAdded = (firstName: string, lastName: string, email: string, message: string) => {
    setNewUserData({
      firstName,
      lastName,
      email,
      message,
    });
    setUploadedData([{ firstName, lastName, email }]);
    setStep(steps.INVITATION_SENT);
  };

  const handleCancel = () => {
    setStep(steps.DEFAULT);
    onCancel();
  };

  const handleUploadClick = () => {
    setStep(steps.UPLOAD);
  };

  const handleSendClick = () => {
    setStep(steps.INVITATION_SENT);
  };

  const handleBackClick = () => {
    setStep(steps.DEFAULT);
  };

  const handleSendInvitations = async () => {
    const failedEmails = [];

    for (const member of uploadedData) {
      const response = await services.addManualUserToWheel(wheel.id, member);

      if (response.status !== 200) {
        failedEmails.push(member.email);
      }
    }

    setFailedInvites(failedEmails);
    setStep(steps.INVITATION_SENT);
  };

  const dialogTitle = `Invite ${userWheelRole === WheelMemberRole.MEMBER ? 'member' : 'buddy'} to the wheel`;

  const roleDescription = {
    member:
      'Members are able to complete check-ins for this wheel and can view their personal results to reflect on their journey.',
    accountability_buddy:
      'Invite a buddy who can see your check-in data, reply to comments and assist you on your journey of overcoming obstacles and achieving your goals.',
  };

  const modalBodyText = {
    link: {
      member:
        'Copy this invitation link and share it with the people you want to join the wheel or complete this check-in.',
      accountability_buddy: 'Copy this invite link and share it with whoever you wish to be your accountability buddy.',
    },
    qrCode: {
      member:
        'Download the invitation QR code and share it with people you wish to invite and join the wheel. Or ask people to scan it from here.',
      accountability_buddy:
        'Download the invitation QR code and share it with a buddy who can see your check-in data. Or ask a buddy to scan it from here.',
    },
  };

  const renderMessage = () => {
    if (!newUserData?.message) return null;

    const messageContent = {
      [ManualInvitationMessages.USER_CREATED]: `Until ${newUserData.firstName} ${newUserData.lastName} accepts the invitation, as an admin, you can assist them with completing check-ins.`,
      [ManualInvitationMessages.USER_IN_SYSTEM]: `The user ${newUserData.firstName} ${newUserData.lastName} already exists and has been successfully added to the wheel.`,
      [ManualInvitationMessages.USER_AB]: `The user ${newUserData.firstName} ${newUserData.lastName} is already an accountability buddy and cannot be added as a regular member.`,
      [ManualInvitationMessages.USER_MEMBER]: `The user ${newUserData.firstName} ${newUserData.lastName} is already a member of the wheel.`,
    };

    return messageContent[newUserData.message] ? <>{messageContent[newUserData.message]}</> : null;
  };

  return (
    <Dialog open={Boolean(wheel)} onClose={handleCancel} classes={{ paper: classes.dialog }} className={classes.modal}>
      <X className={classes.closeBtn} size={18} color="#7b858e" onClick={handleCancel} />

      {step === steps.DEFAULT ? (
        <>
          {wheel?.access === WHEEL_ACCESS_TYPES.PUBLIC ||
            userWheelRole === WheelMemberRole.MEMBER ? (
            <>
              <DialogTitle classes={{ root: classes.title }}>
                <Text size="24px" mSize="16px">
                  {dialogTitle}
                </Text>
              </DialogTitle>
              <Text size="14px" margin="0 40px">
                {roleDescription[inviteRole]}
              </Text>
            </>
          ) : (
            <RoleControls>
              <RadioButton
                isBoldSelected
                textSize="16px"
                value={WheelMemberRole.MEMBER}
                selected={inviteRole}
                text="Invite Members to Wheel"
                onChange={(e) => {
                  analytics.shareWheel();
                  onChangeInviteRole(e);
                }}
                infoIconTooltipText={roleDescription[WheelMemberRole.MEMBER]}
              />
              <RadioButton
                isBoldSelected
                textSize="16px"
                value={WheelMemberRole.ACCOUNTABILITY_BUDDY}
                selected={inviteRole}
                text="Invite Accountability Buddy to Wheel"
                onChange={(e) => {
                  analytics.clickInviteBuddy();
                  onChangeInviteRole(e);
                }}
                infoIconTooltipText={roleDescription[WheelMemberRole.ACCOUNTABILITY_BUDDY]}
                disabled={activeTab === 2}
                tooltipText=""
              />
            </RoleControls>
          )}

          <DialogContent classes={{ root: classes.content }}>
            <TabContainer defaultTabIndex={activeTab} onChangeTab={setActiveTab} key={inviteRole}>
              <Tab
                className={classes.tab}
                title={
                  <span className="d-flex-inline align-items-center position-relative">
                    <FiLink className="mr-1 position-relative" style={{ top: '-1px' }} />
                    <Text size="16px!important" mSize="12px!important">
                      Copy Link
                    </Text>
                  </span>
                }
              >
                <ContentWrapper>
                  <div className="mb-4">
                    <Text size="16px" mSize="12px">
                      {modalBodyText.link[inviteRole]}
                    </Text>
                  </div>
                  <div className="pt-3">
                    <LinkInput url={url} inviteRole={inviteRole}></LinkInput>
                  </div>
                </ContentWrapper>
              </Tab>
              <Tab
                className={classes.tab}
                title={
                  <span className="d-flex-inline align-items-center position-relative">
                    <IoQrCodeOutline className="mr-1 position-relative" style={{ top: '-1px' }} />
                    <Text size="16px!important" mSize="12px!important">
                      Share QR code
                    </Text>
                  </span>
                }
              >
                <ContentWrapper>
                  <div className="d-flex align-items-center flex-column flex-lg-row">
                    <div style={{ height: size, width: size }} className="flex-shrink-0">
                      <div ref={ref}>
                        <QRCode
                          value={url}
                          size={size - 20}
                          quietZone={10}
                          bgColor="#fefaf2"
                          logoImage={qrLogo}
                          logoWidth={size * 0.25}
                          logoHeight={size * 0.25}
                        />
                      </div>
                    </div>
                    <div
                      className="ml-0 ml-lg-4 d-flex flex-column justify-content-between mt-4 mt-lg-0 text-center text-lg-left"
                      style={{ height: !isPhone ? size - 20 : size - 80 }}
                    >
                      <Text size="16px" mSize="12px" color="#0c2337">
                        {modalBodyText.qrCode[inviteRole]}
                      </Text>
                      <div className="d-flex justify-content-center justify-content-lg-end mt-2">
                        <Button onClick={onImageDownload}>Download QR code</Button>
                      </div>
                    </div>
                  </div>
                </ContentWrapper>
              </Tab>

              {isAdmin && (
                <Tab
                  className={classes.tab}
                  title={
                    <span className="d-flex-inline align-items-center position-relative">
                      <IoAdd className="mr-1 position-relative" style={{ top: '-1px' }} />
                      <Text size="16px!important" mSize="12px!important">
                        Add Manually
                      </Text>
                    </span>
                  }
                >
                  <ContentWrapper>
                    <div className="d-flex align-items-center flex-column flex-lg-row">
                      <ManualUserForm
                        wheelId={wheel?.id}
                        onUserAdded={handleUserAdded}
                        onUploadClick={handleUploadClick}
                      />
                    </div>
                  </ContentWrapper>
                </Tab>
              )}
            </TabContainer>
          </DialogContent>
        </>
      ) : step === steps.UPLOAD ? (
        <>
          <DialogContent classes={{ root: classes.content }}>
            <MembersInvitationSteps
              onNext={handleSendInvitations}
              setUserData={setUploadedData}
              onBack={handleBackClick}
              wheelId={wheel?.id}
              onInvitationsSent={handleSendClick}
            />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent classes={{ root: classes.invitation }}>
            <div className="d-flex flex-column align-items-start" style={{ textAlign: 'left' }}>
              {newUserData?.message !== ManualInvitationMessages.USER_AB &&
                newUserData?.message !== ManualInvitationMessages.USER_MEMBER && (
                  <>
                    <img src={success} alt="Success" className="mb-4" />
                    <Text size="30px" mSize="12px" className="mb-3">
                      {uploadedData.length > 0 && uploadedData.length === failedInvites.length
                        ? 'Invitation failed for all members!'
                        : uploadedData.length === 1
                          ? 'Invitation sent!'
                          : `Invitations sent to ${uploadedData.length - failedInvites.length} members!`}
                    </Text>
                  </>
                )}

              {failedInvites.length > 0 && (
                <>
                  <Text size="14px" mSize="12px" className="mb-3" color="#ff0000">
                    Failed to send invitations to the following emails:
                  </Text>
                  <Text mSize="12px" className="mb-3">
                    {failedInvites.join(', ')}
                  </Text>
                </>
              )}
              {uploadedData.length === 1 && newUserData ? (
                <Text mSize="12px" className="mb-3">
                  {renderMessage()}
                </Text>
              ) : (
                <Text mSize="12px" className="mb-3">
                  Until members accept the invitations, as an admin, you can assist them with completing check-ins.
                </Text>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button onClick={handleCancel} className="exit-btn mb-3">
                Got it
              </button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const ContentWrapper = styled.div`
  padding: 40px 40px 0px;
  @media (max-width: 1024px) {
    padding: 24px 24px 0px;
  }
`;

const RoleControls = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

const styles = {
  modal: {
    '& .tab-container': {
      minHeight: '255px',
      backgroundColor: '#fefaf2',
      '& .tab-body': {
        minHeight: '200px',
      },
    },
  },
  dialog: {
    padding: '32px 0px 24px',
    backgroundColor: '#fefaf2',
    width: '620px',
  },
  invitation: {
    padding: '8px 40px 0',
  },
  closeBtn: {
    position: 'absolute' as any,
    top: '30px',
    right: '30px',
    cursor: 'pointer',
    '@media(max-device-width: 767px)': {
      top: '15px',
      right: '15px',
    },
  },
  title: {
    padding: '0 40px',
    '@media(max-device-width: 767px)': {
      padding: '0 20px',
    },
  },
  content: {
    margin: '0 0 16px',
    padding: '0',
  },
  tab: {
    backgroundColor: '#fefaf2',
    width: '50%',
    textAlign: 'center' as any,
    '@media(max-device-width: 767px)': {
      padding: '20px 10px 10px!important',
    },
  },
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(withStyles(styles)(InviteToWheelModalComponent));
