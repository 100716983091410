import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

export const parseDuration = (durationString: string) => {
  const match = durationString.match(/(\d+)\s+hours\s+(\d+)\s+minutes/);
  return match
    ? { hours: String(Number(match[1]) || 0), minutes: String(Number(match[2]) || 0) }
    : { hours: '', minutes: '' };
};

interface DurationInputProps {
  value: { hours: string; minutes: string };
  onChange: (value: { hours: string; minutes: string }) => void;
  formik: any;
}

const DurationInput: React.FC<DurationInputProps> = ({ value, onChange, formik }) => {
  const durationValue = {
    hours: String(value.hours || ''),
    minutes: String(value.minutes || ''),
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let hours = e.target.value.replace(/\D/g, '');

    if (hours === '') {
      onChange({ ...durationValue, hours: '' });
      formik.setFieldValue('hours', '');
    } else {
      onChange({ ...durationValue, hours: String(Number(hours)) });
      formik.setFieldValue('hours', String(Number(hours)));
    }

    formik.setFieldTouched('hours', true);
    setTimeout(() => formik.validateForm(), 50);
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let minutes = e.target.value.replace(/\D/g, '');
    if (minutes === '') {
      onChange({ ...durationValue, minutes: '' });
      formik.setFieldValue('minutes', '');
    } else {
      onChange({ ...durationValue, minutes: String(Number(minutes)) });
      formik.setFieldValue('minutes', String(Number(minutes)));
    }

    formik.setFieldTouched('minutes', true);
    setTimeout(() => formik.validateForm(), 50);
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        label="Hours"
        placeholder="Hours"
        value={durationValue.hours}
        onChange={handleHoursChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
        error={formik.touched.hours && Boolean(formik.errors.hours)}
        helperText={formik.touched.hours ? formik.errors.hours : ''}
        style={{ width: '110px' }}
        InputLabelProps={{ shrink: Boolean(durationValue.hours) }}
      />
      <span style={{ marginLeft: '16px', marginTop: '17px' }}>and</span>
      <TextField
        label="Minutes"
        placeholder="Minutes"
        value={durationValue.minutes}
        onChange={handleMinutesChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
        error={formik.touched.minutes && Boolean(formik.errors.minutes)}
        helperText={formik.touched.minutes ? formik.errors.minutes : ''}
        style={{ width: '120px' }}
        InputLabelProps={{ shrink: Boolean(durationValue.minutes) }}
      />
    </Stack>
  );
};

export default DurationInput;
