import { Text } from '../../Shared/Typography/Typography';
import 'dayjs/locale/en-gb';
import { TextField } from '@material-ui/core';

const AgeForm = ({ formik }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('age', value);
    }
  };

  const handleInputFocus = () => {
    formik.setFieldTouched('age', true, false);
  };

  return (
    <>
      <Text weight={600} size="32px">
        What is your age?
      </Text>
      <form onSubmit={formik.handleSubmit} style={{ width: '200px', marginTop: '17px' }}>
        <TextField
          id="age"
          name="age"
          label="Enter Your Age"
          variant="outlined"
          fullWidth
          inputProps={{ min: 6, max: 99 }}
          value={formik.values.age ?? ''}
          onChange={handleInputChange}
          onBlur={formik.handleBlur('age')}
          onFocus={handleInputFocus}
          error={formik.touched.age && Boolean(formik.errors.age)}
          helperText={formik.touched.age && formik.errors.age}
          className="mb-4"
        />
      </form>
    </>
  );
};

export default AgeForm;
