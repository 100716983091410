import styled, { css } from 'styled-components';

import { Button } from '../../Shared/_styled';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from './_consts';

export const CreateFolderButton = styled(Button)`
  background-color: #2a3e50;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 16px 4px 8px;
`;

export const noScrollbarCss = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Container = styled.aside`
  overflow-y: scroll;
  padding-top: 50px;
  background-color: #0f2136;
  min-width: 260px;
  min-height: 100%;

  @media (max-width: 575px) {
    min-height: unset;
  }

  ${noScrollbarCss};
`;
