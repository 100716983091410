import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Tag, Text, Text1 } from '../_styled';
import { SearchDesktop } from '../Search/SearchDesktop';
import { SortDesktop } from '../Sort/SortDesktop';
import { WHEEL_FILTER_TAGS } from '../_consts';
import { eWheelFilterTags } from '../_types';
import { formatTag, useInfiniteScroll } from '../_utils';
import NoWheelsCreated from '../NoWheelsCreated';
import NothingFound from '../NothingFound';
import { StyledMainButton, List, Cards } from './_styled';
import { iProps } from './_types';
import { CardDesktop } from '../Card';
import nUser from '../../../_utils/user';
import { SpinnerBackground, Spinner } from '../../Shared/Spinner/Spinner';
import { useDebouncedCallback } from 'use-debounce';
import TasksDueToday from '../TasksDueToday/TasksDueToday';

export const DashboardDesktop = (props: iProps) => {
  const {
    query,
    setQuery,
    setSort,
    filter,
    onFilterTagClick,
    wheels,
    searchResults,
    isSearching,
    user,
    showWheelDuplicateModal,
    showNoWheelsHaveBeenCreated,
    history,
    onCreateWheel,
    folders,
    onMoveWheelToFolder,
    setWheelToDelete,
    setWheelToInvite,
    setWheelToLeave,
    onLoadMore,
    loading,
    totalWheels,
  } = props;

  const cardsRef = useInfiniteScroll(onLoadMore);
  const [typing, setTyping] = useState(false);
  const [currentWheels, setCurrentWheels] = useState(wheels);
  const [spinnerActive, setSpinnerActive] = useState(false);

  const handleSearchChange = (value: string) => {
    setQuery(value);
    setTyping(true);
    setSpinnerActive(true);
    debounceStopTyping();
  };

  const debounceStopTyping = useDebouncedCallback(() => {
    setTyping(false);
  }, 1000);

  useEffect(() => {
    if (!typing && !isSearching) {
      setCurrentWheels(query ? searchResults : wheels);
      setSpinnerActive(false);
    }
  }, [searchResults, wheels, typing, isSearching, query]);

  const displayedWheels = query ? currentWheels : wheels;

  const renderLoader = () => (
    <LoaderWrapper>
      <SpinnerBackground height="60px">
        <Spinner />
      </SpinnerBackground>
    </LoaderWrapper>
  );

  return (
    <ContainerDesktop className="container" fullWidth={nUser.hasAccessToFolders(user)}>
      <TasksDueToday />
      <div className="col-12 d-flex" style={{ marginTop: '24px' }}>
        <Text className="col-4 pl-0">Dashboard</Text>

        <div className="col-8 pr-0 d-flex justify-content-end align-items-center">
          <SearchDesktop value={query} onChange={handleSearchChange} />
          <SortDesktop setSort={setSort} />
          <StyledMainButton onClick={onCreateWheel}>CREATE A NEW WHEEL</StyledMainButton>
        </div>
      </div>

      <List className="col-12">
        {WHEEL_FILTER_TAGS.map((tag: eWheelFilterTags) => (
          <Tag
            selected={filter.access?.includes(tag) || filter.roles?.includes(tag)}
            key={tag}
            onClick={() => onFilterTagClick(tag)}
          >
            {formatTag(tag)}
          </Tag>
        ))}
      </List>

      {spinnerActive || isSearching ? (
        renderLoader()
      ) : !_.isEmpty(displayedWheels) ? (
        <>
          <Text1 className="col-12">{`All Wheels (${displayedWheels.length}/${totalWheels})`}</Text1>
          <Cards ref={cardsRef} className="col-12">
            {displayedWheels.map((wheel, index) => (
              <CardDesktop
                key={uuidv4()}
                id={wheel.id}
                wheel={wheel}
                user={user}
                history={history}
                removeWheel={() => setWheelToDelete(wheel)}
                inviteToWheel={() => setWheelToInvite(wheel)}
                duplicateWheel={() => showWheelDuplicateModal(wheel)}
                leaveWheel={() => setWheelToLeave(wheel)}
                folders={folders}
                onMoveWheelToFolder={(folder) => onMoveWheelToFolder(folder, wheel)}
              />
            ))}
            {loading && renderLoader()}
          </Cards>
        </>
      ) : null}

      {showNoWheelsHaveBeenCreated && <NoWheelsCreated />}

      {!showNoWheelsHaveBeenCreated && !displayedWheels.length && !isSearching && !typing && !spinnerActive && (
        <NothingFound />
      )}
    </ContainerDesktop>
  );
};

const fullWidthContainerDesktopCss = css`
  max-width: 100%;
  margin-right: 70px;
  margin-left: 18px;
`;
const ContainerDesktop = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => fullWidth && fullWidthContainerDesktopCss};
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;
