import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Text } from '../Shared/Typography/Typography';
import './Settings.scss';
import { RootState } from '../../Redux/Reducers';
import { ModalBody, ModalFooter } from 'reactstrap';
import {
  BlackBtn,
  ContentModalBox,
  OptionsContainer,
  GoalsOptionsContainer,
  RedBtn,
  Options,
  OptionButton,
  CheckContainer,
  CustomOptionContainer,
  toolTipStyles,
  StyledModalOB,
} from '../Auth/Onboarding/_styled';
import { FaTimes } from 'react-icons/fa';
import { TextField, Tooltip } from '@material-ui/core';
import plusAdd from '../../Assets/images/onboarding/plus.svg';
import plus from '../../Assets/images/onboarding/plus.png';
import deleteIcon from '../../Assets/images/onboarding/trash-2.svg';
import alertIcon from '../../Assets/images/onboarding/alert-circle.svg';
import edit from '../../Assets/images/onboarding/edit-3.svg';
import { Check } from 'react-feather';
import { questionnaireData, toolTips } from '../Auth/Onboarding/constants';
import { updatePersonalDataAction } from '../Auth/Auth.actions';
import services from '../../API/services';

interface GoalProps extends PropsFromRedux {}

const Goal: React.FC<GoalProps> = ({ user, updatePersonalDataAction }) => {
  const [goals, setGoals] = useState<string[]>([]);
  const [focusAreas, setFocusAreas] = useState<{ [key: string]: string[] }>({});
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [customOptions, setCustomOptions] = useState<string[]>([]);
  const [customOption, setCustomOption] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);

  const classes = toolTipStyles();

  useEffect(() => {
    if (user.personalData) {
      setGoals(user.personalData.goals?.length ? user.personalData.goals : goals);
      setFocusAreas({
        challenges: user.personalData.challenges?.length ? user.personalData.challenges : ['No data'],
        focus: user.personalData.focus?.length ? user.personalData.focus : ['No data'],
        resources: user.personalData.resources?.length ? user.personalData.resources : ['No data'],
      });
    }
  }, [user.personalData]);

  const OptionsComponent = currentQuestion?.name === 'goals' ? GoalsOptionsContainer : OptionsContainer;
  const formatOptionText = (option) => {
    if (currentQuestion?.name !== 'goals') {
      return option;
    }

    if (currentQuestion?.name === 'goals' && !option.includes('\n')) {
      return option;
    }

    const [boldText, plainText] = option.split(':\n');

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{boldText}:</b>
        {plainText && <span>{plainText}</span>}
      </div>
    );
  };

  const addGoal = () => {
    toggleModal('goals');
  };

  const deleteGoal = (index: number) => {
    const updatedGoals = goals.filter((_, i) => i !== index);
    setGoals(updatedGoals);
    const updatedData = {
      ...user.personalData,
      goals: updatedGoals,
    };
    updatePersonalDataAction(updatedData);
  };

  const handleCloseModal = () => {
    setShowCreateFolderModal(false);
  };

  const toggleModal = (sectionName: string) => {
    const userTag = user.personalData?.userTag || 'Other';
    const question = questionnaireData[userTag]?.find((q) => q.name === sectionName);

    if (question) {
      setCurrentQuestion(question);
      setSelectedOptions(user.personalData[sectionName] || []);
      setCustomOptions([]);
      setCustomOption('');
      setShowCreateFolderModal(true);
    } else {
      console.warn(`No question found for section name: ${sectionName}`);
    }
  };

  const handleCheckboxChange = (option: string) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : prevSelected.length < 3 || currentQuestion?.name !== 'focus'
        ? [...prevSelected, option]
        : prevSelected
    );
  };

  const handleCustomOptionAdd = () => {
    if (customOption && !selectedOptions.includes(customOption) && !customOptions.includes(customOption)) {
      setCustomOptions((prevOptions) => [...prevOptions, customOption]);
      setSelectedOptions((prevSelected) => [...prevSelected, customOption]);
      setCustomOption('');
    }
  };

  const handleSaveChanges = async () => {
    if (currentQuestion) {
      const updatedData = {
        ...user.personalData,
        [currentQuestion.name]: selectedOptions,
      };

      try {
        updatePersonalDataAction(updatedData);
        await services.updatePersonalData(updatedData);
        setShowCreateFolderModal(false);
      } catch (error) {
        console.error('Failed to update personal data:', error);
      }
    }
  };

  const renderGoalsSection = () => (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <Text size="18px" weight={500}>
            Goals
          </Text>
          <Tooltip arrow title={toolTips.goals} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
            <img src={alertIcon} style={{ marginLeft: '5px', width: '14px', height: '14px' }} alt="" />
          </Tooltip>
        </div>
        <button className="edit-button" onClick={addGoal}>
          <img src={plusAdd} alt="Edit" style={{ width: '14px', height: '14px', cursor: 'pointer' }} />
          Add
        </button>
      </div>
      {goals.length > 0 && (
        <div className="info-card">
          {goals.map((goal, index) => (
            <div key={index} className="info-item">
              <div className="d-flex flex-column">
                <Text size="14px">{`Goal ${index + 1}`}</Text>
                <Text size="16px" weight={700}>
                  {goal}
                </Text>
              </div>
              <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                <button className="edit-button" onClick={() => toggleModal('goals')}>
                  <img src={edit} alt="Edit" style={{ width: '14px', height: '14px', cursor: 'pointer' }} />
                  Edit
                </button>
                <img
                  src={deleteIcon}
                  onClick={() => deleteGoal(index)}
                  style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                  alt="Delete"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderFocusAreasSection = () => {
    const userTag = user.personalData?.userTag || 'Other';
    const focusQuestions = questionnaireData[userTag]?.filter((q) => q.name !== 'userRole' && q.name !== 'goals');

    return (
      <div>
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <Text size="18px" weight={500}>
              Focus Areas
            </Text>
            <Tooltip arrow title={toolTips.focus} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
              <img src={alertIcon} style={{ marginLeft: '5px', width: '14px', height: '14px' }} alt="" />
            </Tooltip>
          </div>
        </div>
        <div className="info-card">
          {focusQuestions?.map((question) =>
            question.name ? (
              <div key={question.id} className="info-item">
                <div className="d-flex flex-column">
                  <Text size="14px">{question.name.charAt(0).toUpperCase() + question.name.slice(1)}</Text>
                  <Text size="16px" weight={700}>
                    {user.personalData[question.name]?.join(', ') || 'No data'}
                  </Text>
                </div>
                <button className="edit-button" onClick={() => toggleModal(question.name)}>
                  <img src={edit} alt="Edit" style={{ width: '14px', height: '14px', cursor: 'pointer' }} />
                  Edit
                </button>
              </div>
            ) : null
          )}
        </div>
      </div>
    );
  };

  const renderModalContent = () => (
    <ContentModalBox style={{ width: '100%' }}>
      <Text size="32px" weight={600}>
        {currentQuestion?.question}
      </Text>
      <Text size="14px">
        {currentQuestion?.name === 'focus' ? 'Select up to 3 options' : 'You can select unlimited options'}
      </Text>
      <Options>
        <OptionsComponent>
          {[...(currentQuestion?.options || []), ...customOptions].map((option, index) => (
            <OptionButton
              key={index}
              selected={selectedOptions.includes(option)}
              onClick={() => handleCheckboxChange(option)}
            >
              <CheckContainer>{selectedOptions.includes(option) && <Check size={13} />}</CheckContainer>
              {formatOptionText(option)}
            </OptionButton>
          ))}
        </OptionsComponent>
        <CustomOptionContainer id="custom-option">
          <TextField
            fullWidth
            value={customOption}
            onChange={(e) => setCustomOption(e.target.value)}
            multiline={false}
            id="outlined-basic"
            label="Your option"
            variant="outlined"
            InputProps={{
              endAdornment: customOption ? (
                <img src={plus} alt="plus" width="52" height="52" onClick={handleCustomOptionAdd} />
              ) : null,
            }}
          />
        </CustomOptionContainer>
      </Options>
    </ContentModalBox>
  );

  return (
    <div className="settings-tab">
      {renderGoalsSection()}
      {renderFocusAreasSection()}
      <StyledModalOB id="styled-modal-ob" isOpen={showCreateFolderModal} centered>
        <ModalBody style={{ padding: '5px 2px' }}>
          <div className="d-flex justify-content-end">
            <FaTimes className="close-icon" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          </div>
          {currentQuestion && renderModalContent()}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none', marginTop: '25px', padding: '5px 2px' }}>
          <BlackBtn onClick={handleCloseModal}>Cancel</BlackBtn>
          <RedBtn
            onClick={handleSaveChanges}
            style={{
              marginLeft: '10px',
            }}
          >
            Save Changes
          </RedBtn>
        </ModalFooter>
      </StyledModalOB>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Goal);
