import { useState, useMemo } from 'react';
import { TaskRecurrence } from '../../../../../API/tasks.client';

const areArraysEqual = (array1: any[], array2: any[]) => {
  if (array1.length !== array2.length) return false;
  return array1.sort().join() === array2.sort().join();
};

const useTaskForm = (task: any) => {
  const [formData, setFormData] = useState({
    title: task?.title || '',
    description: task?.description || '',
    recurrence: task?.recurrence || TaskRecurrence.NONE,
    dueDateTime: task?.dueDateTime ? new Date(task.dueDateTime).toISOString() : new Date().toISOString(),
    deliveryMethods: task?.deliveryMethods || [],
    assignees: task?.assignees || [],
    assignedToAll: false,
    notifyAdmins: task?.notifyAdmins ?? false,
  });

  const [loadingSave, setLoadingSave] = useState(false);
  const initialFormData = useMemo(() => ({ ...formData }), []);

  const isSaveDisabled = useMemo(() => {
    const keysToCompare = [
      'title',
      'description',
      'recurrence',
      'dueDateTime',
      'deliveryMethods',
      'assignees',
      'notifyAdmins',
    ];

    return (
      loadingSave ||
      !formData.title ||
      !keysToCompare.some((key) => {
        if (key === 'deliveryMethods') {
          return !areArraysEqual(formData.deliveryMethods, initialFormData.deliveryMethods);
        }
        if (key === 'assignees') {
          return !areArraysEqual(
            formData.assignees.map((a) => a.userId),
            initialFormData.assignees.map((a) => a.userId)
          );
        }
        return formData[key] !== initialFormData[key];
      })
    );
  }, [formData, initialFormData, loadingSave]);

  const isCreateDisabled = useMemo(() => {
    return loadingSave || !formData.title;
  }, [loadingSave, formData.title]);

  return { formData, setFormData, loadingSave, setLoadingSave, isSaveDisabled, isCreateDisabled };
};

export default useTaskForm;
