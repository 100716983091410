import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers';
import {
  Root,
  LeftPanel,
  BlackBtn,
  BlackButtonBox,
  ContentBox,
  RedButtonBox,
  BtnsGroup,
  RedBtn,
  EmptyButton,
} from './_styled';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { updatePersonalDataAction } from '../Auth.actions';
import { RightScreen } from './RightScreen';
import services from '../../../API/services';
import { GenderField, NationalityField } from '../../Settings/ProfileFields';
import * as yup from 'yup';
import { useFormik } from 'formik';
import AgeForm from '../SignUp/AgeForm';
import { Text } from './../../Shared/Typography/Typography';

interface PersonalInfoProps extends PropsFromRedux {
  onNext: () => void;
  onBack: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  personalInfoSteps: string[];
  stepIndex: number;
  totalSteps: number;
}

const PersonalInfoScreen: React.FC<PersonalInfoProps> = ({
  user,
  updatePersonalDataAction,
  onNext,
  onBack,
  step,
  setStep,
  personalInfoSteps,
  stepIndex,
  totalSteps,
}) => {
  const [gender, setGender] = useState(user.personalData.gender || '');
  const [customGender, setCustomGender] = useState(user.personalData.customGender || []);
  const [nationality, setNationality] = useState(user.personalData.nationality ?? null);

  const isLastStep = stepIndex === personalInfoSteps.length - 1;

  const formik = useFormik({
    initialValues: { age: user.personalData.age || '' },
    validationSchema: yup.object({
      age: yup.number().nullable().min(6, 'Age must be at least 6').max(99, 'Age cannot exceed 99'),
    }),
    onSubmit: () => handleNext(),
  });

  useEffect(() => {
    if (stepIndex === 0) {
      if (user.personalData.gender) setGender(user.personalData.gender);
      if (user.personalData.customGender) setCustomGender(user.personalData.customGender);
    }
  }, [stepIndex, user.personalData]);

  const handleNext = () => {
    saveCurrentStep();
    if (!isLastStep) {
      setStep((prev) => prev + 1);
    } else {
      setStep((prev) => prev + 1);
      onNext();
    }
  };

  const handleBack = () => {
    if (stepIndex > 0) {
      setStep((prev) => prev - 1);
    } else {
      onBack();
    }
  };

  const skipQuestion = () => {
    setStep((prev) => prev + 1);
    if (isLastStep) {
      onNext();
    }
  };

  const saveCurrentStep = async () => {
    let updatedData = { ...user.personalData };
    if (stepIndex === 0) {
      updatedData.gender = gender;
      updatedData.customGender = customGender;
    } else if (stepIndex === 1) {
      updatedData.nationality = nationality;
    } else if (stepIndex === 2) {
      updatedData.age = formik.values.age;
    }
    updatePersonalDataAction(updatedData);
    await services.updatePersonalData(updatedData);
  };

  const resetCurrentStep = () => {
    if (stepIndex === 0) setGender('');
    else if (stepIndex === 1) setNationality('');
    else if (stepIndex === 2) formik.setFieldValue('age', '');
  };

  const isNextButtonDisabled = () => {
    if (stepIndex === 0) return !gender;
    if (stepIndex === 1) return !nationality;
    if (stepIndex === 2) return !(formik.values.age >= 6 && formik.values.age <= 99);
    return true;
  };

  const renderField = () => {
    if (stepIndex === 0)
      return (
        <GenderField
          gender={gender}
          setGender={setGender}
          customGender={customGender}
          setCustomGender={setCustomGender}
        />
      );
    if (stepIndex === 1) return <NationalityField nationality={nationality} setNationality={setNationality} />;
    if (stepIndex === 2) return <AgeForm formik={formik} />;
    return null;
  };

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={handleBack}>
            <ArrowLeft size={13} />
            Back
          </BlackBtn>
        </BlackButtonBox>

        <ContentBox>
          <Text size="14px">{`Step ${step + 1}/${totalSteps}`}</Text>
          {renderField()}
          <RedButtonBox style={{ width: '100%' }}>
            <BtnsGroup>
              {!isNextButtonDisabled() ? (
                <RedBtn onClick={handleNext}>
                  Next <ArrowRight size={13} />
                </RedBtn>
              ) : (
                <EmptyButton style={{ height: '42px' }} />
              )}
              <BlackBtn onClick={skipQuestion}>Skip question</BlackBtn>
            </BtnsGroup>
          </RedButtonBox>
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PersonalInfoScreen);
