// @ts-nocheck
import React, {
  FC,
  RefObject,
  useEffect,
  useRef,
  useState,
  cloneElement,
  Children,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { makeStyles } from '@material-ui/core';
import withFeatureLock from '../../_HOCs/withFeatureLock';
import { Spinner } from '../Spinner/Spinner';
import './TabContainer.scss';
import _ from 'lodash';
import { TasksClient } from '../../../API/tasks.client';

interface iTabContainerProps {
  defaultTabIndex?: number;
  onChangeTab: (index: number, title: any) => void;
  children: any[];
}
interface iTabContainerRef {
  setTab: (title: string) => void;
}

export const TabContainer = React.memo(
  forwardRef<iTabContainerRef, iTabContainerProps>(({ onChangeTab, defaultTabIndex = 0, children }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
    const defaultTabRef = useRef(null);

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const shownChildren = _.compact(
      children.map((child) => {
        if (!child || (typeof child.props.show === 'boolean' && !child.props.show)) {
          return;
        }
        return child;
      })
    );

    const tabs = shownChildren.map((child, index) =>
      cloneElement(child, {
        key: index,
        tabRef: index === defaultTabIndex ? defaultTabRef : undefined,
        isActive: activeTabIndex === index,
        onClick: () => {
          setActiveTab(child.props.children);
          setActiveTabIndex(index);
          onChangeTab(index, child.props.title);
        },
        ...child.props,
      })
    );

    useEffect(() => {
      if (activeTabIndex === null && defaultTabIndex !== null) {
        setActiveTabIndex(defaultTabIndex);
      }
    }, [defaultTabIndex]);

    useEffect(() => {
      if (activeTabIndex !== null && tabs[activeTabIndex]) {
        setActiveTab(tabs[activeTabIndex]?.props?.children);
      }
    }, [activeTabIndex, tabs]);

    useImperativeHandle(ref, () => ({
      setTab(title: string) {
        const tab = shownChildren.find((tab) => tab.props.title.trim() === title.trim());
        if (tab) {
          const index = shownChildren.indexOf(tab);
          if (index !== activeTabIndex) {
            setActiveTabIndex(index);
            setActiveTab(tab.props.children);
          }
        }
      },
    }));

    const tabContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (direction: 'left' | 'right') => {
      const container = tabContainerRef.current;
      if (container) {
        const scrollAmount = direction === 'left' ? -200 : 200;
        container.scrollTo({
          left: container.scrollLeft + scrollAmount,
          behavior: 'smooth',
        });
      }
    };

    const handleScrollVisibility = () => {
      const container = tabContainerRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
      }
    };

    useEffect(() => {
      handleScrollVisibility();
      const container = tabContainerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScrollVisibility);
      }
      return () => {
        if (container) {
          container.removeEventListener('scroll', handleScrollVisibility);
        }
      };
    }, []);

    return (
      <div className="tab-container">
        <div className="tab-overflow">
          <div className="tab-header">
            {showLeftArrow && (
              <div className="arrow" onClick={() => handleScroll('left')}>
                {'<'}
              </div>
            )}
            <div className="tabs-wrapper" ref={tabContainerRef}>
              {tabs}
            </div>
            {showRightArrow && (
              <div className="arrow" onClick={() => handleScroll('right')}>
                {'>'}
              </div>
            )}
          </div>

          <div className="tab-body">
            {isLoading && (
              <div className="spinner-backing">
                <div className="spinner-container">
                  <Spinner />
                </div>
              </div>
            )}
            <div className={`tab-content${isLoading ? ' loading' : ''}`}>
              {Children.map(activeTab, (child, index) =>
                cloneElement(child, {
                  setIsLoading,
                  isLoading,
                  key: index,
                  ...child.props,
                })
              )}
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export interface iTabChildProps {
  // set spinner
  setIsLoading?: (isLoading: boolean) => void;
  isLoading?: boolean;
}

interface iTabProps {
  title: string | any;
  show?: boolean;
  isActive?: boolean; // added by TabContainer
  onClick?: () => void; // added by TabContainer
  tabRef?: RefObject<HTMLDivElement>; // added by TabContainer
  className?: string;
  wheelId?: string;
}

export const Tab = withFeatureLock(({ title, onClick, isActive, tabRef, className, wheelId }: iTabProps) => {
  const [pendingTasksCount, setPendingTasksCount] = useState<string>('');
  const classes = useStyles();

  const fetchDueTodayTasks = async () => {
    try {
      const response = await TasksClient.getTasksOverview();
      const tasks = response?.data || [];

      const pendingTasks = tasks.filter((task: any) => task.wheelId === wheelId && !task.completed);
      setPendingTasksCount(pendingTasks.length.toString());
    } catch (error) {
      console.error('Error fetching due today tasks:', error);
      setPendingTasksCount('');
    }
  };

  useEffect(() => {
    if (title === 'Task Reminder') fetchDueTodayTasks();
  }, [title, wheelId]);

  return (
    <div className={`tab${isActive ? ' active' : ''} ${className || ''}`} onClick={onClick} ref={tabRef}>
      {pendingTasksCount > 0 && (
        <div className={classes.newWrapper}>
          <div className={classes.newText}>{pendingTasksCount}</div>
        </div>
      )}
      <span>{title}</span>
    </div>
  );
}, 'tab');

const useStyles = makeStyles({
  newWrapper: {
    display: 'flex',
    position: 'absolute',
    right: '10px',
    top: '10px',
    width: '15px',
    height: '15px',
    backgroundImage: 'linear-gradient(103deg, #ff815d 0%, #ff4138 100%)',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  newText: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '7px',
    lineHeight: 'normal',
  },
});
