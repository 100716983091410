import { iFolder } from '../../API/interfaces';

export type tIconProps = {
  className?: string;
};

export enum eWheelFilterTags {
  PRIVATE = 'private',
  PUBLIC = 'public',
  WEARABLE = 'wearable',
  I_AM_ADMIN = 'admin',
  I_AM_MEMBER = 'member',
  I_AM_ACCOUNTABILITY_BUDDY = 'accountability_buddy',
}

export type iWheelFilter = Record<eWheelFilterTags, boolean>;

export enum eSortOptionValues {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum eSortOptions {
  BY_LATEST_UPDATES = 'BY_LATEST_UPDATES',
  BY_CREATION_DATE = 'BY_CREATION_DATE',
  BY_SCORE = 'BY_SCORE',
  BY_CHECKINS = 'BY_CHECKINS',
  BY_ALPHABET = 'BY_ALPHABET',
}

export type tWheelSort = Record<eSortOptions, eSortOptionValues>;
export type tPartialWheelSort = Partial<tWheelSort>;

export interface iHasId {
  id: string;
}
export interface iHasParent {
  parent: string;
}
export interface iHasChild<T> {
  child?: T[];
}
export type tFolderWithChild = iFolder & iHasChild<tFolderWithChild>;
